<div class="quiz-home-container">

    <div class="module-lesson-list" [hidden]="view=='quiz' || view=='quizAnswers'">
        <app-quiz-card [data]="lesson" [quizResults]="quizResults"></app-quiz-card>
    </div>
    <br>

    <ng-container *ngIf="view == 'not-started'">
        <div>
            <div *ngIf="!lesson?.QuizId" class="alert alert-danger">
                Lesson is not assigned a Quiz
            </div>

            <div *ngIf="lesson?.LessonDescription">
                <dw-html-view [ngModel]="lesson?.LessonDescription"></dw-html-view><br>
            </div>

            <button class="btn btn-primary" (click)="startQuiz()" *ngIf="!currentQuizResult && lesson?.QuizId">Start Quiz</button>
        </div>
    </ng-container>
    <ng-container *ngIf="view == 'quiz'">
        <app-quiz [quiz]="quiz" [quizResult]="currentQuizResult" [progress]="progress" (quizComplete)="quizComplete($event)"></app-quiz>
    </ng-container>

    <ng-container *ngIf="view == 'quizAnswers'">
        <app-quiz [quiz]="quiz" [quizResult]="currentQuizResult" [reviewAnswers]="true" [progress]="progress" (quizComplete)="quizComplete($event)"></app-quiz>
    </ng-container>

    <div *ngIf="view == 'lastResult'" class="quiz-home-result-container">
        
        <div *ngIf="lesson?.LessonDescription">
            <dw-html-view [ngModel]="lesson?.LessonDescription"></dw-html-view><br>
        </div>

        <div class="quiz-result-container">
            <div>
                <app-quiz-result [quiz]="quiz" [quizResult]="currentQuizResult" (quizResultAction)="quizResultAction($event)"></app-quiz-result>
            </div>
        </div>
        <div class="quiz-results-container">
            <app-quiz-results [quiz]="quiz" [quizResults]="quizResults" (quizResultAction)="quizResultAction($event)"></app-quiz-results>
        </div>
    </div>
 </div>

