import { Component, Injectable, OnInit } from '@angular/core';
import { DwDecoratorType, DwGridColumn, DwGridColumnDef, DwGridColumnRendererBase } from '@devwareapps/devware-cap';
import { DateTimeUtilService } from '../../../util/date-time-util.service';

@DwGridColumn({
  keyName:'duration-seconds-column',
  display: 'Duration Seconds',
  decoratorType: DwDecoratorType.service,
  isGlobal: true,
  allowAction: true,
  showActionButtonConfig: false,
  requireAction: false,
  allowAttr: true,
  requireAttr: true,
})
@Injectable()
export class DurationSecondsColumnRendererService implements DwGridColumnRendererBase {

  constructor(private dateTimeUtilService: DateTimeUtilService) { }


  renderColumn(columnConfig: DwGridColumnDef): (params: any) => any {
   
    return (params: any) => {
      const value = params.getValue();
      
      return this.dateTimeUtilService.formatDurationMinutesSeconds(params.getValue());
    }
  }

}
