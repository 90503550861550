import { Injectable, Optional } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray, DwMetaDataFormStateService, DwMetaDataFormApi, DwEventService, DwGridEvents, DwActionItemRequirement } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { CourseProgressVariableContext } from "../../courses/models/course-progress-variable-context.model";
import { StudentAssignmentProgressEvent, studentAssignmentProgress } from "../models/student-assignment-progress-event.model";
import { SchoolRepositoryService } from "../services/school-repository.service";
import { ResetStudentAssignmentRequest } from "../models/reset-student-assignment-request.model";

@DwAction({
    keyName: 'assignment-student-reset-progress',
    display: 'Assignment Student - Reset Progress',
    actionType: DwActionType.gridAction,
    parentItemName: AppMetaDataItemNames.AssignmentStudent,
    isGlobal: false,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Reset Student Progress',
        iconClass: 'fa fa-eraser',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'Clear out student progress for all students and lessons in this assignment'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    },
    defaultConfirmConfig :{
        showDialogConfig: true,
        showConfirmDialog: true,
        message: 'Are you sure?  This cannot be undone!',
        title: 'Reset Student Progress'
    }
})
@DwAction({
    keyName: 'assignment-student-reset-progress_menu',
    display: 'Assignment Student - Reset Progress',
    actionType: DwActionType.gridContextMenuAction,
    parentItemName: AppMetaDataItemNames.AssignmentStudent,
    isGlobal: false,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Reset Student Progress',
        iconClass: 'fa fa-eraser',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'Clear out student progress for all students and lessons in this assignment'
    },
    defaultMenuConfig: {
        useParentDisplay : false,
        menuDisplay: 'Reset Student Progress',
        itemRequirement: DwActionItemRequirement.multiple
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        requireCanUpdate: true
    },
    defaultConfirmConfig :{
        showDialogConfig: true,
        showConfirmDialog: true,
        message: 'Are you sure?  This cannot be undone!',
        title: 'Reset Student Progress'
    }
})
@Injectable()
export class AssignmentStudentSetProgressActionService implements DwActionHandler {
    formApi: DwMetaDataFormApi;

    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService,
        @Optional() private dwFormStateService: DwMetaDataFormStateService,
        private schoolRespositoryService: SchoolRepositoryService,
        private dwEventService: DwEventService
    ) {
        this.formApi = this.dwFormStateService?.state?.formApi;
    }

    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {

        const assignmentId = actionState.metaDataGridApi.addContext?.AssignmentId;

        let studentIds: any[] = null;

        if (actionState.data != null) {
            const students : AssignmentStudentEntity[] = ensureArray(actionState.data);

            studentIds = students.map(s => s.StudentId);
        }

        const resetRequest: ResetStudentAssignmentRequest = {
            AssignmentId: assignmentId,
            StudentIds: studentIds
        };

        //console.log('resetRequest', resetRequest);

        return this.schoolRespositoryService.resetStudentAssignmentProgress(resetRequest)
            .pipe(map((resetResult) => {
                const result: DwGridActionResult = {
                    success: resetResult.IsSuccess
                };

                if (resetResult.ErrorMessage) {
                    result.message = `Error resetting student progress  Error: ${resetResult.ErrorMessage} `
                } else {
                    result.message = 'Student progress reset successfully';
                }

                result.refreshData = resetResult.ChangesMade;
        
                return result;
            }));
     
 
    }

}