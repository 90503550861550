import { DwUser } from "@devwareapps/devware-cap";
import { AviatorOnlineUserTypeIds } from "./aviator-user-type.enum";


export class AviatorOnlineUser
{
    user: DwUser;

    UserTypeId: AviatorOnlineUserTypeIds;
    FlightSchoolId: number;
    FlightSchoolName: string;
    FlightSchoolBrandingThemeId: string;

    constructor(user: DwUser) {
        this.setupUser(user);
    }

    private setupUser(user: DwUser) {
        this.user = user;

        this.UserTypeId = this.getExtenedAttributeInt('UserTypeId');
        this.FlightSchoolId = this.getExtenedAttributeInt('FlightSchoolId');
        this.FlightSchoolName = this.getExtenedAttribute('FlightSchoolName');
        this.FlightSchoolBrandingThemeId = this.getExtenedAttribute('FlightSchoolBrandingThemeId');
    }

    private getExtenedAttribute(name: string): string {
        const userTypeAttr = this.user?.ExtendedAttributes?.find(a => a.AttrName==name);

        if (userTypeAttr) {
            return userTypeAttr.AttrValue
        }

        return null;
    }

    
    private getExtenedAttributeInt(name: string): number {
        const value = this.getExtenedAttribute(name);

        if (value) {
            return parseInt(value);
        }

        return null;
    }

}