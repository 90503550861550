import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwFormatTypes, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataListCardConfigData, DwMetaDataService, DwMetaDataServiceToken } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, CourseModuleProgressEntity, StudentCourseProgressQueryEntity, StudentModuleProgressQueryEntity } from '../../../../../meta-data/app-meta-data.service';
import { DwCardBaseConfig } from '../../../../z-devware/cards/models/dw-card-base-config.model';
import { DwCardItemResult } from '../../../../z-devware/cards/models/dw-card-item-result.model';
import { CourseProgressVariableContext } from '../../../models/course-progress-variable-context.model';
import { DateTimeUtilService } from '../../../../shared/util/date-time-util.service';

@Component({
  selector: 'app-module-student-progress-card-header',
  templateUrl: './module-student-progress-card-header.component.html',
  styleUrls: ['./module-student-progress-card-header.component.scss']
})
export class ModuleStudentProgressCardHeaderComponent  implements DwCardBaseComponent<any, StudentModuleProgressQueryEntity>, OnChanges {
  cardConfig?: any;
  @Input() data?: StudentModuleProgressQueryEntity;
  resolvedContext?: any;

  cardClass:string;
  cardDesignerConfig?: DwCardBaseConfig;

  cardItemResult: DwCardItemResult<CourseModuleProgressEntity>;

  
  queryMetaData: any;
  variableContext: CourseProgressVariableContext;
  formApi: DwMetaDataFormApi;
  initComplete: boolean;
  constructor(
    @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
    private dateTimeUtilService: DateTimeUtilService
  ) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.setupCard();
    }
  }

  cardClick = new EventEmitter<any>();

  click() {   

  }

  setCardItemResult(cardItemResult: any): void {
    this.cardItemResult = cardItemResult;
  }
 
  setupCard() {
    const attrs = AppMetaData.Queries.StudentModuleProgress.Attributes;

    this.cardDesignerConfig = {
      image: {
        mediaPathField: attrs.ImageMediaPath.fieldName,
        altImage : {
          mediaPathField: attrs.CourseImageMediaPath.fieldName
        }
      },
      titleField: attrs.CourseModuleModuleName.fieldName,
      descriptionField: '',
      additionalFields: [
        {
          key: 'timeSpent',
          fieldName: attrs.TotalTimeSeconds.fieldName,
          prefix: 'Time: ',
          valueFormatter: (value) => {
            return this.dateTimeUtilService.formatDuration(value);
          }
        },
        {
          key: 'completed',
          fieldName: attrs.CompletedDateTime.fieldName,
          formatType: DwFormatTypes.date,
          prefix: 'Completed: ',
        },
        { 
          key: 'lastActivity',
          fieldName: attrs.LastActivityDateTime.fieldName,
          formatType: DwFormatTypes.date,
          prefix: 'Last Activity: ',
        },
      ]
    }
    if (!this.queryMetaData) {
      this.dwMetaDataService.getSavedQueryMetaData(AppMetaDataItemNames.CourseModuleProgress, null, AppMetaData.Queries.StudentModuleProgress.QueryMeaning, false)
        .subscribe(queryMetaData => {
          this.queryMetaData = queryMetaData;

        });
    }
  }
}
