import { Injectable } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { VideoUploadModalComponent } from "../components/video-upload-modal/video-upload-modal.component";
import { map } from "rxjs/operators";
import { VideoModalService } from "../services/video-modal.service";

@DwAction({
    keyName: 'video-upload-action',
    display: 'Upload Videos',
    actionType: DwActionType.gridAction,
    parentItemName: AppMetaDataItemNames.Video,
    isGlobal: true,
    defaultButtonConfig: {
        useParentDisplay: true,
        iconClass: 'fa fa-video',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'Uploads videos files to a streaming provider'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    }
})
@Injectable()
export class VideoUploadActionService implements DwActionHandler {
    constructor(
        /*  @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
         private route: ActivatedRoute,
         private metaDataRoutingService: DwMetaDataRoutingService, */
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private videoModalService: VideoModalService
    ) { }


    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {

        return this.videoModalService.showVideoUploadModal('Upload Videos')
            .pipe(map((modalResult) => {
                const result = new DwGridActionResult();

                if (modalResult.cancelled) {
                    result.refreshData = false;
                    result.cancelled = true;

                    return result;
                }

                result.refreshData = true;

                return result;
            }));
    }

    // private showModal(): Observable<DwActionResult> {
    //     let modalConfig = new DwModalConfig();

    //     modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;
    //     modalConfig.allowFullScreen = true;
    //     modalConfig.preventClose = true;

    //     modalConfig.buttons[0].buttonText = 'Upload';
    //     modalConfig.buttons[0].buttonClass = 'btn btn-warning';
    //     modalConfig.buttons[0].iconClass = 'fa fa-video';

    //     modalConfig.component = VideoUploadModalComponent;

    //     modalConfig.title = 'Upload Videos';

    //     return this.dwModalService.showModal(modalConfig)
    //         .pipe(map(modalResult => {
    //             const result = new DwGridActionResult();

    //             if (result.cancelled) {
    //                 result.refreshData = false;
    //                 result.cancelled = true;

    //                 return result;
    //             }

    //             result.refreshData = true;

    //             return result;
    //         }));
    // }

}