import { Injectable, Optional } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray, DwMetaDataFormStateService, DwMetaDataFormApi, DwEventService, DwGridEvents } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { CourseProgressVariableContext } from "../../courses/models/course-progress-variable-context.model";
import { StudentAssignmentProgressEvent, studentAssignmentProgress } from "../models/student-assignment-progress-event.model";

@DwAction({
    keyName: 'assignment-student-detail-action',
    display: 'Assignment Student Details link',
    actionType: DwActionType.gridAction,
    parentItemName: AppMetaDataItemNames.AssignmentStudent,
    isGlobal: false,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'See Progress',
        iconClass: '',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'See Student Progress Details'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    }
})
@Injectable()
export class AssignmentStudentDetailActionService implements DwActionHandler {
    formApi: DwMetaDataFormApi;

    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService,
        @Optional() private dwFormStateService: DwMetaDataFormStateService,
        private dwEventService: DwEventService
    ) {
        this.formApi = this.dwFormStateService?.state?.formApi;
    }

    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {

        const eventArgs: StudentAssignmentProgressEvent = {
            data:actionState.data 
        };

        this.dwEventService.publishEvent(studentAssignmentProgress, eventArgs);
     
        const result: DwActionResult = {
            success: true
        };

        return of(result);
    }

}