
<app-annonymous-home-view *ngIf="!isAuthenticated"></app-annonymous-home-view>
<app-authenticated-home-view *ngIf="isAuthenticated"></app-authenticated-home-view>
    
<!-- 
<dw-content-manager configKey="examiner-calendar">
    <h1>
        <dw-content name="title" display="Page Title" [controlType]="textboxControlType">
            Welcome to Aviator Online
        </dw-content>
    </h1>

    <dw-content name="detail" display="Header Details">
        
    </dw-content>

    <dw-content-component name='component' display='Custom Component'></dw-content-component>

    <img src="/assets/aviator-online/img/home-page1.png" style="width:100%"/>
</dw-content-manager> -->
