import { Inject, Injectable } from "@angular/core";
import { AppMetaData, AppMetaDataItemNames, CourseEntity, CourseLessonEntity, CourseLessonProgressEntity, CourseLessonSessionEntity, CourseModuleProgressEntity, LessonTypeAllItems, QuizEntity, QuizQueryBuilder, QuizResultEntity, QuizResultQueryBuilder, QuizResultStatusAllItems, StudentCourseEntity } from "../../../meta-data/app-meta-data.service";
import { DevwareMetaData, DwCacheService, DwConfigService, DwConfigServiceToken, DwEventService, DwMetaDataService, DwMetaDataServiceToken, DwOrmData, DwOrmDataService, DwOrmDataServiceToken, DwSecurityTopics, DwSecurityUserService, areEqual, deepCopy } from "@devwareapps/devware-cap";
import { Observable, combineLatest, of } from "rxjs";
import { Router } from "@angular/router";
import { map, mergeMap } from "rxjs/operators";
import { QuizSetupRequest } from "../models/quiz-setup-request.model";
import { QuizSetupResult } from "../models/quiz-setup-result.model";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class QuizRepositoryService {
   
    quizApi: string;



    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) private configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        private dwEventService: DwEventService,
        private router: Router,
        private http: HttpClient
    ) {
        this.quizApi = `${configService.coreConfig.apiRoot}/aviator-online/quizzes`;
    }

    regradeQuiz(quizResultId: number): Observable<void> {

        return this.loadQuizResult(quizResultId)
            .pipe(mergeMap(quizResult => {
                quizResult.QuizResultStatusId = QuizResultStatusAllItems.CompleteUngraded;

                return this.dwOrmDataService.saveEntity(quizResult, true);
            }));
        // const quizResult : Partial<QuizResultEntity> = {
        //     QuizResultId: QuizResultQuizResultId,
        //     QuizResultStatusId: QuizResultStatusAllItems.CompleteUngraded,
        //     _itemName: AppMetaDataItemNames.QuizResult
        // };

        // return this.dwOrmDataService.saveEntity(quizResult, false);
    }

    getQuizSetup(request: QuizSetupRequest) : Observable<QuizSetupResult> {
        const url = `${this.quizApi}/setup-quiz`;

        return this.http.post<QuizSetupResult>(url, request);
    }

    /** Gets a cached quiz including all questions/answers */
    getQuiz(quizId: number): Observable<QuizEntity> {
        const cacheInvalidationTopics: string[] = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Quiz));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.QuizQuestionLink));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Question));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.QuestionAnswer));

        const courseKey = `Quiz_${quizId}`;

        return this.dwCacheService.get(courseKey, this.loadQuiz(quizId), null, cacheInvalidationTopics);
    }

    /** Save a quiz - used for linked questions */
    saveQuiz(quizEntity: QuizEntity) {
        return this.dwOrmDataService.saveEntity(quizEntity, true);
    }

    /** Gets a cached quiz including all questions/answers */
    getQuizResults(quizId: number, studentCourseId: number, lessonProgressId?: number): Observable<QuizResultEntity[]> {
        const cacheInvalidationTopics: string[] = [DwSecurityTopics.SECURITY_CHANGED];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.QuizResult));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.QuizResultQuestion));

        const courseKey = `QuizResult_${quizId}_${studentCourseId}_${lessonProgressId}`;

        return this.dwCacheService.get(courseKey, this.loadCurrentQuizResults(quizId, studentCourseId, lessonProgressId), null, cacheInvalidationTopics);
    }

    loadQuizResult(quizResultId: number): Observable<QuizResultEntity> {
        const query = this.buildQuizResultQuery();

        query.addFilterAnd(f => f.Equal(a => a.QuizResultId, quizResultId));

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    /** Saves a quiz result */
    saveQuizResult(quizResult: QuizResultEntity): Observable<QuizResultEntity> {

        const loadQuery = this.buildQuizResultQuery();

        return this.dwOrmDataService.saveEntity(quizResult, true, loadQuery.query);
    }
    private loadQuiz(quizId: number): Observable<QuizEntity> {
        const query = this.buildLoadQuizQuery();

        query.addFilterAnd(f => f.Equal(a => a.QuizId, quizId));

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    private buildLoadQuizQuery(): QuizQueryBuilder {
        const query = AppMetaData.Quiz.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;

        const questionLinkQuery = AppMetaData.QuizQuestionLink.CreateQueryBuilder();

        questionLinkQuery.addOrderBy(o => o.QuestionOrder);

        // const questionQuery = AppMetaData.Question.CreateQueryBuilder();

        // questionLinkQuery.addPrefetch(r => r.Question, questionQuery.query);

        // const questionAnswerQuery = AppMetaData.QuestionAnswer.CreateQueryBuilder();

        // questionQuery.addPrefetch(r => r.QuestionAnswer, questionAnswerQuery.query);
        // questionQuery.addPrefetch(r => r.DwRoMediaByQuestionImageMediaId);
        // questionQuery.addPrefetch(r => r.DwRoMediaByCorrectAnswerImageMediaId);

        query.addPrefetch(r => r.QuizQuestionLink, questionLinkQuery.query);

        return query;
    }

    private loadCurrentQuizResults(quizId: number, studentCourseId: number, lessonProgressId?: number): Observable<QuizResultEntity[]> {
        const query = this.buildQuizResultQuery();

        //query.query.FieldSettings.LoadAllLookupDisplayFields = true;

        // Only logged in users have course progress
        const userId = this.dwSecurityUserService.securityContext?.ApplicationUser?.UserId;

        if (!userId) {
            return of(null);
        }

        //query.addRelation(r => r.StudentCourse);

        query.addFilterAnd(f => f.Equal(a => a.QuizId, quizId));
        query.addFilterAnd(f => f.Equal(a => a.StudentCourseId, studentCourseId));

        if (lessonProgressId) {
            query.addFilterAnd(f => f.Equal(a => a.CourseLessonProgressId, lessonProgressId));
        }

        return this.dwOrmDataService.executeQuery(query);
    }

    private buildQuizResultQuery(): QuizResultQueryBuilder {
        const query = AppMetaData.QuizResult.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;

        query.addPrefetch(r => r.QuizResultQuestion);

        return query;

    }

}