import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwMediaRepositoryService, DwMetaDataListCardConfigData, DwRoutingService } from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CourseEntity, CourseLessonEntity, CourseListForStudentQueryEntity, CourseModuleEntity, LessonListForStudentQueryEntity, ModuleListForStudentQueryEntity } from '../../../../../meta-data/app-meta-data.service';
import { CourseRepositoryService } from '../../../services/course-repoistory.service';

@DwComponent<DwMetaDataListCardConfigData>({
  name: 'Lesson Card - Home Page',
  key: 'lessonCard-homePage',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: false,
  parentItemName: AppMetaDataItemNames.CourseLesson,
  configData: {
    contentItemDefinitions: [{
      display: 'Main',
      contentItemKey: 'main'
    }]
  }
})
@Component({
  selector: 'app-lesson-card-home-page',
  templateUrl: './lesson-card-home-page.component.html',
  styleUrls: ['./lesson-card-home-page.component.scss']
})
export class LessonCardHomePageComponent implements DwCardBaseComponent<any, LessonListForStudentQueryEntity> {

  cardConfig?: any;
  data?: LessonListForStudentQueryEntity;
  resolvedContext?: any;
  cardClick: EventEmitter<any> = new EventEmitter<any>();

  imageUrl: string  = ''; //= '/assets/aviator-online/img/module-image-default.png';

  defaultImageUrl: string = '/assets/aviator-online/img/lesson-image-default.png';
  progressPercent = 0;
  constructor(private dwMediaRepository: DwMediaRepositoryService,
    private dwRoutingService: DwRoutingService,
    private courseRepositoryService: CourseRepositoryService,
  ) { }

  setupCard() {
    this.imageUrl = this.courseRepositoryService.getLessonImageUrl(this.data).mainImageUrl;

    // if (this.data?.LessonImageDwroMediaMediaPath) {
    //   this.imageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.data.LessonImageDwroMediaMediaPath}`;
    // } else {
    //   this.imageUrl = this.data?.VideoThumbnailUrl;
    // }

    //this.imageUrl = this.data?.Video?.ThumbnailUrl;
    // this.progressPercent = this.data?.CourseModuleProgressPercentComplete || 0;

    // if (this.data?.MediaMediaPath) { 
    //   this.imageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.data.MediaMediaPath}`;
    // }
  }

  click() {
    const url = `/my-courses/view/${this.data.CourseModuleCourseId}/module/${this.data.CourseModuleId}/lesson/${this.data.CourseLessonId}`;

    this.dwRoutingService.navigateToUrl(url, null);
    //this.cardClick.emit(this.data);
  }
   
  ngOnInit(): void { 
  }
}
