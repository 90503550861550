import { Component, OnInit } from '@angular/core';
import { QuizAnswerBaseComponent } from '../base/quiz-answer-base.component';
import { DwLookupInfo } from '@devwareapps/devware-cap';
import { QuestionAnswerReviews } from '../../../models/question-answer-review.model';
import { QuizUtilService } from '../../../services/quiz-util.service';

@Component({
  selector: 'app-quiz-answer-true-false',
  template: `
  <div *ngFor="let option of options; let i = index" id="check-item" class="check-item">
    <div class="check-label">
      <label>
        <input type="radio" class="check-input" name="answer" [disabled]="reviewAnswers" [ngModel]="selectedOption" [value]="option.value" (ngModelChange)="setAnswer(option)" />
        <div class="check-item-text"> {{option.display}}</div>
      </label>
    </div> 
    
    <div *ngIf="answerReviews[i] && reviewAnswers" class="answer-review">
      <i class="answer-icon" [ngClass]="answerReviews[i].iconClass"></i>
      <span class="answer-note">{{answerReviews[i].textNote}}</span>
      <span class="answer-explanation" [ngClass]="answerReviews[i].explanationTextClass">{{answerReviews[i].explanationText}}</span>
    </div>
  </div>

  <!--  <dw-radio-list [values]="options" [ngModel]="selectedOption" (ngModelChange)="setAnswer($event)"></dw-radio-list> -->
  `
})
export class QuizAnswerTrueFalseComponent extends QuizAnswerBaseComponent {
  options: DwRadioItem[] = [];
  answerReviews: QuestionAnswerReviews = {};

  selectedOption: any;

  constructor(private quizUtilService: QuizUtilService) {
    super();
  }

  ngOnInit(): void {
  }

  setAnswer(option: DwRadioItem) {

    this.selectedOption = option.value;
    this.questionAnswerValue.trueFalseAnswer = option.value == 'true';

    this.setAnswerValue(this.questionAnswerValue, true);
  }

  setupAnswer() {
    this.options = [
      {
        value: 'true',
        display: 'True'
      },
      {
        value: 'false',
        display: 'False'
      }
    ];

    if (this.questionAnswerValue.trueFalseAnswer == true || this.questionAnswerValue.trueFalseAnswer == false) {
      this.selectedOption = this.questionAnswerValue?.trueFalseAnswer ? 'true' : 'false';
    }
    
    if (this.reviewAnswers) {
      this.answerReviews = this.quizUtilService.getAnswerReviews(this.questionLink, this.questionResult, this.questionAnswerValue);
    }
  }

}


export interface DwRadioItem {
  display: string;
  value: any;
}