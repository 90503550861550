// tslint:disable: max-line-length
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AgGridModule } from 'ag-grid-angular';
import { OrderListModule } from 'primeng/orderlist';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AppRoutingModule } from './app-routing.module';  

import { DevwareCoreModule,
  DevwareOrmModule,
  DevwareUIModule,
  DevwareAGGridModule, 
  DevwareUILayoutModule,
  DevwareUIMetaDataModule,
  DevwareAdminModule,
  DevwareUiWizardModule, 
  DevwareMediaModule} from '@devwareapps/devware-cap';

import { AppDevwareConfigProviderService } from './app-devware-config-provider.service';


import { AppComponent } from './pages/app/app.component';
import { HomeComponent } from './features/shared/pages/home/home.component';


import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { TreeModule } from 'primeng/tree';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import {ProgressBarModule} from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { DwTaskContainerComponent } from './features/z-devware/tasks/components/dw-task-container/dw-task-container.component';


import {ChartModule} from 'primeng/chart';
import { AviatorOnlineHomePageComponent } from './features/homepage/components/aviator-online-home-page/aviator-online-home-page.component';
import { AppDevwareBootstrapService } from './app-devware-registrations.service';
import { CourseCardComponent } from './features/courses/components/cards/course-card/course-card.component';
import { CourseBreadcrumbProcessor } from './features/courses/breadcrumb/course-breadcrumb-customiziner.service';
import { CourseNavigationComponent } from './features/courses/components/course-navigation/course-navigation.component';
import { ModuleCardComponent } from './features/courses/components/cards/module-card/module-card.component';
import { CourstListDataSourceService } from './features/courses/data-sources/course-list-data-source.service';
import { ModuleListDataSourceService } from './features/courses/data-sources/module-list-data-source.service';
import { LessonCardComponent } from './features/courses/components/cards/lesson-card/lesson-card.component';
import { LessonNavigationComponent } from './features/courses/components/lesson-navigation/lesson-navigation.component';
import { LessonUiComponent } from './features/courses/components/lesson-ui/lesson-ui.component';
import { VideoHostComponent } from './features/videos/components/video-host/video-host.component';
import { VideoPlayerBunnyComponent } from './features/videos/components/streaming-providers/bunny/components/video-player-bunny/video-player-bunny.component';
import { VideoPlayerApiVideoComponent } from './features/videos/components/streaming-providers/apivideo/components/video-player-apivideo/video-player-apivideo.component';
import { VideoPlayerBaseComponent } from './features/videos/components/streaming-providers/base/video-player-base.component';
import { LessonListDataSourceService } from './features/courses/data-sources/lesson-list-data-source.service';
import { QuizComponent } from './features/quizzes/components/quiz/quiz.component';
import { QuizQuestionComponent } from './features/quizzes/components/quiz-question/quiz-question.component';
import { QuizHomeComponent } from './features/quizzes/components/quiz-home/quiz-home.component';
import { QuizAnswerTrueFalseComponent } from './features/quizzes/components/answers/quiz-answer-true-false/quiz-answer-true-false.component';
import { QuizAnswerMultipleChoiceComponent } from './features/quizzes/components/answers/quiz-answer-multiple-choice/quiz-answer-multiple-choice.component';
import { QuizAnswerMultipleAnswerComponent } from './features/quizzes/components/answers/quiz-answer-multiple-answer/quiz-answer-multiple-answer.component';
import { QuizAnswerBaseComponent } from './features/quizzes/components/answers/base/quiz-answer-base.component';
import { QuizAnswerHostComponent } from './features/quizzes/components/answers/quiz-answer-host/quiz-answer-host.component';
import { QuizResultComponent } from './features/quizzes/components/quiz-result/quiz-result.component';
import { QuizResultsComponent } from './features/quizzes/components/quiz-results/quiz-results.component';
import { QuizCardComponent } from './features/courses/components/cards/quiz-card/quiz-card.component';
import { TimeDurationPipe } from './features/shared/pipes/time-duration.pipe';
import { VideoUploadActionService } from './features/videos/actions/video-upload-action.service';
import { VideoUploadModalComponent } from './features/videos/components/video-upload-modal/video-upload-modal.component';
import { UserRegistrationComponent } from './features/homepage/components/user-registration/user-registration.component';
import { CurrentFlightSchoolContextService } from './features/schools/contexts/current-school-context.service';
import { CourseStudentProgressCardComponent } from './features/courses/components/cards/course-student-progress-card/course-student-progress-card.component';
import { ModuleStudentProgressCardComponent } from './features/courses/components/cards/module-student-progress-card/module-student-progress-card.component';
import { ModuleStudentProgressCardHeaderComponent } from './features/courses/components/cards/module-student-progress-card-header/module-student-progress-card-header.component';
import { BaseCardComponent } from './features/courses/components/cards/base-card/base-card.component';
import { DwCardDesignerComponent } from './features/z-devware/cards/components/dw-card-designer/dw-card-designer.component';
import { CourseStudentProgressFormCardComponent } from './features/courses/components/cards/course-student-progress-form-card/course-student-progress-form-card.component';
import { CourseStudentProgressComponent } from './features/courses/components/course-student-progress/course-student-progress.component';
import { LessonStudentProgressCardComponent } from './features/courses/components/cards/lesson-student-progress-card/lesson-student-progress-card.component';
import { QuizResultsModalComponent } from './features/quizzes/components/quiz-results-modal/quiz-results-modal.component';
import { ShowQuizResultModalActionService } from './features/quizzes/actions/show-quiz-result-mode-action.service';
import { StudentCourseProgressListDataSourceService } from './features/courses/data-sources/student-course-progress-list-data-source.service';
import { LinkQuestionsModalComponent } from './features/quizzes/components/link-questions-modal/link-questions-modal.component';
import { LinkQuestionActionsService } from './features/quizzes/actions/link-questions-action.service';
import { CurrentStudentContextService } from './features/students/contexts/current-student-context.service';
import { AssignmentUtilComponent } from './features/schools/components/assignment-util/assignment-util.component';
import { AssignmentStudentDetailActionService } from './features/schools/actions/assignment-student-detail-action.service';
import { AssignmentStudentSetProgressActionService } from './features/schools/actions/assignment-student-reset-progress.service';
import { AnnonymousHomeViewComponent } from './features/homepage/components/annonymous-home-view/annonymous-home-view.component';
import { AuthenticatedHomeViewComponent } from './features/homepage/components/authenticated-home-view/authenticated-home-view.component';
import { StudentDashboardComponent } from './features/students/components/student-dashboard/student-dashboard.component';
import { LessonCardHomePageComponent } from './features/courses/components/cards/lesson-card-home-page/lesson-card-home-page.component';
import { AssignmentCardComponent } from './features/schools/components/cards/assignment-card/assignment-card.component';
import { AssignmentStudentCurrentDataSourceService } from './features/schools/data-sources/assignment-student-current-data-source.service';
import { DurationSecondsColumnRendererService } from './features/shared/components/columns/duration-seconds-column/duration-seconds-column-renderer-service.';
import { UsreAvatarComponent } from './features/shared/components/usre-avatar/usre-avatar.component';
import { UrlParameterContextService } from './features/shared/contexts/url-parameter-context.service';
import { ManageStudentContextService } from './features/schools/contexts/manage-student-context.service';
import { AssignCourseUtilComponent } from './features/schools/components/assign-course-util/assign-course-util.component';
import { VideoAdminPreviewComponent } from './features/videos/components/video-admin-preview/video-admin-preview.component';
import { AssignmentLessonCardComponent } from './features/schools/components/cards/assignment-lesson-card/assignment-lesson-card.component';
import { AssignmentStudentDataSourceService } from './features/schools/data-sources/assignment-student-data-source.service';
import { AssignmentStudentLessonProgressComponent } from './features/schools/components/assignment-student-lesson-progress/assignment-student-lesson-progress.component';
import { StudentCourseDeleteActionService } from './features/schools/actions/student-course-delete-action.service';
import { StudentResetCourseProgressActionService } from './features/schools/actions/student-reset-course-progress-action.service';
import { SchoolDashboardComponent } from './features/schools/components/school-dashboard/school-dashboard.component';
import { InstructorDashboardComponent } from './features/instructors/components/instructor-dashboard/instructor-dashboard.component';
import { StudentCardComponent } from './features/students/components/cards/student-card/student-card.component';
import { InstructorStudentDataSourceService } from './features/instructors/data-sources/instructor-student-data-source.service';
import { CurrentInstructorContextService } from './features/instructors/contexts/current-instructor-context.service';
import { SchoolStudentDataSourceService } from './features/schools/data-sources/school-student-data-source.service';
import { ModuleProgressListDataSourceService } from './features/courses/data-sources/module-progress-list-data-source.service';
import { MyFlightSchoolContextService } from './features/schools/contexts/my-school-context.service';
import { VideoReplaceFormActionService } from './features/videos/actions/video-replace-form-action.service';
import { VideoRevertToHistoryActionService } from './features/videos/actions/video-revert-to-history-action.service';
import { VideoUpdateStatusActionService } from './features/videos/actions/video-update-status-action.service';
import { DwMagicImage2Directive } from './features/z-devware/visualizations/components/dw-magic-image2/dw-magic-image2.directive';
import { DwImage2Component } from './features/z-devware/visualizations/components/dw-image2/dw-image2.component';
import { UserRoleDetailsContextService } from './features/schools/contexts/user-role-details-context.service';
import { ManagerUserUtilComponent } from './features/schools/components/manager-user-util/manager-user-util.component';
import { DwAssignmentLessonCountValidationRule } from './features/schools/validators/assignment-lesson-count-validator.service';
import { DwAssignmentStudentCountValidationRule } from './features/schools/validators/assignment-student-count-validator.service';
import { UpdateAllStudentProgressActionService } from './features/schools/actions/course-update-all-student-progress-action.service';
import { InstructorAssignStudentsSaveActionService } from './features/schools/actions/instructor-assign-students-save-action.service';
import { LessonSelectionTreeFormControlComponent } from './features/shared/components/lesson-selection-tree-form-control/lesson-selection-tree-form-control.component';
import { LessonSelectionTreeControlComponent } from './features/shared/components/lesson-selection-tree-control/lesson-selection-tree-control.component';
import { UserBreadcrumbProcessor } from './features/schools/breadcrumbs/user-breadbrumb-processor.service';
import { FlagLessonModalComponent } from './features/courses/components/flag-lesson-modal/flag-lesson-modal.component';
import { StudentCourseEndorsementContextService } from './features/courses/contexts/student-course-endorsement-context.service';
import { EndorsementStudentEditComponent } from './features/schools/components/endorsement-student-edit/endorsement-student-edit.component';
import { CourseHelperContextContextService } from './features/courses/contexts/course-helper-context.service';
import { FlightSchoolSettingsComponent } from './features/schools/components/flight-school-settings/flight-school-settings.component';
import { StudentUpdateCourseProgressActionService } from './features/schools/actions/student-update-course-progress-action.service';
import { RegardeQuizActionsService } from './features/quizzes/actions/regrade-quiz-action.service';
import { ResendJoinRequestActionService } from './features/schools/actions/resend-join-request-action.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DwTaskContainerComponent, 
    AviatorOnlineHomePageComponent,
    CourseCardComponent,
    CourseNavigationComponent,
    ModuleCardComponent,
    LessonCardComponent,
    LessonNavigationComponent,
    LessonUiComponent,
    VideoHostComponent,
    VideoPlayerBunnyComponent,
    VideoPlayerApiVideoComponent,
    VideoPlayerBaseComponent,
    QuizComponent,
    QuizQuestionComponent,
    QuizHomeComponent,
    QuizAnswerTrueFalseComponent,
    QuizAnswerMultipleChoiceComponent,
    QuizAnswerMultipleAnswerComponent,
    QuizAnswerBaseComponent,
    QuizAnswerHostComponent,
    QuizResultComponent,
    QuizResultsComponent,
    QuizCardComponent,
    TimeDurationPipe,
    VideoUploadModalComponent,
    UserRegistrationComponent,
    CourseStudentProgressCardComponent,
    ModuleStudentProgressCardComponent,
    ModuleStudentProgressCardHeaderComponent,
    BaseCardComponent,
    DwCardDesignerComponent,
    CourseStudentProgressFormCardComponent,
    CourseStudentProgressComponent,
    LessonStudentProgressCardComponent,
    QuizResultsModalComponent,
    LinkQuestionsModalComponent,
    AssignmentUtilComponent,
    AnnonymousHomeViewComponent,
    AuthenticatedHomeViewComponent,
    StudentDashboardComponent,
    LessonCardHomePageComponent,
    AssignmentCardComponent,
    UsreAvatarComponent,
    AssignCourseUtilComponent,
    VideoAdminPreviewComponent,
    AssignmentLessonCardComponent,
    AssignmentStudentLessonProgressComponent,
    SchoolDashboardComponent,
    InstructorDashboardComponent,
    StudentCardComponent,
    DwMagicImage2Directive,
    DwImage2Component,
    ManagerUserUtilComponent,
    LessonSelectionTreeFormControlComponent,
    LessonSelectionTreeControlComponent,
    FlagLessonModalComponent,
    EndorsementStudentEditComponent,
    FlightSchoolSettingsComponent,
  ], 
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OrderListModule, 
    InputSwitchModule,
    TabViewModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    TreeModule,
    
    HttpClientModule,
    AgGridModule.forRoot(),
    BsDropdownModule.forRoot(),
    ChartModule,
    
    DevwareCoreModule, 
    DevwareOrmModule,
    DevwareUIModule, 
    DevwareAGGridModule,
    DevwareUILayoutModule,
    DevwareUIMetaDataModule,
    DevwareUiWizardModule,
    DevwareAdminModule, 
    DevwareMediaModule,
    ProgressBarModule,
    AppRoutingModule,
  ],
  providers: [ 
    AppDevwareConfigProviderService,
    AppDevwareBootstrapService,
    CourseHelperContextContextService,
    CurrentStudentContextService,
    CourseBreadcrumbProcessor,
    CourstListDataSourceService,
    ModuleListDataSourceService,
    LessonListDataSourceService,
    StudentCourseProgressListDataSourceService,
    VideoUploadActionService,
    CurrentFlightSchoolContextService,
    ShowQuizResultModalActionService,
    LinkQuestionActionsService,
    AssignmentStudentDetailActionService,
    AssignmentStudentSetProgressActionService,
    AssignmentStudentCurrentDataSourceService,
    DurationSecondsColumnRendererService,
    UrlParameterContextService,
    ManageStudentContextService,
    AssignmentStudentDataSourceService,
    StudentCourseDeleteActionService,
    StudentResetCourseProgressActionService,
    InstructorStudentDataSourceService,
    CurrentInstructorContextService,
    SchoolStudentDataSourceService,
    ModuleProgressListDataSourceService,
    MyFlightSchoolContextService,
    VideoReplaceFormActionService,
    VideoRevertToHistoryActionService,
    VideoUpdateStatusActionService,
    UserRoleDetailsContextService,
    DwAssignmentLessonCountValidationRule,
    DwAssignmentStudentCountValidationRule,
    UpdateAllStudentProgressActionService,
    InstructorAssignStudentsSaveActionService,
    UserBreadcrumbProcessor,
    StudentCourseEndorsementContextService,
    StudentUpdateCourseProgressActionService,
    RegardeQuizActionsService,
    ResendJoinRequestActionService
  ],
  bootstrap: [AppComponent], 
  exports: [
    //UserRegistrationComponent
  ]
})
export class AppModule { }
