import { Directive, ElementRef, HostBinding, HostListener, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { isFirstChange } from "@devwareapps/devware-cap";


@Directive({
    selector: "[dwMagicImage2]",
})
export class DwMagicImage2Directive implements OnInit, OnChanges {
    @Input() maxWidth: string;
    @Input() maxHeight: string;

    @Input() src: string;

    lastSrc: string;

    
    @HostBinding('style.width')
    width: string;

    @HostBinding('style.height')
    height?: string;

    constructor(private el: ElementRef) {
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isFirstChange(changes)) {
            this.setupImage();
        }
    }


    ngOnInit(): void {
        this.setupImage();
    }

    setupImage() {
        if (!this.src) {
            return;
        }

        if (this.lastSrc && this.src == this.lastSrc) {
            return;
        }

        this.lastSrc = this.src;


        const image = new Image();
        image.src = this.src;

        let maxWidthNumber: any = this.parseDimension(this.maxWidth);
        let maxHeightNumber: any = this.parseDimension(this.maxHeight);


        image.onload = (e: any) => {
            const height = e.path[0].height;
            const width = e.path[0].width;


            if (maxHeightNumber && height > maxHeightNumber) {
                // Need to resize to fit height
                this.height = this.maxHeight;
                this.width = (height / maxHeightNumber) * width + "px";
                return;
            }

            if (maxWidthNumber && width > maxWidthNumber) {
                // Need to resize to fit width
                this.width = this.maxWidth;
                this.height = (width / maxWidthNumber) * height + "px";
                return;
            }

            if (width > height) {
                this.width = this.maxWidth;
                this.height = null;
            } else {
                this.height = this.maxHeight;
                this.width = null;
            }
        };
    }

    parseDimension(dimension: string): number {

        if (dimension?.indexOf("px") > 0) {
            return parseInt(dimension.replace("px", ""));
        }

        return null;
    }

}
