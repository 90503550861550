<div class="card card-container card-item" (click)="click()" [ngClass]="additionalClasses">
    <div class="card-image" *ngIf="cardDesignerConfig?.image && cardItemResult?.imageUrl" class="hide-mobile">
        <dw-image [src]="cardItemResult?.imageUrl" [defaultSrc]="cardItemResult?.backupImageUrl" [maxWidth]="cardDesignerConfig.image.maxWidth" [maxHeight]="cardDesignerConfig.image.maxHeight"></dw-image>
    </div>
    <div class="card-image" *ngIf="cardDesignerConfig?.image  && cardItemResult?.imageUrl" class="show-mobile">
        <dw-image [src]="cardItemResult?.imageUrl" [defaultSrc]="cardItemResult?.backupImageUrl" [maxWidth]="cardDesignerConfig.image.mobileMaxWidth" [maxHeight]="cardDesignerConfig.image.maxMaxHeight"></dw-image>
    </div>
    <div class="card-body">
        <h5 class="card-title">{{cardItemResult?.title}}  <ng-content select="[cardTitle]"></ng-content></h5>
      
        <div class="card-section1">

            <div class="card-description">
                <dw-html-view [ngModel]="cardItemResult?.description" *ngIf="cardItemResult?.description"></dw-html-view>

                <ng-content select="[cardDesc]"></ng-content>
            </div>
        </div>
        <div class="card-section2">
            <ng-content select="[cardRight]"></ng-content>
        </div>
    </div>
</div>