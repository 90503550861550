import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwDataFormatService, DwFormatTypes, DwMetaDataListCardConfigData, DwQueryMetaData } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, AssignmentLessonWithProgressForStudentQueryEntity, AssignmentStudentLessonProgressListQueryEntity, LessonTypeAllItems, QuizTypeAllItems } from '../../../../../meta-data/app-meta-data.service';
import { CourseNavigationService } from '../../../../courses/services/course-navigation.service';
import { LessonReferenceInfo } from '../../../../courses/models/lesson-reference-info.model';


@DwComponent<DwMetaDataListCardConfigData>({
  name: 'Assignment Lesson Card',
  key: 'assignmentLessonCard',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: true,
  parentItemName: AppMetaDataItemNames.AssignmentCourseLesson,
  configData: {
    contentItemDefinitions: [{
      display: 'Main',
      contentItemKey: 'main'
    }]
  }
})
@Component({
  selector: 'app-assignment-lesson-card',
  templateUrl: './assignment-lesson-card.component.html',
  styleUrls: ['./assignment-lesson-card.component.scss']
})
export class AssignmentLessonCardComponent implements DwCardBaseComponent<any, AssignmentLessonWithProgressForStudentQueryEntity> {
  cardConfig?: any; 
  data?: AssignmentStudentLessonProgressListQueryEntity;
  resolvedContext?: any;
  cardClick: EventEmitter<any> = new EventEmitter<any>();
  dueDateTime: string;
  percentCompleteText: string;
  
  constructor(private dwDataFormatService: DwDataFormatService,
    private courseNavigationService: CourseNavigationService
  ) { }
  queryMetaData?: DwQueryMetaData;

 
  setupCard() {
    //this.dueDateTime = this.dwDataFormatService.formatValue(this.data?.AssignmentDueDateTime, { formatType: DwFormatTypes.datetime, useGmtDateTime: true});

    // this.data?.CourseLessonProgressLessonComplete
    // this.data?.CourseLessonProgressPercentComplete
    // this.data?.CourseLessonLessonTypeId == LessonTypeAllItems.Quiz
  }

  quizLessonTypeId = LessonTypeAllItems.Quiz
  click() {
    const lessonReference : LessonReferenceInfo = {
      courseId: this.data.CourseLessonCourseModuleId,
      moduleId: this.data.CourseLessonCourseModuleId,
      lessonId: this.data.CourseLessonId
    }

    this.courseNavigationService.navigateToLesson(lessonReference);
  }

}
