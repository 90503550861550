import { Injectable } from "@angular/core";
import { DwAppInitService, DwThemeManagerService } from "@devwareapps/devware-cap";
import { AviatorOnlineUser } from "../../shared/models/aviator-user.model";
import { mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SchoolBrandingService {

    private currentThemeId: any;
    constructor(private themeManagerService: DwThemeManagerService,
        private appInitService: DwAppInitService) {
            
        }

    setupSchoolBranding(user: AviatorOnlineUser): Observable<any> {

        return this.appInitService.getAppInitResult()
            .pipe(mergeMap(result => {
                let newThemeId = result?.ThemesConfig?.CurrentThemeId;

                if (user.FlightSchoolBrandingThemeId) {
                    newThemeId = user.FlightSchoolBrandingThemeId;
                }

                if (!newThemeId || newThemeId == this.currentThemeId) { 
                    return of(null);
                }

                this.currentThemeId = newThemeId;

                return this.themeManagerService.applyTheme({
                    ThemeId: newThemeId,
                } as any);
            }));
    }

}