import { Component, OnInit } from '@angular/core';
import { StudentDashboardComponent } from '../../../students/components/student-dashboard/student-dashboard.component';
import { CourseRepositoryService } from '../../../courses/services/course-repoistory.service';
import { InstructorRepositoryService } from '../../services/instructor-repository.service';
import { InstructorEntity } from '../../../../meta-data/app-meta-data.service';

@Component({
  selector: 'app-instructor-dashboard',
  templateUrl: './instructor-dashboard.component.html',
  styleUrls: ['./instructor-dashboard.component.scss']
})
export class InstructorDashboardComponent
  // Instructors are also students so we inherit from the StudentDashboardComponent
  extends StudentDashboardComponent implements OnInit {
  instructor: InstructorEntity;

  constructor(courseRepositoryService: CourseRepositoryService,
    private instructorRepositoryService: InstructorRepositoryService
  ) {
    super(courseRepositoryService);
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  setupInstructorFilters() {
    this.instructorRepositoryService.getCurrentInstructor()
      .subscribe(instructor => {
        this.instructor = instructor;
      });
  }

}
