

<div class="quiz-question">
    <dw-html-view [ngModel]="questionLink?.Question?.Question"></dw-html-view>
</div>

<div class="quiz-image" *ngIf="questionImageUrl">
    <!-- <img [src]="questionImageUrl" dwMagicImage2 maxHeight="300px"> -->
    <dw-image2 [src]="questionImageUrl" maxHeight="300px" screenMargin="64"></dw-image2>
</div>

<div class="quiz-answers">
    <app-quiz-answer-host [questionLink]="questionLink" [(questionResult)]="questionResult" [reviewAnswers]="reviewAnswers"></app-quiz-answer-host>
</div>
<div class="quiz-answer-explanation" *ngIf="reviewAnswers">
    <dw-html-view [ngModel]="questionLink?.Question?.CorrectAnswerNotes"></dw-html-view>

    <div class="quiz-image" *ngIf="answerImageUrl">
        <dw-image2 [src]="answerImageUrl" maxWidth="100%" maxHeight="200px"></dw-image2>
    </div>
</div>
