import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppMetaDataItemNames, QuizEntity, QuestionEntity, QuizResultEntity, QuizResultQuestionEntity, QuizQuestionLinkEntity, CourseLessonProgressEntity, QuizResultStatusAllItems } from '../../../../meta-data/app-meta-data.service';
import { QuizCompleteResult } from '../../models/quiz-complete-result.model';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { ArrayUtilService } from '../../../shared/util/array-util.service';
import { QuizRepositoryService } from '../../services/quiz-repository.service';
import { LessonProgressHierarchy } from '../../../courses/models/lesson-progress-hierarchy.model';
import { QuizUtilService } from '../../services/quiz-util.service';
import { QuizSetupResult } from '../../models/quiz-setup-result.model';
import { DwMessage, DwMessageSeverity } from '@devwareapps/devware-cap';
import { LessonFlagUtilService } from '../../../courses/services/lesson-flag-util.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  @Input() quiz: QuizEntity;
  @Input() quizResult: QuizResultEntity;
  @Input() progress: LessonProgressHierarchy;

  @Output() quizComplete = new EventEmitter<QuizCompleteResult>();

  @Input() reviewAnswers: boolean = false;

  @Input() startingQuizResultQuestionId: number;

  currentQuestionLink: QuizQuestionLinkEntity;
  currentQuestionResult: QuizResultQuestionEntity;

  hasNextQuestion: boolean = false;
  hasPreviousQuestion: boolean = false; 
  //questionLinkIds: number[];

  quizStartTime: any;

  timeString: string;
  currentQuestionIndex?: number;
  totalQuestionCount?: number;
  quizTimerIntervalHandle: any;
  currentQuestionStartTime: any;
  timeTakingString: string;
  timeLabel: string = 'Time :';
  quizSetup: QuizSetupResult;

  isLoading = true;
  showQuiz = false;

  questionResultIconClass: string;
  questionResultTextNote: string;
  flagEnabled = false;
  flagSubmitted = false;

  constructor(private dateTimeUtilService: DateTimeUtilService,
    private arrayUtilService: ArrayUtilService,
    private quizRepositoryService: QuizRepositoryService,
    private quizUtilService: QuizUtilService,
    private lessonFlagUtilService: LessonFlagUtilService

  ) { }

  ngOnInit(): void {
    this.setupQuiz();
  }

  
  flagLesson() {
    if (this.flagSubmitted || !this.currentQuestionLink) {
      return;
    }
    
    this.lessonFlagUtilService.showQuestionFlagModal(this.progress.course.course.CourseId, this.progress.lesson.lesson.CourseLessonId, this.quiz.QuizId, this.currentQuestionLink.QuestionId)
      .subscribe(result => {
        if (!result.cancelled) {
          this.flagSubmitted= true;
        }
      });
  }

  setupQuiz() {
    if (!this.quiz) {
      return;
    }

    if (this.quiz.TimeLimitMinutes) {
      this.timeLabel = 'Time Remaining: ';
    }

    this.ensureQuizResult();
    this.setupQuestions();

    if (!this.reviewAnswers) {
      this.setupQuizTimer();
    } else {
      this.timeTakingString = this.dateTimeUtilService.formatDurationMinutesSeconds(this.quizResult.TotalTimeSeconds);
    }
  }

  setupQuizTimer() {
    this.quizStartTime = this.dateTimeUtilService.getCurrentDateTime();
    this.updateQuizTimer();
    this.quizTimerIntervalHandle = setInterval(() => {
      this.updateQuizTimer();

    }, 1000);
  }

  updateQuizTimer() {
    let timeInSeconds = this.dateTimeUtilService.calcDurationSeconds(this.quizStartTime, this.dateTimeUtilService.getCurrentDateTime());

    if (this.quiz.TimeLimitMinutes) {
      const timeLimit = this.quiz.TimeLimitMinutes * 60;

      if (timeInSeconds >= timeLimit) {

        this.submitQuiz();
        return;
      }

      const timeLeft = timeLimit - timeInSeconds;

      timeInSeconds = timeLeft;
    }

    const currentTime = this.dateTimeUtilService.formatDurationMinutesSeconds(timeInSeconds);

    // if (this.quiz.TimeLimitMinutes) {
    //   const timeLimint = this.dateTimeUtilService.formatDurationMinutesSeconds(this.quiz.TimeLimitMinutes * 60);

    //   this.timeString = `${currentTime} of ${timeLimint}`;
    // }

    this.timeString = currentTime;
  }

  ensureQuizResult() {
    if (!this.quizResult) {
      this.quizResult = {
        _itemName: AppMetaDataItemNames.QuizResult,
        QuizId: this.quiz.QuizId,
        StudentCourseId: this.progress?.course.studentCourse.StudentCourseId,
        CourseLessonProgressId: this.progress?.lesson?.lessonProgress?.CourseLessonProgressId,
        IsComplete: false,
        CompletedQuestions: 0,
        TotalQuestions: this.quiz.MaxQuestionsToShow || this.quiz.QuizQuestionLink.length,
        QuizStartDateTime: this.dateTimeUtilService.getCurrentDateTime()
      }
    }

    this.quizResult.QuizResultQuestion = this.quizResult.QuizResultQuestion || [];
  }

  message: DwMessage;
  setupQuestions() {

    this.quizRepositoryService.getQuizSetup({ QuizId: this.quiz.QuizId, StudentCourseId: this.progress?.course.studentCourse.StudentCourseId, QuizResultId: this.quizResult?.QuizResultId })
      .subscribe((quizSetup) => {
        //console.log('Quiz Setup', quizSetup);
        this.quizSetup = quizSetup;

        
        if (!quizSetup.IsSuccess) {
          this.message = {
            messageBody: quizSetup.ErrorMessage,
            severity: DwMessageSeverity.error
          };
          this.isLoading = false;

          return;
        }
        
        this.currentQuestionIndex = 0;
        this.totalQuestionCount = this.quizSetup.CurrentQuizQuestionLinkIds.length;
        
        if (this.totalQuestionCount>0) {
          this.setupStartingQuestion();

          this.setupCurrentQuestion();
          this.showQuiz = true;
        } else {
          this.message = {
            messageBody: 'No questions found for this quiz',
            severity: DwMessageSeverity.error
          };
          this.showQuiz = false
        }

        this.isLoading = false;
      });


    // // Map all question link ids
    // this.questionLinkIds = this.quiz.QuizQuestionLink.map(q => q.QuizQuestionLinkId);

    // if (this.quiz.UseRandomOrder && !this.reviewAnswers) {
    //   this.questionLinkIds = this.arrayUtilService.shuffle(this.questionLinkIds);

    //   if (this.quiz.MaxQuestionsToShow>0 && this.quiz.MaxQuestionsToShow < this.questionLinkIds.length) {
    //     this.questionLinkIds.length = this.quiz.MaxQuestionsToShow;
    //   }
    // } 

    // this.currentQuestionIndex = 0;
    // this.totalQuestionCount = this.questionLinkIds.length;

  }

  setupStartingQuestion() {
    
    if (!this.startingQuizResultQuestionId) {
      return;
    }

    let questionResult = this.quizResult.QuizResultQuestion.find(q => q.QuizResultQuestionId == this.startingQuizResultQuestionId);
    if (!questionResult) {
      return;
    }

    let questionLink = this.quizSetup.AllQuizQuestionLinks.find(ql => ql.QuestionId == questionResult.QuestionId);

    if (!questionLink) {
      return;
    }

    const questionIndex = this.quizSetup.CurrentQuizQuestionLinkIds.indexOf(questionLink.QuizQuestionLinkId);

    if (questionIndex>0) {
      this.currentQuestionIndex = questionIndex;
    }
  }

  previousQuestion() {
    this.currentQuestionIndex--;
   
    this.setupCurrentQuestion();
  }

  nextQuestion() {
    this.currentQuestionIndex++;

    this.setupCurrentQuestion();
  }

  submitQuiz() {
    if (!this.quizTimerIntervalHandle) {
      return;
    }

    clearInterval(this.quizTimerIntervalHandle);

    this.updateQuestionResultStats();

    this.quizResult.IsComplete = true;
    this.quizResult.QuizResultStatusId = QuizResultStatusAllItems.CompleteUngraded;

    this.quizResult.QuizStopDateTime = this.currentQuestionResult?.QuestionEndDateTime || this.dateTimeUtilService.getCurrentDateTime();

    let totalSessionQuizTime = this.dateTimeUtilService.calcDurationSeconds(this.quizStartTime, this.quizResult.QuizStopDateTime);
    if (this.quiz.TimeLimitMinutes && totalSessionQuizTime > this.quiz.TimeLimitMinutes * 60) {
      totalSessionQuizTime = this.quiz.TimeLimitMinutes * 60;
    }

    this.quizResult.TotalTimeSeconds = this.quizResult.TotalTimeSeconds || 0 + totalSessionQuizTime;

    // Prevent saving quizzes with no questions
    if (this.quizResult.QuizResultQuestion.length==0) {
      return;
    }

    this.quizRepositoryService.saveQuizResult(this.quizResult)
      .subscribe((quizResult) => {

        this.quizResult = quizResult;

        const result: QuizCompleteResult = {
          quizResult: this.quizResult,
          isCancelled: false
        };

        this.quizComplete.emit(result);
      });
  }

  cancel() {
    const result: QuizCompleteResult = {
      isCancelled: true,
      quizResult: this.quizResult
    };

    this.quizComplete.emit(result);
  }

  private setupCurrentQuestion() {

    // Update stats on previous question
   this.updateQuestionResultStats();

    this.hasNextQuestion = this.currentQuestionIndex < (this.totalQuestionCount - 1);
    this.hasPreviousQuestion = this.currentQuestionIndex > 0;

    const questionLinkId = this.quizSetup.CurrentQuizQuestionLinkIds[this.currentQuestionIndex];
 
    if (!questionLinkId) {
      console.warn('No question link id found for index', this.currentQuestionIndex);
      
      return;
    } 

    this.currentQuestionStartTime = this.dateTimeUtilService.getCurrentDateTime();

    this.currentQuestionLink = this.quizSetup.AllQuizQuestionLinks.find(q => q.QuizQuestionLinkId == questionLinkId);

    this.currentQuestionResult = this.quizResult?.QuizResultQuestion.find(q => q.QuestionId === this.currentQuestionLink.QuestionId);

    if (this.reviewAnswers) {
      this.questionResultIconClass = this.currentQuestionResult?.IsCorrect ? 'fa fa-check dw-green' : 'fa fa-times dw-red',
      this.questionResultTextNote = this.currentQuestionResult?.IsCorrect ? 'Correct' : 'Incorrect';
    }

    if (!this.currentQuestionResult) {
      this.currentQuestionResult = {
        _itemName: AppMetaDataItemNames.QuizResultQuestion,
        QuestionId: this.currentQuestionLink.QuestionId,
        AnswerValue: null,
        QuestionStartDateTime: this.dateTimeUtilService.getCurrentDateTime()
      };

      this.quizResult.QuizResultQuestion.push(this.currentQuestionResult);
    }
  }

  private updateQuestionResultStats() {
     // Update stats on previous question
     if (this.currentQuestionResult) {
      this.currentQuestionResult.QuestionEndDateTime = this.dateTimeUtilService.getCurrentDateTime();

      const questionTime = this.dateTimeUtilService.calcDurationSeconds(this.currentQuestionStartTime, this.currentQuestionResult.QuestionEndDateTime);
      this.currentQuestionResult.TotalTimeSeconds = (this.currentQuestionResult.TotalTimeSeconds || 0) + questionTime;
    }
  }

}
