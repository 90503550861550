import { Component, EventEmitter, OnInit } from '@angular/core';
import { AppMetaDataItemNames, LessonTypeAllItems, StudentByInstructorQueryEntity } from '../../../../../meta-data/app-meta-data.service';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwDataFormatService, DwFormatTypes, DwMediaRepositoryService, DwQueryMetaData, DwRoutingService } from '@devwareapps/devware-cap';
import { CourseNavigationService } from '../../../../courses/services/course-navigation.service';


@DwComponent({
  name: 'Student Card',
  key: 'studentCard',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: false,
  parentItemName: [AppMetaDataItemNames.Student, AppMetaDataItemNames.InstructorStudent],
}) 
@Component({
  selector: 'app-student-card',
  templateUrl: './student-card.component.html',
  styleUrls: ['./student-card.component.scss']
})
export class StudentCardComponent implements DwCardBaseComponent<any, StudentByInstructorQueryEntity> {
  cardConfig?: any; 
  data?: StudentByInstructorQueryEntity;
  resolvedContext?: any;
  cardClick: EventEmitter<any> = new EventEmitter<any>();
  imageUrl: string;
  defaultImageUrl: string;

  studentName: string;

  constructor(private dwDataFormatService: DwDataFormatService,
    private dwRoutingService: DwRoutingService,
    private courseNavigationService: CourseNavigationService,
    private dwMediaRepository: DwMediaRepositoryService
  ) { }
  queryMetaData?: DwQueryMetaData;

  setupCard() {
    this.defaultImageUrl = '/assets/img/user-avatar2.png';
    if (this.data?.DwroMediaMediaPath) {
      this.imageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.data.DwroMediaMediaPath}`;
    } else {
      this.imageUrl = '/assets/img/user-avatar2.png'
    }

    this.studentName = this.data?.UserIdDisplay ?  this.data?.UserIdDisplay : `${this.data?.DwUserFirstName} ${this.data?.DwUserLastName}`;
  }

  click() {
    this.cardClick.emit(this.data);
    // const url = `/manage-students/view/${this.data?.FlightSchoolStudentFlightSchoolStudentId}`;

    // this.dwRoutingService.navigateToUrl(url, null);
  }

}
