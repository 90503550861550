import { Inject, Injectable } from '@angular/core';
import { DevwareMetaData, DwAppPermissionEntity, DwAppPermissionMetaData, DwAttributeType, DwCacheService, DwContextDefinition, DwControlType, DwCustomContextBase, DwCustomContextDefinition, DwMdFormContextInfo, DwMenuService, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwQuery, DwQueryBuilderGen, DwQueryHelper, DwQueryMdAttribute, DwRoutingService, DwSecurityTopics, DwSecurityUserService } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, CourseEntity, RoModuleLessonMaxOrderEntity, StudentCourseForContextQueryBuilder, StudentCourseForContextQueryEntity, StudentCourseForContextQueryMetaDataAttributes } from '../../../meta-data/app-meta-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


export const StudentCourseEndorsementContext = 'studentCourseEndorsementContext';

@DwCustomContextDefinition({
    keyName: StudentCourseEndorsementContext,
    display: 'Student Course Endorsement Context',
    isGlobal: false,
    parentItem: AppMetaDataItemNames.Course
})
@Injectable()
export class StudentCourseEndorsementContextService extends DwCustomContextBase {
    query: DwQuery;

    
    constructor(private dwSecurityUserService: DwSecurityUserService,
        private dwRoutingService: DwRoutingService,
        private dwMenuService: DwMenuService,
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        private dwCacheService: DwCacheService

    ) {
        super();
    }

   
    setupContextDefinition(contextDef: DwContextDefinition) :Observable<void> {
        contextDef.buildQueryFunction = (context) => this.buildQuery(context);

        contextDef.item = AppMetaDataItemNames.StudentCourse;
        contextDef.parentItem = AppMetaDataItemNames.Course;
//        contextDef.metaDataQueryId = 79; // Need to switch this to use Query meaning which we generate in metadata

        contextDef.getCustomContextKey = (context) => {
            const course: CourseEntity = context[DwMdFormContextInfo.DATA_CONTEXT_NAME];

            return course?.CourseId;
        };

        contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.StudentCourse.ItemDetail.itemName));
        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.CourseEndorsementStudent.ItemDetail.itemName));

        return AppMetaData.Queries.StudentCourseForContext.CreateQueryBuilderWithMetaData(this.dwMetaDataService)
            .pipe(map(result => {
                this.query = result.queryBuilder.query;
                
                contextDef.attributes = result.queryMetaData.Attributes;
            }));
    }

    buildQuery(resolvedContext: any): DwQuery {
        const course: CourseEntity = resolvedContext[DwMdFormContextInfo.DATA_CONTEXT_NAME];

        if (!course) {
            return null;
        }
        const qh = new DwQueryHelper();

        const query = AppMetaData.Queries.StudentCourseForContext.CreateQueryBuilderFromQuery(qh.cloneQuery(this.query));
        
        if (!this.dwSecurityUserService.securityContext?.ApplicationUser) {
            return null;
        }
   
        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        
        const user = this.dwSecurityUserService.securityContext.ApplicationUser;
        
        query.addFilterAnd(filters => filters.Equal(f => f.StudentUserId, user?.UserId || -1));
        query.addFilterAnd(filters => filters.Equal(f => f.CourseId, course.CourseId));

        return query.query;
    }


}
