import { Injectable } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, VideoHistoryEntity } from "../../../meta-data/app-meta-data.service";
import { VideoUploadModalComponent } from "../components/video-upload-modal/video-upload-modal.component";
import { map } from "rxjs/operators";
import { VideoUploadService } from "../services/video-upload.service";


@DwAction({
    keyName: 'video-revert-to-history-action',
    display: 'Revert History',
    actionType: DwActionType.gridRowAction,
    parentItemName: AppMetaDataItemNames.VideoHistory,
    isGlobal: true,
    defaultButtonConfig: {
        useParentDisplay: true,
        iconClass: 'fa fa-video',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'Reverts this video to a previous version'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    },
    defaultConfirmConfig: {
        showDialogConfig: true,
        showConfirmDialog: true,
        message: 'Are you sure you wish to revert this video?  This will create a new history record with the current video details',
        title: 'Revert Video to History'
    }
})
@Injectable()
export class VideoRevertToHistoryActionService implements DwActionHandler {
    constructor(
        /*  @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
         private route: ActivatedRoute,
         private metaDataRoutingService: DwMetaDataRoutingService, */
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private videoUploadService: VideoUploadService,
        private dwRoutingService: DwRoutingService
    ) { }


    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {

        const videoHistory : VideoHistoryEntity = actionState.data;
        const result = new DwGridActionResult();

        if (!videoHistory.VideoHistoryId) {
            result.cancelled = true;
            result.message = 'Video history record must be provided';

            return of(result)
        }

        return this.videoUploadService.revertToVideoHistory(videoHistory.VideoHistoryId)
            .pipe(map(() => {
                const result = new DwGridActionResult();

                this.dwRoutingService.navigateToUrl('/lms-admin/video-management/videos', null);

                result.refreshData = false;

                return result;
            }));
    }
}