import { Injectable } from "@angular/core";
import { QuestionAnswerEntity, QuestionTypeAllItems, QuizEntity, QuizQuestionLinkEntity, QuizResultEntity, QuizResultQuestionEntity, QuizResultStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { QuizDisplayDetailCard, QuizDisplayResult } from "../models/quiz-display-result.model";
import { DateTimeUtilService } from "../../shared/util/date-time-util.service";
import { QuestionAnswerReview, QuestionAnswerReviews } from "../models/question-answer-review.model";
import { QuestionAnswerValue } from "../models/question-answer-value.model";
import { QuizSetupResult } from "../models/quiz-setup-result.model";
import { QuizRepositoryService } from "./quiz-repository.service";
import { ArrayUtilService } from "../../shared/util/array-util.service";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class QuizUtilService {
    constructor(private dateTimeUtilService: DateTimeUtilService,
        private quizResultService: QuizRepositoryService,
        private arrayUtilService: ArrayUtilService
    ) { }

    // setupQuizQuestions(quiz: QuizEntity, reviewAnswers: boolean): Observable<QuizSetupResult> {
    //     const result: QuizSetupResult = {
    //         allQuestionLinks: [],
    //         questionLinkIds: [],
    //         totalQuestionCount: 0
    //     };



    //     // Map all question link ids
    //     const coreQuestionLinkIds = quiz.QuizQuestionLink.map(q => q.QuizQuestionLinkId);



    //     if (quiz.UseRandomOrder && !reviewAnswers) {
    //         result.questionLinkIds = this.arrayUtilService.shuffle(coreQuestionLinkIds);

    //         if (quiz.MaxQuestionsToShow > 0 && quiz.MaxQuestionsToShow < result.questionLinkIds.length) {
    //             result.questionLinkIds.length = quiz.MaxQuestionsToShow;
    //         }
    //     }

    //     result.totalQuestionCount = result.questionLinkIds.length;

    //     return of(result)
    // }

    getQuizDisplayResult(quiz: QuizEntity, quizResult: QuizResultEntity): QuizDisplayResult {
        let displayResult: QuizDisplayResult = {
            detailCards: []
        } as any;

        quizResult.QuizResultStatusId = quizResult.QuizResultStatusId || QuizResultStatusAllItems.Failed;

        switch (quizResult.QuizResultStatusId) {
            case QuizResultStatusAllItems.Incomplete:
                displayResult.headerText = 'Quiz Incomplete';
                displayResult.headerIconClass = "fa fa-circle-info dw-yellow";
                displayResult.containerClass = 'dw-yellow-border';

                return displayResult;

            case QuizResultStatusAllItems.CompleteUngraded:
                displayResult.headerText = 'Quiz Not graded';
                displayResult.headerIconClass = "fa fa-circle-info dw-yellow";
                displayResult.containerClass = 'dw-yellow-border';

                return displayResult;

            case QuizResultStatusAllItems.Passed:
                displayResult.headerText = 'Quiz Passed';
                displayResult.headerIconClass = "fa fa-check-circle dw-green";
                displayResult.containerClass = 'dw-green-border';

                break;

            case QuizResultStatusAllItems.Failed:
                displayResult.headerText = 'Quiz Failed';
                displayResult.headerIconClass = "fa fa-times-circle dw-red";
                displayResult.containerClass = 'dw-red-border';

                break;
        }

        displayResult.detailCards = this.getQuizResultDetailCards(quiz, quizResult);

        return displayResult;
    }

    getQuizResultDetailCards(quiz: QuizEntity, quizResult: QuizResultEntity): QuizDisplayDetailCard[] {
        const cards: QuizDisplayDetailCard[] = [];

        cards.push({
            headerText: 'My Score',
            detail: Math.round(quizResult.PercentageScore) + '%',
            notes: `Passing: ${quiz.PassingPercent}%`,

            iconClass: 'fa fa-bullseye'
        });


        let timeAllowed = 'None';
        if (quiz.TimeLimitMinutes) {
            timeAllowed = this.dateTimeUtilService.formatDurationMinutesSeconds(quiz.TimeLimitMinutes * 60);
        }

        cards.push({
            headerText: 'Time',
            detail: this.dateTimeUtilService.formatDurationMinutesSeconds(quizResult.TotalTimeSeconds),
            notes: `Limit: ${timeAllowed}`,
            //containerClass: 'dw-green-border',
            iconClass: 'fa fa-clock'
        });

        const correctAnswers = quizResult.QuizResultQuestion.filter(q => q.IsCorrect).length;

        cards.push({
            headerText: 'Correct',
            detail: `${correctAnswers} / ${quizResult.CompletedQuestions}`,
            notes: 'Questions: ' + quizResult.TotalQuestions,
            //containerClass: 'dw-green-border',
            iconClass: 'fa fa-check-circle'
        });

        return cards;
    }


    getAnswerReviews(questionLink: QuizQuestionLinkEntity, questionResult: QuizResultQuestionEntity, questionAnswerValue: QuestionAnswerValue, answersToShow?: QuestionAnswerEntity[]): QuestionAnswerReviews {
        if (!questionLink || !questionResult || !questionAnswerValue) {
            return {};
        }

        const question = questionLink.Question;

        switch (question.QuestionTypeId) {
            case QuestionTypeAllItems.TrueOrFalse:
                return this.getAnswerNotesTrueFalse(questionLink, questionResult, questionAnswerValue);
            case QuestionTypeAllItems.MultipleAnswer:
            case QuestionTypeAllItems.MultipleChoice:
                return this.getAnswerNotesMultiple(questionLink, questionResult, questionAnswerValue, answersToShow);
        }

        return {};
    }

    getAnswerNotesTrueFalse(questionLink: QuizQuestionLinkEntity, questionResult: QuizResultQuestionEntity, questionAnswerValue: QuestionAnswerValue): QuestionAnswerReviews {
        const question = questionLink.Question;

        const answerReviews: QuestionAnswerReviews = {};

        const index = questionAnswerValue.trueFalseAnswer ? 0 : 1;

        answerReviews[index] = {
            iconClass: questionResult.IsCorrect ? 'fa fa-check dw-green' : 'fa fa-times dw-red',
            textNote: questionResult.IsCorrect ? 'Correct' : 'Incorrect'
        };

        return answerReviews;
    }


    getAnswerNotesMultiple(questionLink: QuizQuestionLinkEntity, questionResult: QuizResultQuestionEntity, questionAnswerValue: QuestionAnswerValue, answersToShow: QuestionAnswerEntity[]): QuestionAnswerReviews {
        const question = questionLink.Question;

        const answerReviews: QuestionAnswerReviews = {};

        const answersWithDetail = questionAnswerValue.originalQuestion?.QuestionAnswer || [];


        for (const answer of answersToShow) {
            const answerWithDetail = answersWithDetail.find(a => a.QuestionAnswerId === answer.QuestionAnswerId) || answer;

            const isSelected = questionAnswerValue?.quizQuestionAnswerId == answer.QuestionAnswerId || questionAnswerValue?.quizQuestionAnswerIds?.includes(answer.QuestionAnswerId);

            if (!isSelected) {
                continue;
            }

            const index = answersToShow.indexOf(answer);

            answerReviews[index] = {
                iconClass: answerWithDetail?.IsCorrectAnswer ? 'fa fa-check dw-green' : 'fa fa-times dw-red',
                textNote: answerWithDetail?.IsCorrectAnswer ? 'Correct' : 'Incorrect',
                explanationText: answerWithDetail?.ExplanationText,
                explanationTextClass: answerWithDetail?.IsCorrectAnswer ? 'dw-green' : 'dw-red',
            };
        }

        return answerReviews;
    }
}