import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwFormatTypes, DwMenuService, DwMetaDataListCardConfigData, DwMetaDataService, DwMetaDataServiceToken, DwQueryMetaData, DwRoutingService, isAfterDate } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, CourseEntity, CourseListForStudentQueryEntity, StudentCourseProgressQueryEntity } from '../../../../../meta-data/app-meta-data.service';
import { DwCardBaseConfig } from '../../../../z-devware/cards/models/dw-card-base-config.model';
import { DwCardItemResult } from '../../../../z-devware/cards/models/dw-card-item-result.model';
import { DateTimeUtilService } from '../../../../shared/util/date-time-util.service';


@DwComponent<DwMetaDataListCardConfigData>({
  name: 'Course Card - Student Progress',
  key: 'courseCard-StudentProgress',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: false,
  parentItemName: AppMetaDataItemNames.Student,
  configData: {
    contentItemDefinitions: [{
      display: 'Main',
      contentItemKey: 'main'
    }]
  }
})
@Component({
  selector: 'app-course-student-progress-card',
  templateUrl: './course-student-progress-card.component.html',
  styleUrls: ['./course-student-progress-card.component.scss']
})
export class CourseStudentProgressCardComponent implements DwCardBaseComponent<any, StudentCourseProgressQueryEntity>, OnInit, OnChanges {
  cardConfig?: any;
  @Input() data?: StudentCourseProgressQueryEntity;
  resolvedContext?: any;
  cardClick: EventEmitter<any> = new EventEmitter<any>();

  queryMetaData?: DwQueryMetaData;

  cardDesignerConfig?: DwCardBaseConfig;

  cardItemResult: DwCardItemResult<StudentCourseProgressQueryEntity>;

  progressPercent = 22;
  title: string;
  endorsed: boolean;
  expired: boolean;
  constructor(
    @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
    private dwRoutingService: DwRoutingService,
    private dateTimeUtilService: DateTimeUtilService,
    private dwMenuService: DwMenuService
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.setupCard();
    }
  }
  
  setCardItemResult(cardItemResult: any): void {
    this.cardItemResult = cardItemResult;
  }

  setupCard() {
    const attrs = AppMetaData.Queries.StudentCourseProgress.Attributes;

    this.title = this.data.CourseCourseName;
    this.progressPercent = this.data?.StudentCoursePercentComplete;

    if (this.data?.StudentCourseIsEndorsed) {
      this.endorsed = true;
      this.progressPercent = 100;

      if (isAfterDate(this.data?.CourseEndorsementStudentExpirationDateTime)) {
        this.expired = true;
        this.endorsed = false;
      }
    }



    
    this.cardDesignerConfig = {
      titleField: null, // attrs.CourseCourseName.fieldName,
      descriptionField: '',
      image: {
        mediaPathField: attrs.CourseImageDwroMediaMediaPath.fieldName,
        defaultImage: {
          mediaFullUrl: '/assets/aviator-online/img/course-image-default.png',
        }
      }, 
      additionalFields: [
        {
          key: 'started',
          fieldName: attrs.StudentCourseFirstActivityDateTime.fieldName,
          formatType: DwFormatTypes.date,
          prefix: 'Started: ',
        },
        { 
          key: 'lastActivity',
          fieldName: attrs.StudentCourseLastActivityDateTime.fieldName,
          formatType: DwFormatTypes.date,
          prefix: 'Last Activity: ',
        },
        {
          key: 'timeSpent',
          fieldName: attrs.StudentCourseTotalTimeSeconds.fieldName,
          prefix: 'Time Spent: ',
          valueFormatter: (value: any) => {
            return this.dateTimeUtilService.formatDuration(value);
          }
        },
        
      ]
    }
    if (!this.queryMetaData) {
      this.dwMetaDataService.getSavedQueryMetaData(AppMetaDataItemNames.Course, null, AppMetaData.Queries.StudentCourseProgress.QueryMeaning, false)
        .subscribe(queryMetaData => {
          this.queryMetaData = queryMetaData;
        });
    }
  }

  click() {
    this.cardClick.emit(this.data);
    // const menu = this.dwMenuService.getMenuByKey('45');

    // const isView = this.dwMenuService.menuState.currentMenu.url.indexOf('/view') >= 0;


    
    // let url = `${menu.url}/edit/${this.data.FlightSchoolStudentFlightSchoolStudentId}/course-progress/${this.data.StudentCourseStudentCourseId}`;
    
    // if (isView) {
    //   url = `${menu.url}/view/${this.data.FlightSchoolStudentFlightSchoolStudentId}/course-progress/${this.data.StudentCourseStudentCourseId}`;
    // }

    // this.dwRoutingService.navigateToUrl(url, null);
  }

}
