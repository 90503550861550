import { Inject, Injectable } from "@angular/core";
import { AppMetaData, AppMetaDataItemNames, CourseEntity, CourseLessonEntity, CourseLessonProgressEntity, CourseLessonSessionEntity, CourseModuleProgressEntity, LessonTypeAllItems, StudentCourseEntity, VideoEntity, VideoLibraryEntity, VideoStreamingProviderEntity } from "../../../meta-data/app-meta-data.service";
import { DevwareMetaData, DwCacheService, DwEventService, DwMetaDataService, DwMetaDataServiceToken, DwOrmData, DwOrmDataService, DwOrmDataServiceToken, DwSecurityTopics, DwSecurityUserService, areEqual, deepCopy } from "@devwareapps/devware-cap";
import { Observable, combineLatest, of } from "rxjs";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";


@Injectable({ providedIn: 'root' })
export class VideoRepositoryService {

    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        private dwEventService: DwEventService,
        private router: Router
    ) {

    }

    getStreamingProvider(streamingProviderId: number): Observable<VideoStreamingProviderEntity> {
        return this.getStreamingProviders()
            .pipe(map(providers => {
                const provider = providers.find(p => p.VideoStreamingProviderId == streamingProviderId);

                return provider;
            }));
    }

    getVideoLibrary(streamingProvider: VideoStreamingProviderEntity, videoLibraryId: number): VideoLibraryEntity {
        return streamingProvider.VideoLibrary.find(v => v.VideoLibraryId == videoLibraryId);
    }


    getStreamingProviders(): Observable<VideoStreamingProviderEntity[]> {
        const cacheInvalidationTopics = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.VideoStreamingProvider));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.VideoLibrary));

        return this.dwCacheService.get('streaming-providers', this.loadStreamingProviders(), null, cacheInvalidationTopics);
    }

    getVideos(videoIds: number[]) : Observable<VideoEntity[]> {
        const query = AppMetaData.Video.CreateQueryBuilder();

        query.addFilterAnd(f => f.InList(a => a.VideoId, videoIds));


        return this.dwOrmDataService.executeQuery(query);
    }

    private loadStreamingProviders(): Observable<VideoStreamingProviderEntity[]> {
        const query = AppMetaData.VideoStreamingProvider.CreateQueryBuilder();

        query.addPrefetch(r => r.VideoLibrary);

        return this.dwOrmDataService.executeQuery(query);
    }

}