import { Component, Input, OnInit } from '@angular/core';
import { CourseRepositoryService } from '../../services/course-repoistory.service';
import { CourseEntity, CourseLessonEntity, CourseModuleEntity } from '../../../../meta-data/app-meta-data.service';
import { DwComponent, DwComponentType, DwRoutingService, DwSecurityUserService } from '@devwareapps/devware-cap';
import { Router } from '@angular/router';
import { CourseNavigationService } from '../../services/course-navigation.service';
import { LessonReferenceInfo } from '../../models/lesson-reference-info.model';
import { CourseProgressItem, ModuleProgressItem } from '../../models/course-progress-hierarchy.model';



@DwComponent({
  name: 'Course Navigation',
  key: 'courseNavigation',
  isGlobal: true,
  componentType: DwComponentType.sideMenuReplacement
})
@Component({ 
  selector: 'app-course-navigation', 
  templateUrl: './course-navigation.component.html',
  styleUrls: ['./course-navigation.component.scss']
})
export class CourseNavigationComponent implements OnInit {
  currentLessonReference: LessonReferenceInfo;
  courseProgress: CourseProgressItem;

  moduleCollapsed: { [moduleId: number]: boolean } = {};


  constructor(
    private courseRepositoryService: CourseRepositoryService, 
    private courseNavigationService : CourseNavigationService,
    private dwSecurityUserService: DwSecurityUserService,
    private router: Router,     
    private dwRoutingService: DwRoutingService) { 
    //  console.log('CourseNavigationComponent constructor');

    }
 
  ngOnInit(): void {
    this.setCurrentLesson(); 
    this.loadCourse();

    this.dwRoutingService.getRoutesChanges().subscribe(() => {
      this.setCurrentLesson();
    });
 
    this.courseRepositoryService.getCourseProgressUpdate()
      .subscribe(() => {
        this.loadCourse();
      });

    this.dwSecurityUserService.securityContextChanged().subscribe(() => {
      this.loadCourse();
    });
  }

  toggleModuleCollapse(moduleProgress: ModuleProgressItem) {
    this.moduleCollapsed[moduleProgress.module.CourseModuleId] = !this.moduleCollapsed[moduleProgress.module.CourseModuleId];
  }

  loadCourse() {
    this.courseRepositoryService.getCourseProgress(this.currentLessonReference?.courseId)
      .subscribe(courseProgress => {
        this.courseProgress = courseProgress;

        this.setupModuleCollapsed();
      })
  }

  setCurrentLesson() {
    const newLessonReference = this.courseNavigationService.getCourseUrlParameters(this.router.url);

    if(this.currentLessonReference && this.currentLessonReference?.courseId != newLessonReference?.courseId) {
      this.loadCourse();
    }

    this.currentLessonReference = newLessonReference;

    this.setupModuleCollapsed();
  }

  navigateToModule(module: CourseModuleEntity) {
    this.courseNavigationService.navigateToModule(module.CourseId, module.CourseModuleId);
    this.hideMobile();
  }

  navigateToLesson(module: CourseModuleEntity, lesson: CourseLessonEntity) {
    this.courseNavigationService.navigateToLesson( { courseId: module.CourseId, moduleId: module.CourseModuleId, lessonId: lesson.CourseLessonId});
    this.hideMobile();
  }

  hideMobile() {
		if (document.body.classList.contains('sidebar-show')) {
            document.body.classList.toggle('sidebar-show');
        }
	}

  private setupModuleCollapsed() {
    if (!this.courseProgress) {
      return;
    }

    this.moduleCollapsed = {};

    for(const moduleProgress of this.courseProgress.modules) {
      this.moduleCollapsed[moduleProgress.module.CourseModuleId] = true;
    }

    if (this.currentLessonReference?.moduleId) {
      this.moduleCollapsed[this.currentLessonReference.moduleId] = false;
    } else {
      if (this.courseProgress.furtherestLesson?.CourseModuleId) {
        this.moduleCollapsed[this.courseProgress.modules[0].module.CourseModuleId] = false;  
      } else {
        this.moduleCollapsed[this.courseProgress.modules[0].module.CourseModuleId] = false;
      }
    }
  }

}  
