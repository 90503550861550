import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VideoComponentBase } from '../../../../../models/video-component.base.interface';
import { VideoEntity, VideoLibraryEntity, VideoStreamingProviderEntity } from '../../../../../../../meta-data/app-meta-data.service';
import { VideoEvent } from '../../../../../models/video-event.model';
import { VideoEventType } from '../../../../../models/video-event-type.enum';
import { BunnyTimeData } from '../../models/bunny-time-data.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VideoPlayerBaseComponent } from '../../../base/video-player-base.component';
import { DwSecurityUserService } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-video-player-bunny',
  template: `
    <iframe id="iframe" #player [src]="videoUrlSafe"
    loading="lazy" style="border:0;position:absolute;top:0;height:100%;width:100%;"
    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
  `
})
export class VideoPlayerBunnyComponent extends VideoPlayerBaseComponent implements VideoComponentBase, AfterContentInit {
  videoUrl: string;
  videoUrlSafe: SafeUrl;

  @ViewChild('iframe') playerIframe: HTMLIFrameElement;
  message: string;
  player: any;
  userSeeked: boolean = false;
  lastTimeData: BunnyTimeData;
  ignoreNextStopEvent: boolean;
  seekFurthestTime: boolean;
  videoStartComplete: boolean;
  videoDuration: any;

  constructor(dwSecurityUserService: DwSecurityUserService, private sanitizer: DomSanitizer, private hostElementRef: ElementRef) {
    super(dwSecurityUserService);
   }

  setupVideo(): void {
    super.setupVideo();
    
    const videoKey = this.video?.VideoKey;
    const videoLibraryKey = this.videoLibrary?.VideoLibraryKey;

    if (!videoKey || !videoLibraryKey) {
      return;
    }


    // this didn't look like it will change so didn't make it a configurable option
    const responsive = true;

    this.videoUrl = `https://iframe.mediadelivery.net/embed/${videoLibraryKey}/${videoKey}?autoplay=${this.videoStreamingProvider.AutoPlay}&loop=false&muted=false&preload=${this.videoStreamingProvider.PreloadVideo}&responsive=${responsive}`;

    this.videoUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }


  ngAfterContentInit(): void {
    this.playerIframe = this.hostElementRef?.nativeElement?.children?.iframe;
    this.setupPlayer();
  }

  setupPlayer() {
    if (this.player) {
      return;
    }

    if (!this.playerIframe) {
      console.warn('Could not find iframe element');
      return;
    }

    // set src attribute to player js can find it (it doesn't work with Angular bound src attribute)
    this.playerIframe.src = this.videoUrl;

    const playerJs = (window as any).playerjs;

    if (!playerJs) {
      this.message = 'Could not find playerjs';
      console.warn('Could not find Bunny.Net playerjs for Video Events');
      return;
    }

    this.player = new playerJs.Player('iframe');

    this.message = 'Player setup';

    this.player.on('play', (data) => {
     // console.log('play event', data);
      if (this.seekFurthestTime) {
        this.seekFurthestTime = false;

        this.player.setCurrentTime(this.furthestWatchedTime);

        if (!this.videoStreamingProvider.AutoPlay) {
          setTimeout(() => {
            this.player.pause();
          },200);
        }
      }

      this.onVideoEvent(VideoEventType.videoStarted);
    });

    this.player.on('pause', (data) => {
      
      if (this.ignoreNextStopEvent) {
        this.ignoreNextStopEvent = false;
        return;
      }

      this.onVideoEvent(VideoEventType.videoPaused);
    });

    this.player.on('ended', (data) => {
      if (this.ignoreNextStopEvent) {
        this.ignoreNextStopEvent = false;
        return;
      }

      this.onVideoEvent(VideoEventType.videoStopped);
    });

    this.player.on('timeupdate', (timeData: BunnyTimeData) => {

      const timeUpdateResult = this.updateCurrentTime(timeData.seconds);

      if (timeUpdateResult.userSeeked) {
        this.player.setCurrentTime(this.furthestWatchedTime);
      }

      // if (timeData.seconds > this.furthestWatchedTime) {
      //   const changeSeconds = timeData.seconds - this.furthestWatchedTime;

      //   const belowSeekThreshhold = changeSeconds <= (this.videoStreamingProvider.SeekThreshholdSeconds || 5);

      //   if (belowSeekThreshhold) {
      //     this.userSeeked = false;
      //     this.furthestWatchedTime = timeData.seconds;
      //   } else {
      //     if (this.videoStreamingProvider.AllowSeek) {
      //       this.furthestWatchedTime = timeData.seconds;
      //     }
      //     else {
      //       this.player.setCurrentTime(this.furthestWatchedTime);
      //       this.userSeeked = true;
      //     }
      //   }
      // }
      this.onVideoEvent(VideoEventType.videoProgress);

    });

    
    this.player.on("ready", () => this.initVideoPlayer());

    // setTimeout(() => {
    //   this.setupVideoStartOptions();
    // }, 100);

  }

  initComplete = false;

  initVideoPlayer() {
    
    if (this.initComplete) {
      
      return;
    }


    if (!this.player.isReady) {

      setTimeout(() => 
        this.setupVideoStartOptions(), 100);

      return;
    }

    this.player.getDuration((duration) => {
      this.videoDuration = duration;

      if (this.videoDuration > 0) {
        this.initComplete = true;
        this.setupVideoStartOptions();
        return;
      }

      setTimeout(() => {
        this.initVideoPlayer();
      }, 100);
    });

  }

  setupVideoStartOptions() {
    if (this.videoStartComplete) {
      return;
    }

    this.videoStartComplete = true;

    if (this.videoStreamingProvider.ContinueFromLastPosition) {
      this.seekFurthestTime = true;

      if (this.furthestWatchedTime >= this.videoDuration) {
       // console.log('Already at end of video');
        return;
      }

    //  console.log(`Seeking to furthest watched time   Furthest: ${this.furthestWatchedTime}  Duration: ${this.videoDuration}`);

      this.player.play();
      return;
    }
    
    if (this.videoStreamingProvider.AutoPlay) {
      this.player.play();
    }
    
    // if (this.videoStreamingProvider.ContinueFromLastPosition) {
    //   if (!this.videoStreamingProvider.AutoPlay) {
    //     this.player.play();

    //     this.seekFurtherTime = true;
    //   }

    //   setTimeout(() => {
    //     this.player.setCurrentTime(this.furthestWatchedTime);

    //     if (!this.videoStreamingProvider.AutoPlay) {
    //       this.player.pause();
    //       this.ignoreNextStopEvent =true;
    //       setTimeout(() => {
    //         this.player.pause();
    //       },50);
    //     }

    //   }, 100);
    // }

  }

  // onVideoEvent(eventType: VideoEventType, timeData: BunnyTimeData) {

  //   if ((eventType === VideoEventType.videoStopped || eventType === VideoEventType.videoPaused) && this.ignoreNextStopEvent) {
  //     this.ignoreNextStopEvent = false;
  //     return;
  //   }

  //   if (timeData) {
  //     this.lastTimeData = timeData;
  //   } else {
  //     timeData = this.lastTimeData;
  //   }

  //   const videoEvent: VideoEvent = {
  //     videoEventType: eventType,
  //     videoTime: timeData?.seconds,
  //     videoDuration: timeData?.duration || this.videoDuration,
  //     furtherestVideoTime: this.furthestWatchedTime,
  //     userSeeked: this.userSeeked
  //   };

  //   this.videoEvent.emit(videoEvent);
  // }
}
