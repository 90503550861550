import { Component, OnInit } from '@angular/core';
import { DwRadioItem } from '../quiz-answer-true-false/quiz-answer-true-false.component';
import { QuizAnswerBaseComponent } from '../base/quiz-answer-base.component';
import { QuestionAnswerEntity } from '../../../../../meta-data/app-meta-data.service';
import { ArrayUtilService } from '../../../../shared/util/array-util.service';
import { QuestionAnswerReviews } from '../../../models/question-answer-review.model';
import { QuizUtilService } from '../../../services/quiz-util.service';

@Component({
  selector: 'app-quiz-answer-multiple-choice',
  template: `
  <div *ngFor="let answer of answersToShow; let i = index" id="check-item" class="check-item">
    <div class="check-label">
      <label>
        <input type="radio" class="check-input" name="answer" [disabled]="reviewAnswers" [(ngModel)]="questionAnswerValue.quizQuestionAnswerId" [value]="answer.QuestionAnswerId" (ngModelChange)="setAnswer($event, answer)" />
        <div class="check-item-text"> {{answer.AnswerText}}</div>
      </label>
    </div> 
    
    <div *ngIf="answerReviews[i] && reviewAnswers" class="answer-review">
      <i class="answer-icon" [ngClass]="answerReviews[i].iconClass"></i>
      <span class="answer-note">{{answerReviews[i].textNote}}</span>
      <span class="answer-explanation" [ngClass]="answerReviews[i].explanationTextClass">{{answerReviews[i].explanationText}}</span>
    </div>
  </div>

  `
})
export class QuizAnswerMultipleChoiceComponent extends QuizAnswerBaseComponent {
  answersToShow: QuestionAnswerEntity[] = [];
  answerReviews: QuestionAnswerReviews = {};
  
  constructor(private arrayUtilService: ArrayUtilService, private quizUtilService: QuizUtilService) {
    super();
  }

  setAnswer(value, answer: QuestionAnswerEntity) {

    this.questionAnswerValue.quizQuestionAnswerId = answer.QuestionAnswerId;

    this.setAnswerValue(this.questionAnswerValue, this.questionAnswerValue.quizQuestionAnswerId>0);
  }

  setupAnswer(): void {
    // Set answers to use default order
    this.answersToShow = [...this.question.QuestionAnswer];

    if (this.questionAnswerValue.answerOrder) {
      this.answersToShow = this.answersToShow.sort((a, b) => {
        return this.questionAnswerValue.answerOrder.indexOf(a.QuestionAnswerId) - this.questionAnswerValue.answerOrder.indexOf(b.QuestionAnswerId);
      });

      if (this.reviewAnswers) { 
        this.answerReviews = this.quizUtilService.getAnswerReviews(this.questionLink, this.questionResult, this.questionAnswerValue, this.answersToShow);
      }
      return;
    }

    // Setup new order
    if (this.question.UseRandomOrder) {
      this.answersToShow = this.arrayUtilService.shuffle(this.answersToShow);
    }

    // Set the answer order so when we view it later, it will be in the same order
    this.questionAnswerValue.answerOrder = this.answersToShow.map((answer) => answer.QuestionAnswerId);
  }
}

