import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { DwCardBaseConfig, DwCardFieldConfig, DwCardImageConfig, DwCardImageItemConfig } from "../models/dw-card-base-config.model";
import { DwCardItemResult } from "../models/dw-card-item-result.model";
import { DwAttributeType, DwDataFormatService, DwFormatOptions, DwFormatTypes, DwMediaRepositoryService, DwQueryMdAttribute, DwQueryMetaData, DwUIMetaDataConfig, DwUIMetaDataConfigToken } from "@devwareapps/devware-cap";

@Injectable({ providedIn: 'root' })
export class DwCardUtilService {

    constructor(private dwDataFormatService: DwDataFormatService,
        @Inject(DwUIMetaDataConfigToken) private uiMetaDataConfig: DwUIMetaDataConfig,
        private dwMediaRepository: DwMediaRepositoryService,
    ) {

    }

    processCardData<DataEntity = any>(queryMetaData: DwQueryMetaData, cardConfig: DwCardBaseConfig, data: DataEntity): Observable<DwCardItemResult<DataEntity>> {
        const result: DwCardItemResult<DataEntity> = {};

        result.title = this.getFieldValue(data, queryMetaData, cardConfig.titleField);
        result.description = this.getFieldValue(data, queryMetaData, cardConfig.descriptionField);
        result.imageUrl = this.getImageUrl(data, queryMetaData, cardConfig.image);
        result.backupImageUrl = this.getImageUrl(data, queryMetaData, cardConfig.image, true);

        if (cardConfig.additionalFields?.length > 0) {
            result.additionalFields = {};

            for (let field of cardConfig.additionalFields) {
                const key = typeof field === 'string' ? field : field.key || field.fieldName;

                result.additionalFields[key] = this.getFieldValue(data, queryMetaData, field);
            }
        }

        return of(result);
    }

    private getImageUrl<DataEntity = any>(data: DataEntity, queryMetaData: DwQueryMetaData, imageConfig: DwCardImageConfig, backupImage: boolean = false): string {

        const imageUrls: string[] = [];

        if (!imageConfig) {
            return '';
        }

        let fullImagePath = this.getImageItemUrl(data, queryMetaData, imageConfig);

        if (fullImagePath) {
            imageUrls.push(fullImagePath);
        }

        fullImagePath = this.getImageItemUrl(data, queryMetaData, imageConfig.altImage);

        if (fullImagePath) {
            imageUrls.push(fullImagePath);
        }

        fullImagePath = this.getImageItemUrl(data, queryMetaData, imageConfig.defaultImage);

        if (fullImagePath) {
            imageUrls.push(fullImagePath);
        }

        if (!backupImage) {
            return imageUrls[0];
        } else {
            return imageUrls[1];
        }
    }

    private getImageItemUrl<DataEntity = any>(data: DataEntity, queryMetaData: DwQueryMetaData, imageItemConfig: DwCardImageItemConfig): string {
        if (imageItemConfig?.mediaFullUrl) {
            return imageItemConfig.mediaFullUrl;
        }

        if (imageItemConfig?.mediaFullUrlField) {
            return this.getFieldValue(data, queryMetaData, imageItemConfig.mediaFullUrlField);
        }

        if (imageItemConfig?.mediaPathField) {
            const imagePath = this.getFieldValue(data, queryMetaData, imageItemConfig?.mediaPathField);

            if (imagePath) {
                return `${this.dwMediaRepository.mediaApi}/download/${imagePath}`;
            }
        }

        return '';
    }

    private getFieldValue<DataEntity extends {}>(data: DataEntity, queryMetaData: DwQueryMetaData, fieldInfo: string | DwCardFieldConfig): string {
        if (!fieldInfo) {
            return "";
        }

        let cardFieldConfig: DwCardFieldConfig;

        if (typeof fieldInfo === 'string') {
            cardFieldConfig = { fieldName: fieldInfo };
        } else {
            cardFieldConfig = fieldInfo;
        }


        const attr = queryMetaData.Attributes.find(a => a.AttributeName == cardFieldConfig.fieldName || a.DisplayAttributeName == cardFieldConfig.fieldName);

        if (!attr) {
            return;
        }

        //let value = data[cardFieldConfig.fieldName];
        let value = data[attr.DisplayAttributeName];

        if (!value && value !== false && value !== 0) {
            value = cardFieldConfig.blankValue || '';
        }

        // If we don't have metadata, then we can't format the value
        if (!queryMetaData) {
            return value;
        }

        const formatType = this.getFormatType(attr, cardFieldConfig);

        if (formatType != DwFormatTypes.text && (value)) {
            const formatOptions: DwFormatOptions = {
                formatType: formatType,
                missingDataText: '',
                use24HourTime: this.use24HourTime(),
                useGmtDateTime: this.useGmtDateTime(attr)
            };

            value = this.dwDataFormatService.formatValue(value, formatOptions);
        }

        if (cardFieldConfig.valueFormatter) {
            value = cardFieldConfig.valueFormatter(value);
        }

        if (cardFieldConfig.template) {
            value = cardFieldConfig.template.replace('[data]', value).replace('[label]', attr.AttributeDisplay);
        }

        if (cardFieldConfig.prefix) {
            value = cardFieldConfig.prefix + value;
        }

        if (cardFieldConfig.suffix) {
            value = value + cardFieldConfig.suffix;
        }

        if (cardFieldConfig.showLabel) {
            value = `${attr.AttributeDisplay}: ${value}`;
        }

        return value;
    }

    private useGmtDateTime(attr: DwQueryMdAttribute) {
        return attr?.Attribute?.UseGmtDateTime || this.uiMetaDataConfig.useGmtDateTime;
    }

    private use24HourTime() {
        return this.uiMetaDataConfig.use24HourTime;
    }

    private getFormatType(attr: DwQueryMdAttribute, cardFieldConfig: DwCardFieldConfig): DwFormatTypes {

        if (cardFieldConfig.formatType) {
            return cardFieldConfig.formatType;
        }

        if (attr) {
            switch (attr.AttributeType) {
                case DwAttributeType.Date:
                    return DwFormatTypes.date;
                case DwAttributeType.DateTime:
                    return DwFormatTypes.datetime;
                case DwAttributeType.Money:
                    return DwFormatTypes.currency;
                case DwAttributeType.YesNo:
                    return DwFormatTypes.yesNo;
                case DwAttributeType.Percentage:
                    return DwFormatTypes.percent;
            }
        }

        return DwFormatTypes.text;
    }


}