<div class="progress-list module-progress-header">
    <app-dw-card-designer [data]="data" [cardDesignerConfig]="cardDesignerConfig" [queryMetaData]="queryMetaData"
        (cardClick)="click()" (cardItemResultChange)="setCardItemResult($event)">
        <div cardDesc style2="width:400px;">
            <div *ngIf="data?.PercentComplete<100">
                {{data?.LessonsCompleted}} of {{data?.LessonsCount}} Completed
            </div>
            <div *ngIf="data?.PercentComplete>=100">
                {{cardItemResult?.additionalFields?.completed}}
            </div>
            <div *ngIf="data?.TotalTimeSeconds > 0 && data?.PercentComplete < 100">
                {{data?.PercentComplete}}%
            </div>
        </div>

        <div cardRight class="card-additional-data">
            <div dwRow>
                <div dwCol="12">
                    {{cardItemResult?.additionalFields?.timeSpent}}
                </div>
            </div>
            <div *ngIf="data?.LastActivityDateTime && data?.PercentComplete > 0 && data?.PercentComplete < 100">
                {{cardItemResult?.additionalFields?.lastActivity}}
            </div>
            <div dwRow *ngIf="data?.PercentComplete>=100">
                <div dwCol="12">
                    <br>
                    <i class="fa fa-check-circle dw-green fa-2x" title="Complete"></i>
                </div>
            </div>
            <!-- <div dwRow *ngIf="data?.TotalTimeSeconds > 0 && data?.PercentComplete < 100">
                <div dwCol="12">
                    {{data?.PercentComplete}}% Complete
                </div>
            </div> -->
            <div dwRow *ngIf="data?.TotalTimeSeconds==0">
                <div dwCol="12">
                    Not Started
                </div>
            </div>
        </div>
    </app-dw-card-designer>
</div>