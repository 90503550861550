import { Component, Inject, OnInit } from '@angular/core';
import { DwSectionBaseComponent, DwMetaDataFormApi, DwExpressionService, DwUiConfigRegistryService, DwUIMetaDataConfigToken, DwUIMetaDataConfig, DwMetaDataServiceToken, DwMetaDataService, DwOrmDataServiceToken, DwOrmDataService, DwMetaDataFormStateService, DwEventService, DwGridEvents, DwComponentType, DwComponent, DwSecurityUserService } from '@devwareapps/devware-cap';
import { AssignmentEntity, AppMetaDataItemNames, AssignmentStudentEntity, AppMetaData, StudentLessonProgressQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { CourseProgressVariableContext } from '../../../courses/models/course-progress-variable-context.model';
import { StudentAssignmentProgressEvent, studentAssignmentProgress } from '../../models/student-assignment-progress-event.model';
import { CourseNavigationService } from '../../../courses/services/course-navigation.service';
import { LessonReferenceInfo } from '../../../courses/models/lesson-reference-info.model';


@DwComponent({
  key: 'assignment-student-lesson-progress',
  name: 'Assignment Student Lesson Progress List',
  componentType: DwComponentType.formSection,
  parentItemName: [AppMetaDataItemNames.AssignmentStudent, AppMetaDataItemNames.Assignment],
})
@Component({
  selector: 'app-assignment-student-lesson-progress',
  templateUrl: './assignment-student-lesson-progress.component.html',
  styleUrls: ['./assignment-student-lesson-progress.component.scss']
})
export class AssignmentStudentLessonProgressComponent extends DwSectionBaseComponent {
  assignmentStudent: AssignmentStudentEntity;

  formApi: DwMetaDataFormApi;

  lessonListConfig = {
    itemName: AppMetaDataItemNames.CourseLessonProgress,
    configKeyPart: 'student-lesson-progress',
    filters: {}
  }

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Inject(DwOrmDataServiceToken) private dataService: DwOrmDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private dwEventService: DwEventService,
    private dwUserSecurityService: DwSecurityUserService,
    private courseNavigationService: CourseNavigationService,
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupData();
  }

  cardClick(event: StudentLessonProgressQueryEntity) {
    const lessonReference : LessonReferenceInfo = {
      lessonId: event.CourseLessonId,
      moduleId: event.CourseModuleProgressCourseModuleId,
      courseId: event.StudentCourseCourseId
    }

    this.courseNavigationService.navigateToLesson(lessonReference);
  }

  setupData(): void {
    this.assignmentStudent = this.formApi.getFormData<AssignmentStudentEntity>();
    this.setupListFilter();

    this.formApi.getFormDataChanges<AssignmentStudentEntity>().subscribe((assignment) => {
      this.assignmentStudent = assignment;
      this.setupListFilter();
    });
  }


  setupListFilter(): void {
    let lessonIds = [-1];

    if (this.assignmentStudent?.Assignment?.AssignmentCourseLesson) {
      lessonIds = this.assignmentStudent.Assignment.AssignmentCourseLesson.map(lesson => lesson.CourseLessonId);
    }

    const userId = this.dwUserSecurityService.securityContext.ApplicationUser?.UserId || -1;

    this.lessonListConfig.filters = {};

    this.lessonListConfig.filters[AppMetaData.Queries.StudentLessonProgress.Attributes.CourseLessonId.fieldName] = {
      In: lessonIds
    };

    this.lessonListConfig.filters[AppMetaData.Queries.StudentLessonProgress.Attributes.StudentUserId.fieldName] = userId;
    
  }


}