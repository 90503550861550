import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuizEntity, QuizResultEntity } from '../../../../meta-data/app-meta-data.service';
import { QuizViewAction, QuizViewActionType } from '../../models/quiz-view-action.enum';

@Component({
  selector: 'app-quiz-results',
  templateUrl: './quiz-results.component.html',
  styleUrls: ['./quiz-results.component.scss']
})
export class QuizResultsComponent implements OnInit {
  @Input() quiz: QuizEntity;
  @Input() quizResults: QuizResultEntity[] = [];

  @Input() currentQuizResult: QuizResultEntity;
  @Output() quizResultAction = new EventEmitter<QuizViewAction>();

  constructor() { } 

  ngOnInit(): void {
  }

  viewQuizAnswers(quizResult: QuizResultEntity) {
    const action: QuizViewAction = {
      actionType: QuizViewActionType.viewAnswers,
      quizResult: quizResult
    }

    this.quizResultAction.emit(action);
  }

}
