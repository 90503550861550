import { Injectable } from "@angular/core";
import { DwContextDefinition, DwCustomContextBase, DwCustomContextDefinition, DwOrmDataService, DwQuery, DwSecurityTopics, DwSecurityUserService, DwUserEntity } from "@devwareapps/devware-cap";
import { AppMetaDataItemNames, AppMetaData } from "../../../meta-data/app-meta-data.service";


export const UserRoleDetailsContext = 'userRoleDetailsContext';
@DwCustomContextDefinition({
    keyName: UserRoleDetailsContext,
    display: 'User Role Details Context',
    parentItem: AppMetaDataItemNames.DwUser,
    isGlobal: false,
})
@Injectable()
export class UserRoleDetailsContextService extends DwCustomContextBase {

    constructor(private dwSecurityUserService: DwSecurityUserService,
        ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {
        contextDef.buildQueryFunction = (context) => this.buildQuery(context);

        contextDef.item = AppMetaDataItemNames.RoUserRoleDetail;
        //contextDef.metaDataQueryId = 1019;

        contextDef.noCache = true;
        contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.DwUserRole.ItemDetail.itemName));
        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.DwUser.ItemDetail.itemName));
    }

    buildQuery(resolvedContext: any): DwQuery {
        const query = AppMetaData.RoUserRoleDetail.CreateQueryBuilder();

        // if (!this.dwSecurityUserService.securityContext?.ApplicationUser) {
        //     return null;
        // }
   
        query.query.FieldSettings.LoadAllLookupDisplayFields = false;

        const user: DwUserEntity = resolvedContext?.data;
        
        //const user = this.dwSecurityUserService.securityContext.ApplicationUser;
        
        query.addFilterAnd(filters => filters.Equal(f => f.UserId, user?.UserId || -1));

        return query.query;
    }


}
