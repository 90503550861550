import { Component, OnInit } from '@angular/core';
import { DwControlType } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-annonymous-home-view',
  templateUrl: './annonymous-home-view.component.html',
  styleUrls: ['./annonymous-home-view.component.scss']
})
export class AnnonymousHomeViewComponent implements OnInit {
  textboxControlType: DwControlType = DwControlType.Textbox;
  constructor() { }

  ngOnInit(): void {
  }

}
