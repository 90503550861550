import { Directive, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { VideoEntity, VideoLibraryEntity, VideoStreamingProviderEntity } from "../../../../../meta-data/app-meta-data.service";
import { VideoEvent } from "../../../models/video-event.model";
import { UpdateTimeResult } from "./models/update-time-result.model";
import { VideoEventType } from "../../../models/video-event-type.enum";
import { DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppPermissions } from "../../../../../meta-data/app-permissions.enum";

@Directive({
    selector: 'app-video-player-base'
})
export class VideoPlayerBaseComponent implements OnInit {
    @Input() video: VideoEntity;
    @Input() videoLibrary: VideoLibraryEntity;
    @Input() videoStreamingProvider: VideoStreamingProviderEntity;

    @Output() videoWidthChanged = new EventEmitter<string>();
    furthestWatchedTime: number;
    currentTime: number;
    videoDuration: number;
    userSeeked: boolean = false;

    allowSeek: boolean;
    
    videoEvent = new EventEmitter<VideoEvent>();

    constructor(private dwSecurityUserService: DwSecurityUserService) {
    }

    ngOnInit(): void {
      this.dwSecurityUserService.securityContextChanged().subscribe(() => {
        this.setupVideoSeek();
      });
      this.setupVideoSeek();
    }

    setupVideo(): void {
    
    }

    setupVideoSeek() {
      this.allowSeek = this.videoStreamingProvider?.AllowSeek;

      if (!this.allowSeek) {
        this.allowSeek = this.dwSecurityUserService.hasPermission(AppPermissions.videoCanSeek);
      }
    }

    protected updateCurrentTime(timeSeconds: number): UpdateTimeResult {
        this.currentTime = timeSeconds;

        const result : UpdateTimeResult = {
            userSeeked: false,
        }

        if (timeSeconds > this.furthestWatchedTime) {
            const changeSeconds = timeSeconds - this.furthestWatchedTime;
    
            const belowSeekThreshhold = changeSeconds <= (this.videoStreamingProvider.SeekThreshholdSeconds || 5);
    
            if (belowSeekThreshhold) {
              this.userSeeked = false;
              this.furthestWatchedTime = timeSeconds;

              return result;
            }

            if (this.allowSeek) {
                this.furthestWatchedTime = timeSeconds;

                return result;
            }

            this.userSeeked = true;
            result.userSeeked = true;
            result.returnToPosition = this.furthestWatchedTime;
          }

          return result;
    }


    onVideoEvent(eventType: VideoEventType) {
        const videoEvent: VideoEvent = {
          videoEventType: eventType,
          videoTime: this.currentTime,
          videoDuration: this.videoDuration,
          furtherestVideoTime: this.furthestWatchedTime,
          userSeeked: this.userSeeked
        };
    
        this.videoEvent.emit(videoEvent);
      }

}


