import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DwOrmDataServiceToken, DwOrmDataService, DwMetaDataServiceToken, DwMetaDataService, DwSecurityUserService, DwCacheService, DwEventService, DwConfigService, DwConfigServiceToken } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { QuizEntity, AppMetaDataItemNames, FlightSchoolGroupEntity, FlightSchoolGroupWithStudentCourseDetailQueryEntity, AppMetaData, StudentCourseEntity, PackageEntity, InstructorEntity } from "../../../meta-data/app-meta-data.service";
import { map, mergeMap, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class InstructorRepositoryService {

    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) private configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        private dwEventService: DwEventService,
        private router: Router,
        private http: HttpClient
    ) {

    }

    getCurrentInstructor(): Observable<InstructorEntity> {
        const query = AppMetaData.Instructor.CreateQueryBuilder();

        const userId = this.dwSecurityUserService.securityState.securityContext?.ApplicationUser?.UserId || -1;

        if (!userId) {
            return of(null);
        }

        query.addFilterAnd(f => f.Equal(a => a.UserId, userId));

        return this.dwOrmDataService.executeQuerySingle(query);
    }

}