import { Inject, Injectable } from "@angular/core";
import { AppMetaData, AppMetaDataItemNames, CourseEntity, CourseLessonEntity, CourseLessonProgressEntity, CourseLessonSessionEntity, CourseModuleProgressEntity, LessonListForStudentQueryEntity, LessonTypeAllItems, ReferenceStatusAllItems, StudentCourseEntity, StudentCourseProgressByStudentCourseQueryEntity, StudentCourseProgressQueryEntity } from "../../../meta-data/app-meta-data.service";
import { DevwareMetaData, DwCacheService, DwEventService, DwMediaRepositoryService, DwMetaDataService, DwMetaDataServiceToken, DwModalButtonStandardConfigs, DwModalService, DwOrmData, DwOrmDataService, DwOrmDataServiceToken, DwQueryHelper, DwSecurityTopics, DwSecurityUserService, areEqual, deepCopy } from "@devwareapps/devware-cap";
import { Observable, combineLatest, of } from "rxjs";
import { Router } from "@angular/router";
import { map, mergeMap, tap } from "rxjs/operators";
import { LessonReferenceInfo } from "../models/lesson-reference-info.model";
import { LessonProgressHierarchy } from "../models/lesson-progress-hierarchy.model";
import { CourseProgressItem, LessonProgressItem, ModuleProgressItem } from "../models/course-progress-hierarchy.model";
import { DateTimeUtilService } from "../../shared/util/date-time-util.service";
import { SchoolRepositoryService } from "../../schools/services/school-repository.service";

@Injectable({ providedIn: 'root' })
export class CourseRepositoryService {
    public static readonly COURSE_PROGRESS_UPDATE_EVENT = 'CourseProgressUpdateEvent';

    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        private dwEventService: DwEventService,
        private dateTimeUtilService: DateTimeUtilService,
        private dwMediaRepository: DwMediaRepositoryService,
        private router: Router,
        private schoolRepositoryService: SchoolRepositoryService,
        private dwModalService: DwModalService

    ) {

    }

    getLessonImageUrl(lessonProgress: LessonListForStudentQueryEntity): { mainImageUrl: string, backupImageUrl: string } {
        const imageUrls: string[] = [];
        if (lessonProgress.LessonImageDwroMediaMediaPath) {
            imageUrls.push(`${this.dwMediaRepository.mediaApi}/download/${lessonProgress.LessonImageDwroMediaMediaPath}`);
        } else {
            if(lessonProgress.VideoThumbnailUrl) {
                imageUrls.push(lessonProgress.VideoThumbnailUrl);
            }
        }

        if (lessonProgress.ModuleImageMediaPath) {
            imageUrls.push(`${this.dwMediaRepository.mediaApi}/download/${lessonProgress.ModuleImageMediaPath}`);
        } 
        if (lessonProgress.CourseImageMediaPath) {
            imageUrls.push(`${this.dwMediaRepository.mediaApi}/download/${lessonProgress.CourseImageMediaPath}`);
        }

        imageUrls.push('/assets/aviator-online/img/lesson-image-default.png');

        return { mainImageUrl: imageUrls[0], backupImageUrl: imageUrls[1] };
    }

    getCourseHierarchy(courseId: number): Observable<CourseEntity> {
        const cacheInvalidationTopics: string[] = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Course));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.CourseModule));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.CourseLesson));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Video));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Quiz));

        const courseKey = `Course_${courseId}`;

        return this.dwCacheService.get(courseKey, this.loadCourseHierarchy(courseId), null, cacheInvalidationTopics);
    }

    getCurrentStudentCourses(): Observable<StudentCourseEntity[]> {
        const cacheInvalidationTopics: string[] = [DwSecurityTopics.SECURITY_CHANGED];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.StudentCourse));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.CourseEndorsementStudent));

        const courseKey = `StudentCourses`;

        return this.dwCacheService.get(courseKey, this.loadCurrentStudentCourses(), null, cacheInvalidationTopics);
    }

    getStudentCourse(courseId: number): Observable<StudentCourseEntity> {
        const cacheInvalidationTopics: string[] = [DwSecurityTopics.SECURITY_CHANGED];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.StudentCourse));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.CourseModuleProgress));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.CourseLessonProgress));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.CourseEndorsementStudent));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Video));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Quiz));

        const courseKey = `StudentCourse_${courseId}`;

        return this.dwCacheService.get(courseKey, this.loadStudentCourseWithProgress(courseId), null, cacheInvalidationTopics);
    }


    getStudentCourseProgressQuery(StudentCourseId: number): Observable<StudentCourseProgressQueryEntity> {
        return AppMetaData.Queries.StudentCourseProgress.CreateQueryBuilder(this.dwMetaDataService)
            .pipe(mergeMap(query => {

                const qh = new DwQueryHelper();

                // clone query so we don't modify the original (//TODO: Need to fix this in the framework - thought it was resolved, but it came back here)
                query.query = qh.cloneQuery(query.query);

                query.addFilterAnd(f => f.Equal(a => a.StudentCourseStudentCourseId, StudentCourseId));

                return this.dwOrmDataService.executeQuerySingle(query);
            }));
    }

    getStudentCourseProgressByStudentCourse(StudentCourseId: number): Observable<StudentCourseProgressByStudentCourseQueryEntity> {
        return AppMetaData.Queries.StudentCourseProgressByStudentCourse.CreateQueryBuilder(this.dwMetaDataService)
            .pipe(mergeMap(query => {

                return AppMetaData.Queries.StudentModuleProgress.CreateQueryBuilder(this.dwMetaDataService)
                    .pipe(mergeMap(moduleQuery => {
                        const qh = new DwQueryHelper();

                        const clonedQuery = qh.cloneQuery(query.query);

                        query = AppMetaData.Queries.StudentCourseProgressByStudentCourse.CreateQueryBuilderFromQuery(clonedQuery);

                        //console.log(`StudentgetStudentCourseProgressByStudentCourse  Query Count = ${(query as any).count}`, query);

                        moduleQuery.addOrderBy(o => o.CourseModuleModuleOrder);

                        query.addFilterAnd(f => f.Equal(a => a.StudentCourseId, StudentCourseId));

                        query.addPrefetch(r => r.CourseModuleProgress, moduleQuery.query);

                        return this.dwOrmDataService.executeQuerySingle(query);
                    }));
            }));
    }

    updateStudentCourseCache(studentCourse: StudentCourseEntity, publishEvent: boolean = true) {
        const cacheInvalidationTopics: string[] = [DwSecurityTopics.SECURITY_CHANGED];
        const courseKey = `StudentCourse_${studentCourse.CourseId}`;

        this.dwCacheService.set(courseKey, studentCourse, null, cacheInvalidationTopics);

        if (publishEvent) {
            this.publishCourseProgressUpdate(studentCourse);
        }
    }

    public publishCourseProgressUpdate(studentCourse: StudentCourseEntity) {
        this.dwEventService.publishEvent(CourseRepositoryService.COURSE_PROGRESS_UPDATE_EVENT, studentCourse);
    }

    public getCourseProgressUpdate(): Observable<StudentCourseEntity> {
        return this.dwEventService.getEventData(CourseRepositoryService.COURSE_PROGRESS_UPDATE_EVENT);
    }

    public publishReloadStudentCourse(studentCourse: StudentCourseEntity) {
        this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.StudentCourse), studentCourse);
        this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Course), {});

        this.publishCourseProgressUpdate(studentCourse);
    }

    getCourseProgress(courseId: number): Observable<CourseProgressItem> {
        const obs: Observable<any>[] = [];

        obs.push(this.getCourseHierarchy(courseId));
        obs.push(this.getStudentCourse(courseId));

        return combineLatest(obs)
            .pipe(mergeMap(([course, studentCourse]) => {

                // Ensure the full course hierarchy is created
                return this.ensureCourseProgressHierarchy(studentCourse, course)
                    .pipe(mergeMap(courseUpdated => {
                        if (courseUpdated) {
                            return this.loadStudentCourseWithProgress(courseId)
                                .pipe(map(updatedStudentCourse => {
                                    this.updateStudentCourseCache(updatedStudentCourse, false);

                                    return this.buildCourseProgressItems(course, updatedStudentCourse);
                                }));
                        }

                        return of(this.buildCourseProgressItems(course, studentCourse));
                    }));
            }));
    }

    private buildCourseProgressItems(course: CourseEntity, studentCourse: StudentCourseEntity): CourseProgressItem {
        const courseNavItem: CourseProgressItem = {
            course: course,
            studentCourse: studentCourse,
            modules: []
        };

        for (const module of course.CourseModule) {

            const moduleNav: ModuleProgressItem = {
                module: module,
                moduleProgress: studentCourse.CourseModuleProgress.find(m => m.CourseModuleId == module.CourseModuleId),
                lessons: []
            };

            let previousLessonComplete = false;
            for (const lesson of module.CourseLesson) {
                const lessonProgress = moduleNav.moduleProgress?.CourseLessonProgress.find(m => m.CourseLessonId == lesson.CourseLessonId);
                const lessonNav: LessonProgressItem = {
                    lesson: lesson,
                    lessonProgress: lessonProgress,
                    percentComplete: lessonProgress?.PercentComplete || 0,
                    isComplete: lessonProgress?.LessonComplete || false,
                    lessonIconClass: this.determineLessonIcon(lesson, lessonProgress)
                };

                // Set the furtherest lesson
                if (previousLessonComplete) {
                    courseNavItem.furtherestLesson = lesson;
                } else {
                    if (lessonNav.percentComplete > 0) {
                        courseNavItem.furtherestLesson = lesson;
                    }
                }

                previousLessonComplete = lessonNav.isComplete;

                moduleNav.lessons.push(lessonNav);
            }

            courseNavItem.modules.push(moduleNav);
        }

        if (!courseNavItem.furtherestLesson) {
            courseNavItem.furtherestLesson = course.CourseModule[0]?.CourseLesson[0];
        }

        return courseNavItem
    }

    determineLessonIcon(lesson: CourseLessonEntity, lessonProgress: CourseLessonProgressEntity): string {
        let icon: string = 'fa fa-info-circle';

        switch (lesson.LessonTypeId) {
            case LessonTypeAllItems.Video:
                icon = 'fa fa-play-circle';
                break;
            case LessonTypeAllItems.Quiz:
                icon = 'fa fa-list-alt';
                break;
        }

        if (lessonProgress?.LessonComplete) {
            icon = 'fa fa-check-circle dw-green';
        } else {
            if (lessonProgress?.PercentComplete > 0) {
                icon += ' dw-orange';
            }
            else {
                icon += ' normal-icon';
            }
        }

        return icon;
    }

    getLessonProgress(lessonReference: LessonReferenceInfo): Observable<LessonProgressHierarchy> {

        return this.getCourseProgress(lessonReference.courseId)
            .pipe(map(courseProgress => {
                if (!courseProgress) {
                    return null;
                }

                const moduleProgress = courseProgress.modules?.find(m => m.module.CourseModuleId == lessonReference.moduleId);

                const lessonProgress: LessonProgressHierarchy = {
                    course: courseProgress,
                    module: moduleProgress,
                    lesson: moduleProgress?.lessons.find(l => l.lesson.CourseLessonId == lessonReference.lessonId)
                };

                return lessonProgress;
            }));
    }

    getLessonDetails(lessonReference: LessonReferenceInfo): Observable<CourseLessonEntity> {
        if (!lessonReference?.courseId)
            return of(null);

        return this.getCourseHierarchy(lessonReference.courseId)
            .pipe(map(course => {
                if (!lessonReference.moduleId) {
                    return null;
                }

                const module = course.CourseModule.find(m => m.CourseModuleId == lessonReference.moduleId);

                if (!module) {
                    return null;
                }

                if (!lessonReference.lessonId) {
                    return null;
                }

                return module.CourseLesson.find(l => l.CourseLessonId == lessonReference.lessonId);
            }));
    }

    private loadCourseHierarchy(courseId: number): Observable<CourseEntity> {
        const query = AppMetaData.Course.CreateQueryBuilder();

        query.setFieldSettings(s => s.LoadAllLookupDisplayFields = true);
        query.addFilterAnd(f => f.Equal(a => a.CourseId, courseId));

        const moduleQuery = AppMetaData.CourseModule.CreateQueryBuilder();

        moduleQuery.addFilterAnd(f => f.Equal(a => a.ModuleStatusId, ReferenceStatusAllItems.Active));

        query.addPrefetch(r => r.CourseModule, moduleQuery.query);

        const lessonQuery = AppMetaData.CourseLesson.CreateQueryBuilder();

        const videoQuery = AppMetaData.Video.CreateQueryBuilder();

        videoQuery.addPrefetch(r => r.VideoLibrary);
        videoQuery.addPrefetch(r => r.VideoStreamingProvider);

        lessonQuery.addPrefetch(r => r.Video, videoQuery.query);
        lessonQuery.addPrefetch(r => r.Quiz);
        lessonQuery.addPrefetch(r => r.DwRoMediaByLessonImageMediaId)

        lessonQuery.addFilterAnd(f => f.Equal(a => a.LessonStatusId, ReferenceStatusAllItems.Active));

        moduleQuery.addPrefetch(r => r.CourseLesson, lessonQuery.query);

        moduleQuery.addOrderBy(o => o.ModuleOrder);
        lessonQuery.addOrderBy(o => o.LessonOrder);

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    private loadCurrentStudentCourses(): Observable<StudentCourseEntity[]> {
        const query = AppMetaData.StudentCourse.CreateQueryBuilder();

        // Only logged in users have course progress
        const userId = this.dwSecurityUserService.securityContext?.ApplicationUser?.UserId;

        if (!userId) {
            return of(null);
        }

        query.addRelation(r => r.Student);

        query.addFilterAnd(f => f.Equal(a => DevwareMetaData.DwUser.UserId, userId));
        query.addFilterAnd(f => f.Equal(a => a.StudentCourseStatusId, ReferenceStatusAllItems.Active));

        query.addPrefetch(r => r.CourseEndorsementStudent);

        query.addOrderByDesc(o => o.LastActivityDateTime);

        return this.dwOrmDataService.executeQuery(query);
    }

    private loadStudentCourseWithProgress(courseId: number): Observable<StudentCourseEntity> {
        const query = AppMetaData.StudentCourse.CreateQueryBuilder();

        // Only logged in users have course progress
        const userId = this.dwSecurityUserService.securityContext?.ApplicationUser?.UserId;

        if (!userId) {
            return of(null);
        }

        query.addRelation(r => r.Student);

        query.addFilterAnd(f => f.Equal(a => DevwareMetaData.DwUser.UserId, userId));
        query.addFilterAnd(f => f.Equal(a => a.CourseId, courseId));

        query.setFieldSettings(s => s.LoadAllLookupDisplayFields = true);

        // Add hierarchy of progress and session
        const moduleProgressQuery = AppMetaData.CourseModuleProgress.CreateQueryBuilder();
        const lessonProgressQuery = AppMetaData.CourseLessonProgress.CreateQueryBuilder();
        //const lessonSessionQuery = AppMetaData.CourseLessionSession.CreateQueryBuilder();

        query.addPrefetch(r => r.CourseEndorsementStudent);
        query.addPrefetch(r => r.CourseModuleProgress, moduleProgressQuery.query);

        moduleProgressQuery.addPrefetch(r => r.CourseLessonProgress, lessonProgressQuery.query);
        moduleProgressQuery.addFilterAnd(r => r.Equal(a => a.ModuleProgressStatusId, ReferenceStatusAllItems.Active));
        lessonProgressQuery.addFilterAnd(r => r.Equal(a => a.LessonProgressStatusId, ReferenceStatusAllItems.Active));

        return this.dwOrmDataService.executeQuerySingle(query)
            .pipe(map(studentCourse => {
                return studentCourse;
            }));
    }

    ensureCourseProgressHierarchy(studentCourse: StudentCourseEntity, course: CourseEntity): Observable<boolean> {

        let requiresFullUpdate = false;
        let hasChanges = false;

        if (studentCourse.ModulesCount != course.CourseModule.length) {
            requiresFullUpdate = true;
        }

        for (const module of course.CourseModule) {
            let moduleProgress = studentCourse.CourseModuleProgress.find(m => m.CourseModuleId == module.CourseModuleId);

            if (!moduleProgress) {
                requiresFullUpdate = true;
                break;

                // hasChanges = true;
                // moduleProgress = {
                //     _itemName: AppMetaDataItemNames.CourseModuleProgress,
                //     CourseModuleId: module.CourseModuleId,
                //     PercentComplete: 0,
                //     LessonsCompleted: 0,
                //     LessonsCount: module.CourseLesson.length,
                //     StudentCourseId: studentCourse.StudentCourseId,
                //     CourseLessonProgress: []
                // };
                // studentCourse.CourseModuleProgress.push(moduleProgress);
            }

            if (moduleProgress.LessonsCount != module.CourseLesson.length) {
                requiresFullUpdate = true;
                break;
            }

            for (const lesson of module.CourseLesson) {
                let lessonProgress = moduleProgress.CourseLessonProgress.find(l => l.CourseLessonId == lesson.CourseLessonId);

                if (!lessonProgress) {
                    requiresFullUpdate = true;
                    break;
                    // hasChanges = true;

                    // lessonProgress = {
                    //     _itemName: AppMetaDataItemNames.CourseLessonProgress,
                    //     CourseLessonId: lesson.CourseLessonId,
                    //     LessonComplete: false,
                    //     PercentComplete: 0,
                    //     VideoFurthestTimeSeconds: 0,
                    //     CourseModuleProgressId: moduleProgress.CourseModuleProgressId, // populate FK if it exists
                    // };
                    // moduleProgress.CourseLessonProgress.push(lessonProgress);
                }
            }
        }

        if (requiresFullUpdate) {
            
            return this.schoolRepositoryService.updateCurrentStudentCourseProgress(studentCourse.CourseId)
                .pipe(map(savedStudentCourse => {
                    return true;
                    // // Update the cache with the saved student course
                    // this.updateStudentCourse(savedStudentCourse);
                }))
        }

        return of(false);
    }




    /**
     * 
     * @param progress 
     * @param lessonProgress 
     * @returns 
     * @remarks 
     *   // update module progress (if lesson progress has changed)
     *   // Update student course
     *   // Save changes
     *   // update cache
     *   // publish refresh event
     *   // return updated progress object
     */
    updateLessonProgress(progress: LessonProgressHierarchy, lessonProgress: Partial<CourseLessonProgressEntity>, sessionTimeToAddSeconds: number): Observable<LessonProgressHierarchy> {
        this.ensureProgressHierarchy(progress, progress.currentLessonSession.SessionStartDateTime);

        // set the lesson progress
        const lessonProgressToSave: CourseLessonProgressEntity = { ...progress.lesson.lessonProgress, ...lessonProgress };

        // Check if there are any changes
        const courseModuleProgress = this.updataCourseModuleProgress(lessonProgressToSave, progress, sessionTimeToAddSeconds);

        if (progress.currentLessonSession) {
            // Save the lesson session
            const currentSession: CourseLessonSessionEntity = {
                _itemName: AppMetaDataItemNames.CourseLessonSession,
                CourseLessonProgressId: lessonProgressToSave.CourseLessonProgressId,
                ...progress.currentLessonSession
            };

            lessonProgressToSave.CourseLessonSession = [currentSession];
        }

        return this.dwOrmDataService.saveEntity(courseModuleProgress, true)
            .pipe(map(savedCourseModuleProgress => {

                if (!progress.course.studentCourse.IsEndorsed) {
                    this.checkCourseEndoresment(progress.course.studentCourse.StudentCourseId);
                }

                return this.finalizeLessonProgressUpdate(progress, savedCourseModuleProgress);
            }));
    }

    private checkCourseEndoresment(studentCourseId: number) {
        const studentCourse = AppMetaData.StudentCourse.CreateQueryBuilder();

        studentCourse.addFilterAnd(f => f.Equal(a => a.StudentCourseId, studentCourseId));

        studentCourse.query.FieldSettings.LoadAllLookupDisplayFields = true;
        studentCourse.addField(f => f.IsEndorsed);
        studentCourse.addField(f => f.CourseId);

        this.dwOrmDataService.executeQuerySingle(studentCourse)
            .subscribe(studentCourse => {
                if (studentCourse.IsEndorsed) {

                    this.publishReloadStudentCourse(studentCourse);

                    setTimeout(() => {
                       // const url = `/my-courses/view/${studentCourse.CourseId}`;
                       const url = `/my-courses?tile=endorsed`;
    
                        this.router.navigateByUrl(url);
                    }, 500);

                    this.dwModalService.showDialog('Congratulations!', 'You have completed the course and are now endorsed!  <br><br> Click the download endorsement button below to download your certificate!', [DwModalButtonStandardConfigs.instance.okButton])
                        .subscribe(() => {
                       
                        });
                }
            });
    }

    private finalizeLessonProgressUpdate(progress: LessonProgressHierarchy, updatedCourseModuleProgress: CourseModuleProgressEntity): LessonProgressHierarchy {
        // Save module will always save the course lessage progress as well since that is what triggered the save
        const updatedCourseProgress = updatedCourseModuleProgress.CourseLessonProgress?.[0];


        const previousLessonProgress = progress.module.moduleProgress?.CourseLessonProgress?.filter(p => p.CourseModuleProgressId != updatedCourseProgress.CourseLessonProgressId) || [];

        // Merge new lesson progress with existing lesson progress
        updatedCourseModuleProgress.CourseLessonProgress = [updatedCourseProgress, ...previousLessonProgress]


        progress.currentLessonSession = updatedCourseProgress.CourseLessonSession?.[0];

        updatedCourseProgress.CourseLessonSession = null;

        progress.lesson.lessonProgress = updatedCourseProgress;
        progress.module.moduleProgress = updatedCourseModuleProgress;

        // Check if the student course needs to be updated
        if (updatedCourseModuleProgress.StudentCourse) {
            const updatedStudentCourse = updatedCourseModuleProgress.StudentCourse;

            const previousStudentCourseModuleProgress = progress.course.studentCourse.CourseModuleProgress?.filter(p => p.CourseModuleProgressId != updatedCourseModuleProgress.CourseModuleProgressId) || [];

            updatedStudentCourse.CourseModuleProgress = [updatedCourseModuleProgress, ...previousStudentCourseModuleProgress];

            progress.course.studentCourse = updatedStudentCourse;
        }

        // Need to update the project cache
        this.updateStudentCourseCache(progress.course.studentCourse);

        return progress;
    }

    /**
     * Updates the module and course completed info
     * @param lessonProgress 
     * @param progress 
     * @returns Returns either the lesson or module to be saved
     */
    private updataCourseModuleProgress(lessonProgress: CourseLessonProgressEntity, progress: LessonProgressHierarchy, sessionTimeToAddSeconds: number): CourseModuleProgressEntity {
        const moduleProgress = { ...progress.module.moduleProgress };

        moduleProgress.LessonsCompleted = progress.module.lessons.filter(l => l.lessonProgress?.LessonComplete && l.lesson.CourseLessonId != lessonProgress.CourseLessonId).length;

        var currentSession = progress.currentLessonSession;

        if (!lessonProgress.FirstActivityDateTime) {
            lessonProgress.FirstActivityDateTime = currentSession.SessionStartDateTime;
        }

        lessonProgress.LastActivityDateTime = currentSession.SessionEndDateTime;

        // Increase the lesson count if the lesson is complete
        if (lessonProgress.LessonComplete) {
            lessonProgress.PercentComplete = 100;
            if (!lessonProgress.CompletedDateTime) {
                lessonProgress.CompletedDateTime = lessonProgress.LastActivityDateTime;
            }

            moduleProgress.LessonsCompleted++;
        }

        lessonProgress.TotalTimeSeconds = (lessonProgress.TotalTimeSeconds || 0) + sessionTimeToAddSeconds;  // Add newest session time

        moduleProgress.LessonsCount = progress.module.lessons.length;
        moduleProgress.PercentComplete = moduleProgress.LessonsCount > 0 ? (moduleProgress.LessonsCompleted / moduleProgress.LessonsCount) * 100 : 0;
        moduleProgress.LastActivityDateTime = lessonProgress.LastActivityDateTime;
        moduleProgress.TotalTimeSeconds = this.sum(moduleProgress.CourseLessonProgress
            .filter(c => c.CourseLessonProgressId != lessonProgress.CourseLessonProgressId), l => l.TotalTimeSeconds) // Remove current lesson
            + lessonProgress.TotalTimeSeconds;   // Add newest lesson time

        if (!moduleProgress.FirstActivityDateTime) {
            moduleProgress.FirstActivityDateTime = lessonProgress.FirstActivityDateTime;
        }

        if (moduleProgress.PercentComplete == 100 && !moduleProgress.CompletedDateTime) {
            moduleProgress.CompletedDateTime = lessonProgress.CompletedDateTime;
        }

        // Add the module progress to the lesson progress
        moduleProgress.CourseLessonProgress = [lessonProgress];

        const studentCourse = { ...progress.course.studentCourse, CourseModuleProgress: [] };


        let modulesCompleted = progress.course.modules.filter(m => m.moduleProgress?.PercentComplete == 100 && m.module.CourseModuleId != moduleProgress.CourseModuleId).length;

        if (moduleProgress.PercentComplete == 100) {
            modulesCompleted++;
        }

        studentCourse.ModulesCompleted = modulesCompleted;

        studentCourse.ModulesCount = progress.course.modules.length;
        let otherLessonsCompleted = this.sum(progress.course.modules.filter(m => m.module.CourseModuleId != moduleProgress.CourseModuleId), m => m.moduleProgress?.LessonsCompleted);

        studentCourse.LessonsCompleted = otherLessonsCompleted + moduleProgress.LessonsCompleted;

        studentCourse.LessonCount = this.sum(progress.course.modules, m => m.lessons.length);
        studentCourse.PercentComplete = studentCourse.LessonCount > 0 ? (studentCourse.LessonsCompleted / studentCourse.LessonCount) * 100 : 0;
        studentCourse.LastActivityDateTime = lessonProgress.LastActivityDateTime;

        if (!studentCourse.FirstActivityDateTime) {
            studentCourse.FirstActivityDateTime = moduleProgress.FirstActivityDateTime;
        }
        if (studentCourse.PercentComplete == 100 && !studentCourse.CompletedDateTime) {
            studentCourse.CompletedDateTime = moduleProgress.CompletedDateTime || lessonProgress.CompletedDateTime;
        }

        studentCourse.TotalTimeSeconds = this.sum(progress.course.modules.filter(m => m.moduleProgress.CourseModuleId != moduleProgress.CourseModuleId)
            , m => m.moduleProgress.TotalTimeSeconds) + moduleProgress.TotalTimeSeconds;

        // Add the student course to save
        moduleProgress.StudentCourse = studentCourse;

        // We just need to save module Progress
        return moduleProgress;
    }

    private sum<T>(items: T[], selector: (item: T) => number): number {
        return items.reduce((p, c) => p + selector(c) || 0, 0);
    }

    /**
     * Ensure the module progress and lesson progress entities are created
     * @param progress 
     */
    private ensureProgressHierarchy(progress: LessonProgressHierarchy, startDateTime: any) {
        if (!progress.module.moduleProgress) {
            // Create module progress
            progress.module.moduleProgress = {
                _itemName: AppMetaDataItemNames.CourseModuleProgress,
                CourseModuleId: progress.module.module.CourseModuleId,
                PercentComplete: 0,
                LessonsCompleted: 0,
                LessonsCount: progress.module.lessons.length,
                StudentCourseId: progress.course.studentCourse.StudentCourseId,
                FirstActivityDateTime: startDateTime
            };
        }

        if (!progress.lesson.lessonProgress) {

            // Create lesson progress
            progress.lesson.lessonProgress = {
                _itemName: AppMetaDataItemNames.CourseLessonProgress,
                CourseLessonId: progress.lesson.lesson.CourseLessonId,
                LessonComplete: false,
                PercentComplete: 0,
                VideoFurthestTimeSeconds: 0,
                CourseModuleProgressId: progress.module.moduleProgress.CourseModuleProgressId, // populate FK if it exists
                FirstActivityDateTime: startDateTime
            };
        }


    }

}