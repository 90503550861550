import { Injectable } from "@angular/core";
import { DwModalButtonStandardConfigs, DwModalConfig, DwModalResult, DwModalService } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { VideoEntity } from "../../../meta-data/app-meta-data.service";
import { VideoUploadModalComponent } from "../components/video-upload-modal/video-upload-modal.component";

@Injectable({ providedIn: 'root' })
export class VideoModalService {
  
    constructor(
    private dwModalService: DwModalService
) {
    
}

public showVideoUploadModal(title:string, video?: VideoEntity): Observable<DwModalResult> {
    let modalConfig = new DwModalConfig();

    modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;
    modalConfig.allowFullScreen = true;
    modalConfig.preventClose = true;

    modalConfig.buttons[0].buttonText = 'Upload';
    modalConfig.buttons[0].buttonClass = 'btn btn-warning';
    modalConfig.buttons[0].iconClass = 'fa fa-video';

    modalConfig.component = VideoUploadModalComponent;

    modalConfig.data = video;

    modalConfig.title = title || 'Upload Video';

    return this.dwModalService.showModal(modalConfig);
}

}