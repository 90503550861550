import { Component, Inject, OnInit } from '@angular/core';
import { AppMetaDataItemNames, StudentCourseEntity } from '../../../../meta-data/app-meta-data.service';
import {
  DwComponent, DwComponentType, DwExpressionService, DwFormContextMode, DwMdFormContextInfo, DwMetaDataFormApi, DwMetaDataFormStateService,
  DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken,
  DwLookupSearchType, DwOrmDataService, DwOrmDataServiceToken, DwQueryHelper, DwQueryFilter, DwOperator, DwFilterType,
  deepCopy,
  DwEventService,
  DwGridEvents
}
  from '@devwareapps/devware-cap';

@DwComponent({
  key: 'endorsement-student-edit',
  name: 'Endorsement Student Edit',
  isGlobal: false,
  parentItemName: [AppMetaDataItemNames.StudentCourse, AppMetaDataItemNames.CourseEndorsementStudent],
  componentType: DwComponentType.formSection
})
@Component({
  selector: 'app-endorsement-student-edit',
  template: `
    <dw-meta-data-form #printMdForm [item]="item" [keyValue]="keyValue"
            [data]="data" [formMode]="editMode"
            [configKeyPart]="'endorsement-edit'" *ngIf="keyValue">
      </dw-meta-data-form>
  `
})
export class EndorsementStudentEditComponent extends DwSectionBaseComponent {
  item = AppMetaDataItemNames.CourseEndorsementStudent;
  keyValue: number;
  formMode: DwFormContextMode = DwFormContextMode.addEdit;
  formApi: DwMetaDataFormApi;
  studentCourse: StudentCourseEntity;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Inject(DwOrmDataServiceToken) private dataService: DwOrmDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private dwEventService: DwEventService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  }

  setupComponentComplete(): void {
    this.formApi.getFormDataChanges(100).subscribe(() => {
      this.setupData();
    });

    this.setupData();

  }

  setupData() {
    this.studentCourse = this.formApi.getFormData<StudentCourseEntity>();

    this.keyValue = this.studentCourse.CourseEndorsementStudent?.CourseEndorsementStudentId
  }
}
