import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtilService } from '../util/date-time-util.service';

@Pipe({
  name: 'timeDuration'
}) 
export class TimeDurationPipe implements PipeTransform {

    constructor(private dateTimeUtilService: DateTimeUtilService) {
        
    }

  transform(durationInSeconds: number) {
    return this.dateTimeUtilService.formatDurationMinutesSeconds(durationInSeconds);
  }
}