import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DwMediaRepositoryService } from '@devwareapps/devware-cap';
import { CourseLessonEntity, QuizEntity, QuizResultEntity, QuizResultStatusAllItems } from '../../../../../meta-data/app-meta-data.service';
import { DateTimeUtilService } from 'src/app/features/shared/util/date-time-util.service';

@Component({
  selector: 'app-quiz-card',
  templateUrl: './quiz-card.component.html',
  styleUrls: ['./quiz-card.component.scss']
})
export class QuizCardComponent implements OnInit {

  cardConfig?: any;
  @Input() data?: CourseLessonEntity;
  @Input() quiz?: QuizEntity;
  @Input() quizResults: QuizResultEntity[] = [];
  resolvedContext?: any;
  cardClick: EventEmitter<any> = new EventEmitter<any>();

  imageUrl: string = '';
  defaultImageUrl: string = '/assets/aviator-online/img/quiz-image-default.png';
  progressPercent = 0;
  timeLimit: string;
  passFailPercentText: string;
  constructor(private dwMediaRepository: DwMediaRepositoryService, private dateTimeUtilService: DateTimeUtilService) { }

  ngOnInit(): void {
    this.setupCard();
  }

  setupCard() {
    this.quiz = this.data.Quiz;

    this.timeLimit = this.dateTimeUtilService.formatDuration2(this.quiz.TimeLimitMinutes * 60);

    if (this.data?.LessonImageDwroMedia?.MediaPath) {
      this.imageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.data.LessonImageDwroMedia.MediaPath}`;
    }

    if (this.quizResults?.length > 0) {
      const quizPasses = this.quizResults.filter(qr => qr.QuizResultStatusId == QuizResultStatusAllItems.Passed).length;
      const quizAttempts = this.quizResults.length;

      const passFailPercent = (quizPasses * 100) / quizAttempts;
      this.passFailPercentText = `${passFailPercent}%  (${quizPasses}/${quizAttempts})`;
    }
  }

  click() {

  }

}
