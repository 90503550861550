import { Inject, Injectable } from "@angular/core";
import { DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";

@DwDatasource({
    keyName: 'AssignmentStudentCurrentDataSource',
    display: 'Assignment Student Current List Data Source',
    itemName: AppMetaDataItemNames.AssignmentCourseLesson,
})
@Injectable()
export class AssignmentStudentCurrentDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.Course);
    }

    initDataSource() {

    }

    prepareRequestQuery(request: DwDataRequest): DwQuery {
        const baseQuery = super.prepareRequestQuery(request);

        const courseListQuery = AppMetaData.Queries.AssignmentLessonWithProgressForStudent.CreateQueryBuilderFromQuery(baseQuery);

        const userId = this.securityUserService.securityState.securityContext?.ApplicationUser?.UserId;

        courseListQuery.addFilterAnd(f => f.Equal(a => a.StudentUserId, userId));
        courseListQuery.addFilterAnd(f =>
            f.GroupOr(f =>
                f.IsBlank(a => a.StudentFromProgressUserId, null),
                f => f.Equal(a => a.StudentFromProgressUserId, userId)
            )
        );

        return courseListQuery.query;
    }

}