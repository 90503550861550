import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DwCardBaseConfig } from '../../models/dw-card-base-config.model';
import { DwQueryMetaData, isFirstChange } from '@devwareapps/devware-cap';
import { DwCardUtilService } from '../../services/dw-card-util.service';
import { DwCardItemResult } from '../../models/dw-card-item-result.model';

@Component({
  selector: 'app-dw-card-designer',
  templateUrl: './dw-card-designer.component.html',
  styleUrls: ['./dw-card-designer.component.scss']
})
export class DwCardDesignerComponent implements OnInit, OnChanges{
  @Input() cardDesignerConfig?: DwCardBaseConfig;
  @Input() data?: any;
  @Input() additionalClasses?: string;

  @Input() queryMetaData: DwQueryMetaData;

  cardItemResult: DwCardItemResult;
  defaultImageUrl: string = '/assets/aviator-online/img/module-image-default.png';

  @Output() cardClick = new EventEmitter<any>();
  @Output() cardItemResultChange = new EventEmitter<DwCardItemResult>();

  constructor(private dwCardUtilService: DwCardUtilService) { }
   

  ngOnInit(): void {
    this.setupCard();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isFirstChange(changes)) {
      this.setupCard();
    }

    //this.cardDesignerConfig.image
  }

  resolvedContext?: any;
  
  setupCard() {
    if (!this.cardDesignerConfig || !this.data || !this.queryMetaData) {
      return;
    }

    if (this.cardDesignerConfig.image) {
      this.cardDesignerConfig.image.maxWidth = this.cardDesignerConfig.image.maxWidth || '250px';
      this.cardDesignerConfig.image.maxHeight = this.cardDesignerConfig.image.maxWidth || '141px';
      
      this.cardDesignerConfig.image.mobileMaxWidth = this.cardDesignerConfig.image.mobileMaxWidth || '120px';
      this.cardDesignerConfig.image.mobileMaxHeight = this.cardDesignerConfig.image.mobileMaxHeight || '70px';
    }

    this.dwCardUtilService.processCardData(this.queryMetaData, this.cardDesignerConfig, this.data)
      .subscribe(result => {
        this.cardItemResult = result;

        this.cardItemResultChange.emit(this.cardItemResult);
      })

  }

  click() {
    this.cardClick.emit();
  }
}
