import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DwBreadCrumbCustomizationFunc, DwBreadCrumbCustomizationProcessor, DwBreadcrumbMenu, DwLookupInfo, DwMenu, DwMenuData, DwMetaDataService, DwMetaDataServiceToken, DwSecurityStateService, DwStorageUtil } from '@devwareapps/devware-cap';
import { AppMetaDataLookups } from '../../../meta-data/app-meta-data.service';
import { Observable, combineLatest } from 'rxjs';

@Injectable()
export class UserBreadcrumbProcessor implements DwBreadCrumbCustomizationProcessor {
    userLookupItems: DwLookupInfo[] = [];
    
    constructor(private dwSecurityState: DwSecurityStateService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        private dwStorageUtil: DwStorageUtil
    ) {
        this.dwStorageUtil.useLocalStorage = true;
        this.userLookupItems = this.dwStorageUtil.getValue<DwLookupInfo[]>('userLookupItems') || [];
        this.dwStorageUtil.useLocalStorage = false;

        const obs : Observable<DwLookupInfo[]>[] = [];

        obs.push(this.dwMetaDataService.getLookup(AppMetaDataLookups.UserAllItems));
        
        combineLatest(obs)
        .subscribe(
            ([userLookupItems]) => {
                this.userLookupItems = userLookupItems;
              
                this.dwStorageUtil.useLocalStorage = true;
                this.dwStorageUtil.setValue('userLookupItems', this.userLookupItems);
                this.dwStorageUtil.useLocalStorage = false;
            });
    }

    processBreadcrumb(breadcrumbMenu: DwBreadcrumbMenu, routeSnapshot: ActivatedRouteSnapshot, url: string, menu: DwMenu, menuData: DwMenuData) {
       // console.log('User BreadCrumb Processor', url);

        if (url?.indexOf('/manage-users/') == -1 && url?.indexOf('/manage-students/') == -1) {
            return null;
        }

        const parts = url.replace('//', '/').split('/');

        const userId = parseInt(parts[3]);

        if (!userId || parts.length != 4) {
            return;
        }

        this.updateBreadcrump(this.userLookupItems, userId, breadcrumbMenu);
    }

    private updateBreadcrump(items: DwLookupInfo[], id: number, breadcrumbMenu: DwBreadcrumbMenu) {
        const item = items.find(c => parseInt(c.Id) == id);

        if (item) {
            breadcrumbMenu.breadcrumbDisplay = item.Display;
        }
    }
        



    //     if (url?.indexOf('//courses/view/')==0) {

    //         const parts = url.replace('//','/').split('/');

    //         const courseId = parseInt(parts[3]);

    //         if (parts.length == 4) {
    //             const course = this.coursesLookupItems.find(c => parseInt(c.Id) == courseId);

    //             if (course) {
    //                 breadcrumbMenu.breadcrumbDisplay = course.Display;
    //             }

    //             return;
    //         }

    //         if (parts.length == 6) {
    //             const moduleId = parseInt(parts[5]);
    //             const module = this.moduleLookupItems.find(c => parseInt(c.Id) == moduleId);

    //             if (module) {
    //                 breadcrumbMenu.breadcrumbDisplay = module.Display;
    //             }

    //             return;
    //         }

    //         if (parts.length == 8) {
    //             const lessonId = parseInt(parts[7]);
    //             const lesson = this.lessonLookupItems.find(c => parseInt(c.Id) == lessonId);

    //             if (lesson) {
    //                 breadcrumbMenu.breadcrumbDisplay = lesson.Display;
    //             }
    //         }



    //     }
    // }
}

DwBreadCrumbCustomizationFunc({
    keyName: 'ManagerUsersBreadcrumbProcessor',
    display: 'Manager Users Breadcrumb Processor',
    order: 1
}, UserBreadcrumbProcessor)