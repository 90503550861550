<div class="quiz-result" [ngClass]="quizDisplayResult.containerClass" *ngIf="quizDisplayResult">
    <div class="quiz-result-header">
        {{quizDisplayResult?.headerText}}
    </div>

    <div class="quiz-result-icon">
        <i [ngClass]="quizDisplayResult.headerIconClass"></i>
    </div>
    <div class="quiz-result-detail">
        <div>
            <div *ngFor="let detailCard of quizDisplayResult.detailCards" class="detail-card" [ngClass]="detailCard.containerClass">
                <div class="detail-card-header">
                    {{detailCard.headerText}}
                </div>
                <div class="detail-card-body">
                    <span class="detail-card-icon">
                        <i [ngClass]="detailCard.iconClass"></i>
                    </span>
                    <span class="detail-card-text">
                        {{detailCard.detail}}
                    </span>
                </div>
                <div class="detail-card-notes">
                    {{detailCard.notes}}
                </div>
            </div>
        </div>
    </div>
    <div class="quiz-result-actions">
        <div>
            <button class="btn btn-primary" (click)="takeAction('retake')" *ngIf="showRetake">Retake Quiz</button>

            <button class="btn btn-primary" (click)="takeAction('viewAnswers')">View Answers</button>

            <button class="btn btn-primary" *ngIf="moreThanOneResult" (click)="takeAction('viewAllResult')">All Results</button>
        </div>
    </div>
</div>