import { Injectable } from "@angular/core";
import { ValidatorFn, AbstractControl, FormGroup } from "@angular/forms";
import { DwValidationError, DwValidationErrorResult, DwValidationItemType, DwValidationRule, DwValidationRuleBase } from "@devwareapps/devware-cap";
import * as moment from "moment";
import { AssignmentEntity } from "../../../meta-data/app-meta-data.service";


const errorProperty = 'studentCountError';
@DwValidationRule({
    keyName: 'assignment_student_count_validator',
    display: 'Assignment Student Count Validator',
    errorType: errorProperty,
    validationItemType: DwValidationItemType.formGroup,
    messageTemplate: 'At least one student must be selected',
})
@Injectable()
export class DwAssignmentStudentCountValidationRule extends DwValidationRuleBase {
    createValidator(config?: any) {
        return ValidateLessonCount(config);
    } 

    getMessageTemplate(error: DwValidationError<any>, displayName: string, isSubmitted: boolean, messageTemplate?: string): string {
        return messageTemplate;
    }
}

export function ValidateLessonCount(config: any): ValidatorFn {
    return (control: AbstractControl) => {
        const formGroup = control as FormGroup;
        const assignment : AssignmentEntity = formGroup?.getRawValue();
        

        if (assignment?.UseGroupAssignment) {
            return null;
        }

        let studentCount = 0;

        if (assignment?.AssignmentStudent) {
            studentCount = assignment.AssignmentStudent.filter(lesson => !lesson._isDeleted).length;
        }

        if (studentCount == 0) {
            const result: DwValidationErrorResult<any> = {};
            result[errorProperty] = {
                value: true
            };

            return result;
        } 

        return null;
    }
}