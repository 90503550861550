<div class="card card-container card-item hand" (click)="click()">
    <div class="card-image">
        <!-- <img [src]="courseImageUrl" class="card-img-top" style="width:100%"> -->
        <div class="card-icon">
            <i class="fa fa-chalkboard-teacher fa-2x"></i>
        </div>
    </div>
    <div class="card-body">
        <h5 class="card-title">{{data.CourseLessonLessonName}}</h5>

        <div>Due: {{dueDateTime}}</div>
    </div>
    <div class="card-right" >
        <ng-container *ngIf="data?.CourseLessonLessonTypeId != quizLessonTypeId">
            <span *ngIf="data?.CourseLessonProgressLessonComplete">
                <i class="fa fa-check-circle dw-green"></i>
            </span>
            <span *ngIf="!data?.CourseLessonProgressLessonComplete">
                {{ data?.CourseLessonProgressPercentComplete }}%
            </span>
        </ng-container>
        <ng-container *ngIf="data?.CourseLessonLessonTypeId == quizLessonTypeId">
            <span *ngIf="data?.CourseLessonProgressLessonComplete">
                <i class="fa fa-check-circle dw-green"></i>
            </span>
            <span *ngIf="!data?.CourseLessonProgressLessonComplete">
                <i class="fa fa-times-circle dw-red" title="Quiz Failed"></i>
            </span>
        </ng-container>
    </div>
</div>

{{data | json }}