import { Inject, Injectable } from "@angular/core";
import { DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";

@DwDatasource({
    keyName: 'StudentCourseProgressListDataSource',
    display: 'Student Course Progress List Data Source',
    itemName: AppMetaDataItemNames.Student,

})
@Injectable()
export class StudentCourseProgressListDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.CourseModule);
    }

    initDataSource() {

    }

    prepareRequestQuery(request: DwDataRequest): DwQuery {
        const baseQuery = super.prepareRequestQuery(request);

        const newQuery = AppMetaData.Queries.StudentCourseProgress.CreateQueryBuilderFromQuery(baseQuery);

        newQuery.addOrderByDesc(o => o.StudentCourseLastActivityDateTime);

        return newQuery.query;
    }

}