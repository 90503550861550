import { Injectable } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, ensureArray, DwMessageSeverity } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, VideoEntity, VideoHistoryEntity } from "../../../meta-data/app-meta-data.service";
import { VideoUploadModalComponent } from "../components/video-upload-modal/video-upload-modal.component";
import { map } from "rxjs/operators";
import { VideoUploadService } from "../services/video-upload.service";


@DwAction({
    keyName: 'video-update-status-action',
    display: 'Sync Video wiht Provider',
    actionType: DwActionType.gridContextMenuAction,
    parentItemName: AppMetaDataItemNames.Video,
    isGlobal: true,
    defaultButtonConfig: {
        useParentDisplay: true,
        iconClass: 'fa fa-video',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'Updates the status and details of this video'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    },
    defaultConfirmConfig: {
        showDialogConfig: true,
        showConfirmDialog: false,
        message: 'Are you sure you wish to update this video?',
        title: 'Update Video'
    }
})
@Injectable()
export class VideoUpdateStatusActionService implements DwActionHandler {
    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private videoUploadService: VideoUploadService,
        private dwRoutingService: DwRoutingService
    ) { }


    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {

        const videos: VideoEntity[] = ensureArray(actionState.data);
        
        const video = videos[0];
        
        return this.videoUploadService.updateVideoDetalis(videos.map(v => v.VideoId))
            .pipe(map(() => {
                const result = new DwGridActionResult();

                result.message = {
                    severity: DwMessageSeverity.success,
                    messageBody: 'Videos updated!',
                    allowClose: true,
                    timeToShow: 5000,
                }
                result.refreshData = true;

                return result;
            }));
    }
}