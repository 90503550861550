import { Inject, Injectable } from "@angular/core";
import { DwOrmDataServiceToken, DwOrmDataService, DwMetaDataServiceToken, DwMetaDataService, DwConfigServiceToken, DwConfigService, DwSecurityUserService, DevwareMetaData, DwUserTypeEntity, DwQueryHelper } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { DwUserTypeExtensionEntity } from "../../../meta-data/extensions/dw-user-type-entity.model";


@Injectable({ providedIn: 'root' })
export class AviatorOnlineHomePageRepository {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        
    }

    getUserTypes(): Observable<DwUserTypeEntity[]> {
        const query = DevwareMetaData.DwUserType.CreateQuery();

        const qh = new DwQueryHelper();

        query.OrderBy(DevwareMetaData.DwUserType.UserTypeOrder, true);

        query.AddFilterAnd(qh.filters.Equal(DevwareMetaData.DwUserType.ShowOnRegistration, true));

        return this.dwOrmDataService.executeQuery<DwUserTypeEntity>(query);
    }
    
}