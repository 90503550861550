import { Injectable } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, StudentModuleLessonActivityQueryEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { QuizResultsModalComponent } from "../components/quiz-results-modal/quiz-results-modal.component";
import { LinkQuestionsModalComponent } from "../components/link-questions-modal/link-questions-modal.component";
import { LinkQuestionsModalResult } from "../models/link-questions-modal-result.model";
import { AppPermissions } from "../../../meta-data/app-permissions.enum";
import { QuizRepositoryService } from "../services/quiz-repository.service";

@DwAction({
    keyName: 'regrade-quiz-action',
    display: 'Regrade Quiz',
    actionType: DwActionType.gridRowAction,
    parentItemName: AppMetaDataItemNames.Student,
    isGlobal: true,
    // 
    defaultMenuConfig: {
        menuDisplay: 'Regrade Quiz',
    },
    defaultSecurityConfig: {
        requireAuthentication: true, 
        useEmulatedPermissions: true,
        permissionMeaning: AppPermissions.studentCourseCanResetProgress
        //requireCanUpdate: true
    }
})
@Injectable()
export class RegardeQuizActionsService implements DwActionHandler {
    constructor(
        /*  @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
         private route: ActivatedRoute,
         private metaDataRoutingService: DwMetaDataRoutingService, */
        private quizRespository: QuizRepositoryService,
    ) { }

    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwGridActionResult> {
        const studentLessonActivity : StudentModuleLessonActivityQueryEntity = actionState.data;

        if (!studentLessonActivity || !studentLessonActivity.QuizResultQuizResultId) {
            return of({ success: false, refreshData: false });
        }

        return this.quizRespository.regradeQuiz(studentLessonActivity.QuizResultQuizResultId)
            .pipe(map(() => {
                return { success: true, refreshData: true, message: 'Quiz has been regraded' };
            }));
    }

}