import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DwBreadCrumbCustomizationFunc, DwBreadCrumbCustomizationProcessor, DwBreadcrumbMenu, DwLookupInfo, DwMenu, DwMenuData, DwMetaDataService, DwMetaDataServiceToken, DwSecurityStateService, DwStorageUtil } from '@devwareapps/devware-cap';
import { AppMetaDataLookups } from '../../../meta-data/app-meta-data.service';
import { Observable, combineLatest } from 'rxjs';
import { CourseRepositoryService } from '../services/course-repoistory.service';
import { CourseNavigationService } from '../services/course-navigation.service';



@Injectable()
export class CourseBreadcrumbProcessor implements DwBreadCrumbCustomizationProcessor {
    coursesLookupItems: DwLookupInfo[] = [];
    moduleLookupItems: DwLookupInfo[] = [];
    lessonLookupItems: DwLookupInfo[] = [];
    
    constructor(private dwSecurityState: DwSecurityStateService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        private courseRepositoryService: CourseRepositoryService,
        private courseNavigationService: CourseNavigationService,
        private dwStorageUtil: DwStorageUtil
    ) {
        this.dwStorageUtil.useLocalStorage = true;
        this.coursesLookupItems = this.dwStorageUtil.getValue<DwLookupInfo[]>('coursesLookupItems') || [];
        this.moduleLookupItems = this.dwStorageUtil.getValue<DwLookupInfo[]>('moduleLookupItems') || [];
        this.lessonLookupItems = this.dwStorageUtil.getValue<DwLookupInfo[]>('lessonLookupItems') || [];
        this.dwStorageUtil.useLocalStorage = false;

        const obs : Observable<DwLookupInfo[]>[] = [];

        obs.push(this.dwMetaDataService.getLookup(AppMetaDataLookups.CourseAllItems));
        obs.push(this.dwMetaDataService.getLookup(AppMetaDataLookups.CourseModuleAllItems));
        obs.push(this.dwMetaDataService.getLookup(AppMetaDataLookups.CourseLessonAllItems));

        combineLatest(obs)
        .subscribe(
            ([coursesLookupItems, moduleLookupItems, lessonLookupItems]) => {
                this.coursesLookupItems = coursesLookupItems;
                this.moduleLookupItems = moduleLookupItems;
                this.lessonLookupItems = lessonLookupItems;

                this.dwStorageUtil.useLocalStorage = true;
                this.dwStorageUtil.setValue('coursesLookupItems', this.coursesLookupItems);
                this.dwStorageUtil.setValue('moduleLookupItems', this.moduleLookupItems);
                this.dwStorageUtil.setValue('lessonLookupItems', this.lessonLookupItems);
                this.dwStorageUtil.useLocalStorage = false;
            });
    }

    processBreadcrumb(breadcrumbMenu: DwBreadcrumbMenu, routeSnapshot: ActivatedRouteSnapshot, url: string, menu: DwMenu, menuData: DwMenuData) {
        const courseParameters = this.courseNavigationService.getCourseUrlParameters(url);

        if (!courseParameters) {
            return;
        }

        if (courseParameters.lessonId) {
            this.updateBreadcrump(this.lessonLookupItems, courseParameters.lessonId, breadcrumbMenu);

            return;
        }

        if (courseParameters.moduleId) {
            this.updateBreadcrump(this.moduleLookupItems, courseParameters.moduleId, breadcrumbMenu);

            return;
        }

        if (courseParameters.courseId) {
            this.updateBreadcrump(this.coursesLookupItems, courseParameters.courseId, breadcrumbMenu);

            return;
        }
    }

    private updateBreadcrump(items: DwLookupInfo[], id: number, breadcrumbMenu: DwBreadcrumbMenu) {
        const item = items.find(c => parseInt(c.Id) == id);

        if (item) {
            breadcrumbMenu.breadcrumbDisplay = item.Display;
        }
    }
        



    //     if (url?.indexOf('//courses/view/')==0) {

    //         const parts = url.replace('//','/').split('/');

    //         const courseId = parseInt(parts[3]);

    //         if (parts.length == 4) {
    //             const course = this.coursesLookupItems.find(c => parseInt(c.Id) == courseId);

    //             if (course) {
    //                 breadcrumbMenu.breadcrumbDisplay = course.Display;
    //             }

    //             return;
    //         }

    //         if (parts.length == 6) {
    //             const moduleId = parseInt(parts[5]);
    //             const module = this.moduleLookupItems.find(c => parseInt(c.Id) == moduleId);

    //             if (module) {
    //                 breadcrumbMenu.breadcrumbDisplay = module.Display;
    //             }

    //             return;
    //         }

    //         if (parts.length == 8) {
    //             const lessonId = parseInt(parts[7]);
    //             const lesson = this.lessonLookupItems.find(c => parseInt(c.Id) == lessonId);

    //             if (lesson) {
    //                 breadcrumbMenu.breadcrumbDisplay = lesson.Display;
    //             }
    //         }



    //     }
    // }
}

DwBreadCrumbCustomizationFunc({
    keyName: 'CourseBreadcrumbProcessor',
    display: 'Course Breadcrumb Processor',
    order: 1
}, CourseBreadcrumbProcessor)