<ng-container *ngIf="!currentModule">
    Course has not been started!
    <dw-content-manager configKey="student-course-progress">
        <h1>
            <dw-content name="notStarted" display="Not Started Message">
                Course has not been started!
            </dw-content>
        </h1>
    </dw-content-manager>
</ng-container>
<ng-container *ngIf="currentModule">
    <!-- <div class="progress-container" *ngIf="initComplete && currentModule && false">
    <div class="progress-left">

    </div>
    <div class="progress-right">
        <app-module-student-progress-card-header [data]="currentModule"></app-module-student-progress-card-header>

        <div class="progress-scrolling-list">
            <dw-meta-data-list [item]="lessonListConfig?.itemName" [configKeyPart]="lessonListConfig?.configKeyPart"
                [filters]="lessonListConfig?.filters"></dw-meta-data-list>
        </div>
    </div>
</div> -->
    <div dwRow>
        <div class="col-xl-6">
            <div class="progress-scrolling-list">
                <dw-meta-data-list [item]="moduleListConfig?.itemName" [configKeyPart]="moduleListConfig?.configKeyPart"
                    [filters]="moduleListConfig?.filters" (cardClick)="clickModule($event)"></dw-meta-data-list>
            </div>
        </div>
        <div class="col-xl-6">
            <app-module-student-progress-card-header [data]="currentModule"></app-module-student-progress-card-header>

            <div class="progress-scrolling-list">
                <dw-meta-data-list [item]="lessonListConfig?.itemName" [configKeyPart]="lessonListConfig?.configKeyPart"
                    [filters]="lessonListConfig?.filters" (cardClick)="clickLesson($event)"></dw-meta-data-list>
            </div>
        </div>
    </div>
</ng-container>