<dw-dynamic-routing-init></dw-dynamic-routing-init>

<!-- <div class="dw-splash" *ngIf="showSlashScreen">
    <img src="/assets/pilexos/img/pilexos_logo_with_tagline.jpg" class="dw-splash-logo">
</div> -->
<div [hidden]="showSlashScreen" [ngClass]="courseSideBarClass">
    <dw-layout *ngIf="showLayout" [bodyClasses]="themeClasses" hideSideBarMinimizer="true">
        <div logo>

        </div>
        <div breadcrumb>

        </div>
        <div topMenu>
            <div style="width:150px">
                <div class="alert alert-warning env-message" [hidden]="!environmentMessage"
                    style="margin-left:50px; margin-top:12px;display:inline">
                    {{environmentMessage}}
                </div>
            </div>
        </div>
        <div topRight>
            <div style2="margin-left:50px; margin-top:12px;color:white; " class="site-header-text">
                <h2>{{flightSchoolName}}</h2>
            </div>
        </div>
        <div sideBarTop>
            <div style="width:100%;text-align: center;margin-bottom:12px;padding-top:18px" *ngIf="showSideLogo">
                <div class="aviator-side-logo"></div>
                
                <!-- <img src="/assets/aviator-online/img/logo-small-transparent.png"
                    style="margin:auto; width:150px; height:150px" class="dw-sidebar-logo"> -->
            </div>
        </div>
    </dw-layout>
</div>