<div class="card card-container card-item hand" (click)="click()">
    <div class="card-image">
        <!-- <img [src]="courseImageUrl" class="card-img-top" style="width:100%"> -->
        <dw-image [src]="courseImageUrl" maxWidth="350px" maxHeight="250px"></dw-image>
    </div>
    <div class="card-body">
        <h5 class="card-title">{{data.CourseName}} <span class="dw-red" *ngIf="expired">- Expired</span></h5>
        
        <div class="card-progress">
            <p-progressBar [value]="progressPercent" [showValue]="false"></p-progressBar>
        </div>

        <button class="btn btn-primary" (click)="click()" *ngIf="!progressPercent && !endorsed">Get Started</button>
        <button class="btn btn-primary" (click)="resume()" *ngIf="progressPercent>0 && !endorsed">Resume Course</button>
        <button class="btn btn-primary" (click)="viewEndorsement()" *ngIf="endorsed">View Endorsement</button>
    </div>
</div>