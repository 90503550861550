import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {  QuestionEntity, QuizQuestionLinkEntity, QuizResultQuestionEntity } from '../../../../meta-data/app-meta-data.service';
import { DwMediaRepositoryService, isFirstChange } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-quiz-question',
  templateUrl: './quiz-question.component.html',
  styleUrls: ['./quiz-question.component.scss']
})
export class QuizQuestionComponent implements OnInit, OnChanges {

  @Input() questionLink: QuizQuestionLinkEntity;
  @Input() questionResult: QuizResultQuestionEntity;
  @Input() reviewAnswers: boolean = false;
  @Output() questionResultChange = new EventEmitter<QuizResultQuestionEntity>();

  questionImageUrl: string = '';
  answerImageUrl: string = '';

  constructor(private dwMediaRepository: DwMediaRepositoryService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isFirstChange(changes)) {
      this.setupQuestion();
    }
  }

  ngOnInit(): void {
    this.setupQuestion();
  }

  setupQuestion() {

    this.questionImageUrl = '';
    if (this.questionLink?.Question?.QuestionImageDwroMedia?.MediaPath) {
      this.questionImageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.questionLink.Question.QuestionImageDwroMedia.MediaPath}`;
    }
    
    this.answerImageUrl = '';
    if (this.questionLink?.Question?.CorrectAnswerImageDwroMedia?.MediaPath) {
      this.answerImageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.questionLink.Question.CorrectAnswerImageDwroMedia.MediaPath}`;
    }
  }

}
