import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CourseRepositoryService } from '../../../courses/services/course-repoistory.service';
import { AppMetaData, AppMetaDataItemNames, AssignmentCourseLessonEntity, CourseEntity } from '../../../../meta-data/app-meta-data.service';
import { TreeNode } from 'primeng/api';
import { DwComponent, DwComponentType, DwSectionBaseComponent } from '@devwareapps/devware-cap';
import { AbstractControl, FormControl } from '@angular/forms';

@DwComponent({
  key: 'lesson-selection-tree-control',
  name: 'Lesson Selection Tree Control',
  componentType: DwComponentType.formSection,
  isGlobal: false,
  parentItemName: AppMetaDataItemNames.Assignment
})
@Component({
  selector: 'app-lesson-selection-tree-form-control',
  template: `
    <!-- Parent selected Ids: {{selectedLessonIds | json}}<br>
    // List Value: {{associativeList?.value | json}}<br> -->

    <div class="lesson-tree-container">
      <app-lesson-selection-tree-control [courseId]="courseIdFC?.value" [(selectedLessonIds)]="selectedLessonIds" (selectedLessonIdsChange)="updateLessons()">
      </app-lesson-selection-tree-control>
    </div>
  `
})
export class LessonSelectionTreeFormControlComponent extends DwSectionBaseComponent {
  associativeList: AbstractControl;

  controlName: string = 'AssignmentCourseLesson';

  courseId: number = 1;

  selectedLessonIds: number[] = [];
  courseIdFC: FormControl;

  ngOnInit(): void {
    this.associativeList = this.formGroup.get(this.controlName);
    this.courseIdFC = this.formGroup.get('CourseId') as FormControl;

    if (!this.associativeList) {
      console.warn(`Form control ${this.controlName} not found in form group. This is required for the lesson selection tree control to work.`);
      return;
    }

    this.runAfterFormDataLoaded(() => {
      this.setupSelectedLessons();
    });

    this.associativeList.valueChanges.subscribe(() => {
      this.setupSelectedLessons();
    })

    this.setupSelectedLessons();
  }

  setupSelectedLessons() {
    const allLessons: AssignmentCourseLessonEntity[] = this.associativeList.value || [];

    this.selectedLessonIds = allLessons.filter(l => !l._isDeleted).map(l => l.CourseLessonId);
  }

  updateLessons() {
    const allLessons: AssignmentCourseLessonEntity[] = this.associativeList.value || [];

    for (let lesson of allLessons) {
      lesson._isDeleted = true;
    }

    for (let lessonId of this.selectedLessonIds) {
      let lesson = allLessons.find(l => l.CourseLessonId == lessonId);

      if (lesson) {
        lesson._isDeleted = false;
      } else {

        lesson = AppMetaData.AssignmentCourseLesson.CreateEntity();

        lesson.CourseLessonId = lessonId;

        allLessons.push(lesson);
      }
    }

    this.associativeList.setValue(allLessons);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}