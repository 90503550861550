<div class="dashboard-container">
    <div class="layout-row">
        <div class="layout-column layout-column-3">
            <div class="layout-row" *ngIf="currentCourseProgress?.furtherestLesson">
                <div class="dashboard-section" id="lesson-dashboard">
                    <div class="header">
                        Continue {{currentCourseProgress.course.CourseName}}
                    </div>

                    <dw-meta-data-list [item]="'CourseLesson'" [configKeyPart]="'home-page-progress'"
                        [filters]="courseProgressFilters"></dw-meta-data-list>
                </div>
            </div>
            <div class="layout-row">
                <div class="dashboard-section" id="course-dashboard">
                    <div class="header">
                        My Courses
                    </div>

                    <dw-meta-data-list [item]="'Course'" [configKeyPart]="'course-list-home-page'"></dw-meta-data-list>
                </div>
            </div>
        </div>
        <div class="layout-column dashboard-column2">
            <div class="layout-row">
                <div class="dashboard-section" style="height:100%" id="assignments-dashboard">
                    <div class="header">
                        My Assignments
                    </div>
                    <div style="max-height:600px;overflow:auto;">
                        <dw-meta-data-list [item]="'Assignment'"
                            [configKeyPart]="'home-page-assignments'"></dw-meta-data-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>