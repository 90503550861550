import { Component, Inject, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwEventService, DwExpressionService, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwSectionBaseComponent, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUserEntity } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, AssignmentEntity, InstructorEntity } from '../../../../meta-data/app-meta-data.service';
import { InstructorRepositoryService } from '../../../instructors/services/instructor-repository.service';
import { SchoolRepositoryService } from '../../services/school-repository.service';
import { UserRoleDetailsContext } from '../../contexts/user-role-details-context.service';

@DwComponent({
  key: 'manageUserUtil',
  name: 'Manage User Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.DwUser,
})
@Component({
  selector: 'app-manager-user-util',
  template: ``
})
export class ManagerUserUtilComponent extends DwSectionBaseComponent {
 
  formApi: DwMetaDataFormApi;
  user: DwUserEntity;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Inject(DwOrmDataServiceToken) private dataService: DwOrmDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private dwEventService: DwEventService,
    private schoolRepostitoryService: SchoolRepositoryService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupEventHandlers();
  }


  setupEventHandlers() {
    this.user = this.formApi.getFormData();

    this.dwEventService.getEventData(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.DwUserRole.ItemDetail.itemName))
      .subscribe(() => {
        this.updateUserRoleDetail();
      });
  }

  /** Updates the user role detail context with the latest data after roles are updated (this isn't trigger automatically for a context right now) */
  private updateUserRoleDetail() {
    this.user = this.formApi.getFormData();
     this.schoolRepostitoryService.getUserRoleDetaill(this.user?.UserId)
      .subscribe(userRoleDetail => {
          this.formApi.patchContextData(UserRoleDetailsContext, userRoleDetail)
      });
  }

}
