import { Component, OnInit } from '@angular/core';
import { DwComponent, DwComponentType } from '@devwareapps/devware-cap';

@DwComponent({
  key: 'securityAvatar',
  componentType: DwComponentType.pageComponent,
  name: 'Aviator Avatar'
})
@Component({
  selector: 'app-user-avatar',
  templateUrl: './usre-avatar.component.html',
  styleUrls: ['./usre-avatar.component.scss']
})
export class UsreAvatarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
