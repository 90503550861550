import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwEventService, DwFileDetail, DwFileUploadModalResult, DwFileUtilService, DwMediaUploadResult, DwMessage, DwMessageSeverity, DwModalConfig, DwModalHandler, DwModalResult, DwOrmDataService } from '@devwareapps/devware-cap';
import { Observable, combineLatest, of } from 'rxjs';
import { AppMetaData, QuizEntity, QuizResultEntity, StudentModuleLessonActivityQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { QuizRepositoryService } from '../../services/quiz-repository.service';
import { QuizHomeView } from '../../models/quiz-home-view.enum';
import { QuizCompleteResult } from '../../models/quiz-complete-result.model';
import { QuizViewAction, QuizViewActionType } from '../../models/quiz-view-action.enum';
import { QuizResultModalConfig } from '../../models/quiz-result-modal-config.model';

@Component({
  selector: 'app-quiz-results-modal',
  templateUrl: './quiz-results-modal.component.html',
  styleUrls: ['./quiz-results-modal.component.scss']
})
export class QuizResultsModalComponent implements OnInit, DwModalHandler {

  modalConfig: DwModalConfig<QuizResultModalConfig>;
  closeModal = new EventEmitter<DwModalResult<any>>();
  uploadStarted: any;

  allowMultiple: boolean = true;

  maxFiles = 20;
  checkingEncodingStatus: boolean;
  canCloseAll: boolean = false;
  quizResult: QuizResultEntity;
  quiz: QuizEntity;

  view: QuizHomeView = QuizHomeView.lastResult;

  startingQuizResultQuestionId: number;

  constructor(
    private quizRepositoryService: QuizRepositoryService,
  ) { }

  ngOnInit(): void {

    this.startingQuizResultQuestionId = this.modalConfig.data.quizResultQuestionId;

    if (this.startingQuizResultQuestionId) {
      this.view= QuizHomeView.quizAnswers;
    }
    this.loadQuizResultData();
  }

  close() {
    this.closeModal.emit();
  }

  loadQuizResultData() {
    const obs : Observable<any>[] = [];

    obs.push(this.quizRepositoryService.loadQuizResult(this.modalConfig.data.quizResultId));
    obs.push(this.quizRepositoryService.getQuiz(this.modalConfig.data.quizId));

    combineLatest(obs).subscribe(([quizResult, quiz]) => {
      this.quizResult = quizResult;
      this.quiz = quiz;
    });
  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult<any>> {
    let result = new DwModalResult();

    result.cancelled = false;

    if (!this.canCloseAll && this.uploadStarted) {
      return of(result);
    }

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:
        if(this.canCloseAll) {
          result.closeModal = true;

          return of(result);
        }

        return of(result);

      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }
  
  quizComplete(event: QuizCompleteResult) {
    this.view = QuizHomeView.lastResult;
  }

  quizResultAction(action: QuizViewAction) {
    if (action.isCancelled) {
      this.view = QuizHomeView.lastResult;
      return;
    }

    switch (action.actionType) {
      case QuizViewActionType.viewAnswers:
        this.view= QuizHomeView.quizAnswers;
        break;
    }

  }
}