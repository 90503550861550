import { Injectable } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { QuizResultsModalComponent } from "../components/quiz-results-modal/quiz-results-modal.component";
import { LinkQuestionsModalComponent } from "../components/link-questions-modal/link-questions-modal.component";
import { LinkQuestionsModalResult } from "../models/link-questions-modal-result.model";

@DwAction({
    keyName: 'link-quiz-question-action',
    display: 'Link Quiz Questions',
    actionType: DwActionType.gridRowAction,
    parentItemName: AppMetaDataItemNames.Question,
    isGlobal: true,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Link to Quiz',
        iconClass: 'fa fa-question-circle',
        buttonClass: 'btn-sm btn-warning',
        buttonTitle: 'Link selected questions to a quiz'
    },
    defaultSecurityConfig: {
        requireAuthentication: true, 
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    }
})
@Injectable()
export class LinkQuestionActionsService implements DwActionHandler {
    constructor(
        /*  @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
         private route: ActivatedRoute,
         private metaDataRoutingService: DwMetaDataRoutingService, */
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService
    ) { }

    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {
        return this.showModal(actionState);
    }

    private showModal(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {
        let modalConfig = new DwModalConfig();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.SaveAndCancel;

        modalConfig.allowFullScreen = true;
        modalConfig.modalSize = DwModalSize.medium;

        modalConfig.component = LinkQuestionsModalComponent;

        const questions = ensureArray(actionState.data);

        modalConfig.title = 'Link Questions';
        modalConfig.data = questions;

        
        return this.dwModalService.showModal<any,LinkQuestionsModalResult>(modalConfig)
            .pipe(map(modalResult => {
                const result = new DwGridActionResult();

                if (result.cancelled) {
                    result.refreshData = false;
                    result.cancelled = true;

                    return result;
                }

                if (modalResult.data?.openQuizAfter) {
                    const url = `/lms-admin/quiz-management/quizzes/edit/${modalResult.data?.quiz.QuizId}`;

                    this.dwRoutingService.navigateToUrl(url, null);

                    result.refreshData = true;
                } else {
                    const questionCount = `${questions.length} question${questions.length > 1 ? 's' : ''}`;
                    result.message = modalResult.data.isNewQuiz ? `Quiz created with ${questionCount}` : `Quiz updated with ${questionCount}`;

                    result.refreshData = true;
                }

                return result;
            }));
    }

}