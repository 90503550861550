import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwEventService, DwFileDetail, DwFileUploadModalResult, DwFileUtilService, DwMediaUploadResult, DwMessage, DwMessageSeverity, DwModalConfig, DwModalHandler, DwModalResult, DwOrmDataService } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { AppMetaDataItemNames, AppMetaDataLookups, VideoEntity, VideoStatusAllItems, VideoStreamingProviderAllItems } from '../../../../meta-data/app-meta-data.service';
import { VideoUploadService } from '../../services/video-upload.service';
import { VideoUploadRequest } from '../../models/video-upload-request.model';
import { VideoUploadResult } from '../../models/video-upload-result.model';
import { VideoRepositoryService } from '../../services/video-repository.service';

@Component({
  selector: 'app-video-upload-modal',
  templateUrl: './video-upload-modal.component.html',
  styleUrls: ['./video-upload-modal.component.scss']
})
export class VideoUploadModalComponent implements OnInit, DwModalHandler {

  videoStreamingProviderLookupId = AppMetaDataLookups.VideoStreamingProviderAllItems;
  videoStreamingProviderId: number = VideoStreamingProviderAllItems.ApiVideo;
  videoLibraryId?: number;
  matchFileName: boolean = false;

  allowedFileTypes = ".mp4";
  maxFileSizeBytes = 4000000000;
  showUpload: boolean = true;

  fileDetails: DwFileDetail[] = [];
  uploadResults: VideoUploadResult[] = [];
  message: DwMessage;

  modalConfig: DwModalConfig<VideoEntity>;
  closeModal = new EventEmitter<DwModalResult<any>>();
  uploadStarted: any;

  allowMultiple: boolean = true;

  maxFiles = 40;
  checkingEncodingStatus: boolean;
  canCloseAll: boolean = false;

  constructor(
    private videoUploadService: VideoUploadService,
    private videoRepositoryService: VideoRepositoryService,
    private dwFileUtilService: DwFileUtilService,
    private dwEventService: DwEventService
  ) { }

  ngOnInit(): void {
    if (this.modalConfig.data?.VideoId) {
      this.allowMultiple = false;
    }
  }

  onFilesSelected(files: FileList) {
    if (files) {
      this.dwFileUtilService.buildFileDetails(files, this.allowedFileTypes, this.maxFileSizeBytes)
        .subscribe(newFileDetails => {
          this.fileDetails = [...this.fileDetails, ...newFileDetails].filter(fd => !fd.cancelled);

          this.uploadResults = this.uploadResults.filter(r => !r.isCancelled);

          this.setShowUpload();
        });
    }
  }

  setShowUpload() {
    let showUpload = true;

    this.clearMessage();

    if (this.uploadStarted) {
      showUpload = false;
    } else {
      if (!this.allowMultiple) {
        const existingFile = this.fileDetails.find(fd => !fd.cancelled);
        if (existingFile) {
          showUpload = false;
        }
      } else {
        if (this.maxFiles) {
          const totalFileCount = this.getTotalFileCount();

          if (totalFileCount >= this.maxFiles) {
            showUpload = false;
            if (totalFileCount > this.maxFiles) {
              const additionalFileCount = this.maxFiles;

              const filesToRemoveCount = totalFileCount - this.maxFiles;

              this.setMessage(`You can upload only ${additionalFileCount} files for a total of ${this.maxFiles} Files. Please remove ${filesToRemoveCount} files`, DwMessageSeverity.error);
            }
          }
        }
      }
    }

    this.showUpload = showUpload;
  }

  getTotalFileCount() {
    const fileCount = this.fileDetails.filter(fd => !fd.cancelled).length;

    //const existingFileCount = this.fileUploadOptions.existingFilesCount || 0;

    return fileCount;
  }

  getInvalidFileCount() {
    return this.fileDetails.filter(fd => !fd.cancelled && !fd.isValid).length;
  }

  setMessage(message: string, severity: DwMessageSeverity = DwMessageSeverity.error) {
    this.message = {
      messageBody: message,
      severity: severity
    };
  }

  clearMessage() {
    this.message = null;
  }

  validateFiles(): boolean {

    const invalidFileCount = this.getInvalidFileCount();

    if (invalidFileCount > 0) {
      this.setMessage(`Please remove any files will errors.`, DwMessageSeverity.warning);

      return false;
    }

    const totalFileCount = this.getTotalFileCount();

    if (totalFileCount > this.maxFiles) {
      return false;
    }

    var validFiles = this.fileDetails.filter(fd => !fd.cancelled && fd.isValid).length;

    if (validFiles === 0) {
      this.setMessage('Please select a file to upload');
      return false;
    }

    return true;
  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult<any>> {
    let result = new DwModalResult();

    result.cancelled = false;

    if (!this.canCloseAll && this.uploadStarted) {
      return of(result);
    }

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:
        if(this.canCloseAll) {
          result.closeModal = true;

          return of(result);
        }

        if (!this.validateFiles()) {
          return of(result);
        }

        this.uploadMedia();

        return of(result);

      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }


  cancelFile(fileDetail: DwFileDetail) {
    fileDetail.cancellationSubject.next();
    fileDetail.cancelled = true;

    const index = this.fileDetails.indexOf(fileDetail);

    const uploadResult = this.uploadResults[index];

    if (uploadResult) {
      uploadResult.isCancelled = true;
    }

    this.setShowUpload();
  }

  uploadMedia() {
    if (this.uploadStarted) {
      return; 
    }

    this.uploadStarted = true;

    const videoUploadRequest: VideoUploadRequest = {
      VideoStreamingProviderId: this.videoStreamingProviderId,
      VideoLibraryId: this.videoLibraryId,
      MatchFileName: this.matchFileName,
      UpdateExistingVideoId: this.modalConfig.data?.VideoId
    }

    this.videoUploadService.uploadVideos(this.fileDetails, videoUploadRequest, 1)
      .subscribe(uploadResults => {
        this.uploadResults = uploadResults;

        this.handleUploadResults();

      });
  }


  mergeUploadResult(uploadResults: VideoUploadResult[]) {

    if (!this.updateResult) {
      this.uploadResults = uploadResults;
      return;
    }

    for (const uploadResult of uploadResults) {
      if (!uploadResult) {
        continue;
      }

      const existingResult = this.uploadResults.find(r => r.fileName == uploadResult.fileName);

      if (existingResult) {
        const index = this.uploadResults.indexOf(existingResult);

        this.uploadResults[index] = uploadResult;
      } else {
        this.uploadResults.push(uploadResult);
      }

    }
  }

  private handleUploadResults() {
    // Check if it is complete
    const pendingFiles = this.uploadResults.find(r => (!r.isProcessing && !r.uploadResult) && !r.isCancelled && !r.isError);

    // Wait until all pending files are complete
    if (pendingFiles) {


      this.checkEncodingStatuses();
      return;
    }

    this.checkEncodingStatuses();
  }

  private uploadComplete() {

    this.canCloseAll = true;
    
    const errorFiles = this.uploadResults.find(r => r.isError);

    if (errorFiles) {

      console.log('Error Files', errorFiles);
      this.setMessage('One or more files failed to upload.  Retry or remove these files, then click ok', DwMessageSeverity.error);

      for (const result of this.uploadResults) {
        if (result.isError) {
          const fileDetail = this.fileDetails.find(fd => fd.file.name == result.fileName);

          fileDetail.errorMessage = result.errorMessage;
          fileDetail.isValid = false;
        }
      }

      return;
    }

    //console.log('Upload Complete - done and closing up shop!');
    

    this.message = {
      severity: DwMessageSeverity.success,
      messageBody: 'Upload and Encoding Complete!'
    };

    return;

    // const fileUploadModalResult: DwFileUploadModalResult = {
    //   mediaGroupId: -1,
    //   media: this.fileDetails.map(fd => fd.media).filter(m => m),
    // };

    // let modalResult = new DwModalResult();

    // modalResult.cancelled = false;
    // modalResult.data = fileUploadModalResult;
    // modalResult.closeModal = true;

    // this.closeModal.emit(modalResult);
  }

  private checkEncodingStatuses(force?: boolean) {

    if (this.checkingEncodingStatus && !force) {
   //   console.log('checkEncodingStatuses - existing since already checking encoding status');
      return;
    }

    this.checkingEncodingStatus = true;
    const pendingFiles = this.uploadResults.find(r => !r.isComplete && !r.isCancelled && !r.isError);
    const processingFiles = this.uploadResults.filter(r => r.isProcessing && r.uploadResult?.VideoId);

    if (processingFiles.length == 0) {
      this.checkingEncodingStatus = false;

      if (!pendingFiles) {
       // console.log('checkEncodingStatuses - no pending files, upload complete');
        this.uploadComplete();
      }

     // console.log('checkEncodingStatuses - pending file exists, exiting until next processing event');
      return;
    }

    const videoIds = processingFiles.map(v => v.uploadResult?.VideoId);

    this.videoUploadService.updateVideoStatuses(this.videoStreamingProviderId)
      .subscribe(updated => {
        this.updateVideoStatuses(updated, videoIds)
      });
  }
  updateVideoStatuses(updated: boolean, videoIds: number[]) {

    let obs = of([]);

    if (updated) {
      //console.log('Updated Video Statuses')
      obs = this.videoRepositoryService.getVideos(videoIds);
    }

    obs.subscribe(videos => {
      for (const video of videos) {
        const uploadResult = this.uploadResults.find(r => r.uploadResult?.VideoId == video.VideoId);

        if (uploadResult) {

          this.updateResult(video, uploadResult);
        }
      }

      this.canCloseAll = this.uploadResults.filter(r => r.canClose == false).length == 0;

      const encodingFiles = this.uploadResults.filter(r => r.isProcessing && r.uploadResult?.VideoId);

      if (encodingFiles.length == 0) {
        //console.log('All files encoding is complete');

        this.uploadComplete();

        return;
      }

      if (this.canCloseAll) {
        this.message = {
          severity: DwMessageSeverity.success,
          messageBody: 'All files uploaded and being encoded!'
        };
      }

      setTimeout(() => {
        //console.log('Set Timeout - check encoding status');
        this.checkEncodingStatuses(true);
      }, 5000);
    });
  }

  updateResult(video: VideoEntity, uploadResult: VideoUploadResult) {
    switch (video?.VideoStatusId) {
      case VideoStatusAllItems.Uploading:
        //uploadResult.isUploading = true;
        uploadResult.isProcessing = true;
        uploadResult.processingIconClass = 'fa fa-cloud-upload dw-orange';
        uploadResult.progressingStatusMessage = 'Uploading to Video Provider';

        //console.log(`Update Result: ${uploadResult.fileName} - Uploading`);

        break;
      case VideoStatusAllItems.Encoding:
        uploadResult.isProcessing = true;
        uploadResult.processingIconClass = 'fa fa-video dw-blue';
        uploadResult.progressingStatusMessage = 'Encoding';

        uploadResult.canClose = true;

        //console.log(`Update Result: ${uploadResult.fileName} - Encoding`);
        break;

      case VideoStatusAllItems.Active:

        if (uploadResult.isProcessing) {
          uploadResult.canClose = true;
          uploadResult.isProcessing = false;
          uploadResult.processingIconClass = 'fa fa-check dw-green';
          uploadResult.progressingStatusMessage = 'Complete';
          uploadResult.isComplete = true;

          // Publish an event to notify that the video has been completed
          this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Video), {});
        }

      //  console.log(`Update Result: ${uploadResult.fileName} - Active   - Processing Complete`);
        break;
    }
  }

}
