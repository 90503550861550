import { Injectable, Optional } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray, DwMetaDataFormStateService, DwMetaDataFormApi, DwEventService, DwGridEvents, DwActionItemRequirement, DwMdFormActionHandler, DwMdFormActionState } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentEntity, CourseEntity, StudentCourseEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { CourseProgressVariableContext } from "../../courses/models/course-progress-variable-context.model";
import { StudentAssignmentProgressEvent, studentAssignmentProgress } from "../models/student-assignment-progress-event.model";
import { SchoolRepositoryService } from "../services/school-repository.service";
import { ResetStudentAssignmentRequest } from "../models/reset-student-assignment-request.model";
import { StudentCourseProgressRequest } from "../models/student-course-progress-request.model";
import { AppPermissions } from "../../../meta-data/app-permissions.enum";

@DwAction({
    keyName: 'course-update-all-student-progress',
    display: 'Update All Student Progress',
    actionType: DwActionType.formAction,
    parentItemName: AppMetaDataItemNames.Course,
    isGlobal: false,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Update Student Progress',
        iconClass: 'fa fa-book',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'Updates course progress for all students in this course.  This might take a few minutes depending on the number of students.'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        permissionMeaning: AppPermissions.studentCanManageAll,
        requireCanUpdate: false
    },
    defaultConfirmConfig :{
        showDialogConfig: true,
        showConfirmDialog: true,
        title: 'Updates student progress for Course?',
        message: 'This might take a few minutes depending on the number of students.',
    }
})
@Injectable()
export class UpdateAllStudentProgressActionService implements DwMdFormActionHandler {
    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService,
        @Optional() private dwFormStateService: DwMetaDataFormStateService,
        private schoolRespositoryService: SchoolRepositoryService,
        private dwEventService: DwEventService
    ) {
        
    }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {

        const course = actionState.formApi.getFormData<CourseEntity>();

        return this.schoolRespositoryService.updateAllCourseProgress(course.CourseId)
            .pipe(map((resetResult) => {
                const result: DwActionResult = {
                    success: resetResult.IsSuccess
                };

                if (resetResult.ErrorMessage) {
                    result.message = `Error updating student progress  Error: ${resetResult.ErrorMessage} `
                } else {
                    result.message = 'Student progress updated successfully';
                }
        
                return result;
            }));
    }
}