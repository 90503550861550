

export enum AviatorOnlineUserType {
   aviatorOnlineAdmin='AviatorOnlineAdmin',
   student='student',
   flightSchool='flightSchool',
   flightInstructor='flightInstructor',
}

export enum AviatorOnlineUserTypeIds {
    student=1,
    flightSchool=2,
    aviatorOnlineAdmin=3,
    flightInstructor=4,
}

export function convertUserType(userType: AviatorOnlineUserType): number {
    switch (userType) {
        case AviatorOnlineUserType.student:
            return AviatorOnlineUserTypeIds.student;
        case AviatorOnlineUserType.flightSchool:
            return AviatorOnlineUserTypeIds.flightSchool;
        case AviatorOnlineUserType.flightInstructor:
            return AviatorOnlineUserTypeIds.flightInstructor;
        case AviatorOnlineUserType.aviatorOnlineAdmin:
            return AviatorOnlineUserTypeIds.aviatorOnlineAdmin;
    }
}

