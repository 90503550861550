import { Component, Input, OnInit, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { DwMediaRepositoryService, DwRoMediaEntity, isFirstChange } from '@devwareapps/devware-cap';



@Component({
  selector: 'dw-image2',
  template: `
    <div [ngStyle]="containerStyle">
      <img [src]="src" [defaultSrc]="defaultSrc" [ngStyle]="imageStyle" [ngClass]="class" *ngIf="showImage">
    </div>
  `
})
export class DwImage2Component implements OnInit, OnChanges {
  @Input() src: string;
  @Input() mediaId: number;
  @Input() mediaPath: string;

  @Input() defaultSrc: string;
  @Input() maxWidth: string;
  @Input() maxHeight: string;

  @Input() class: string;

  @Input() dwStyle: any;

  @Input() showAfterLoad: boolean = true;

  @Input() screenMargin: number = 20;

  showImage: boolean = false;

  media: DwRoMediaEntity;
  imageSize: { height: number; width: number; };

  constructor(private dwMediaRepository: DwMediaRepositoryService) { }

  imageStyle: any;
  containerStyle: any;

  lastSrc: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!isFirstChange(changes)) {
      this.setupImage();
    }
  }

  ngOnInit(): void {
    this.setupImage();

    this.showImage = !this.showAfterLoad;
  }

  setupImage() {

    if (this.mediaPath) {
      this.src = this.dwMediaRepository.getImageUrlFromMediaPath(this.mediaPath);
    }

    if (this.mediaId && !this.media) {
      this.dwMediaRepository.loadMedia(this.mediaId).subscribe(mediaItems => {
        this.media = mediaItems[0];

        this.src = this.dwMediaRepository.getImageUrlFromMediaPath(this.media?.MediaPath);

        this.setupImage();
      });

      return;
    }

    this.containerStyle = {
      'width': this.maxWidth || '100%',
      'height': this.maxHeight,
      'overflow': 'hidden'
    }

    this.imageStyle = {
      'width': this.maxWidth || '100%'
    }

    if (this.dwStyle) {
      this.imageStyle = this.dwStyle;
    }

    if (!this.src) {
      return;
    }

    if (this.lastSrc && this.src == this.lastSrc) {
      return;
    }

    this.lastSrc = this.src;

    const image = new Image();
    image.src = this.src;

    image.onload = (e: any) => {
      this.imageSize = this.getImageSize(e);

      this.finalImageSetup(this.imageSize);

      this.showImage = true;
    };
  }

  finalImageSetupOld(imageSize: { height: number, width: number }) {

    if (!imageSize) {
      this.imageStyle = {
        'width': this.maxWidth || '100%'
      }
      return;
    }

    if (imageSize.width > imageSize.height) {
      this.imageStyle = {
        'width': this.maxWidth
      }
    } else {
      this.imageStyle = {
        'height': this.maxHeight
      }
    }

  }


  @HostListener('window:resize', ['$event'])
  public reset(event: any): void {
    // re-configure the grid style upon browser resize
    if (this.imageSize) {
      this.finalImageSetup(this.imageSize);
    }
  }


  finalImageSetup(imageSize: { height: number, width: number }) {
    let maxWidthNumber: any = this.parseDimension(this.maxWidth);
    let maxHeightNumber: any = this.parseDimension(this.maxHeight);


    const screenWidth = window.innerWidth - this.screenMargin;
    const screenHeight = window.innerHeight - this.screenMargin;

    const height = imageSize.height;
    const width = imageSize.width;

    // if (width >= height && width > (screenWidth-this.screenMargin)) {
    //   this.imageStyle = {
    //     'width': '100%'
    //   }

    //   // Check if 100% width will still make the height too tall


    //   return
    // }

    if (height >= width && height > (screenHeight - this.screenMargin)) {
      this.imageStyle = {
        'height': '100%'
      }
      return
    }

    if (maxHeightNumber && height > maxHeightNumber) {
      let adjustedWidth = (height / maxHeightNumber) * width;

      if (width > screenWidth) {

        let adjustedHeight = (screenWidth / width) * height;

        if (adjustedHeight < maxHeightNumber) {
          this.imageStyle = {
            'width': '100%'
          }
          return;
        }
      }

      if (!maxWidthNumber || adjustedWidth <= maxWidthNumber) {
        // Need to resize to fit height
        this.imageStyle = {
          'height': this.maxHeight,
          //'width': (height / maxHeightNumber) * width + "px"
        }
        return;
      }
      if (width >= height && width > (screenWidth - this.screenMargin)) {
        this.imageStyle = {
          'width': '100%'
        }

        return
      }
    }

    if (maxWidthNumber && width > maxWidthNumber) {
      this.imageStyle = {
        'width': this.maxWidth,
        //'width': (height / maxHeightNumber) * width + "px"
      }
      return;
    }

    // if we get here, then the image is smaller than the max width and height
    // so, we set either the width or height to the actual image width/height and we should be good
    this.imageStyle = {
      'width': imageSize.width + 'px'
    }

    // if (width > height) {
    //   this.width = this.maxWidth;
    //   this.height = null;
    // } else {
    //   this.height = this.maxHeight;
    //   this.width = null;
    // }

  }

  parseDimension(dimension: string): number {

    if (dimension?.indexOf("px") > 0) {
      return parseInt(dimension.replace("px", ""));
    }

    return null;
  }

  private getImageSize(imageLoadEvent: any): { height: number, width: number } {
    const image = imageLoadEvent.currentTarget;

    if (!image) {
      return null;
    }

    return { height: image.height, width: image.width };
  }

}
