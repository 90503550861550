import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DwMenuService, DwMenuServiceState, DwSecurityUserService, DwAuthenticationType, DwComponent, DwComponentType } from '@devwareapps/devware-cap';
import { AviatorOnlineUser } from '../../../shared/models/aviator-user.model';


@DwComponent({
  key: 'flight-school-settings',
  name: 'Flight School Settings',
  componentType: DwComponentType.pageComponent,
})
@Component({
  selector: 'app-flight-school-settings',
  templateUrl: './flight-school-settings.component.html',
  styleUrls: ['./flight-school-settings.component.scss']
})
export class FlightSchoolSettingsComponent implements OnInit, OnDestroy {
  keyValue: number;
  subscriptions: Subscription[] = [];
  configKeyPart: string = 'profile';
  aviatorUser: AviatorOnlineUser;

  constructor(
    private dwSecurityUserService: DwSecurityUserService,
  ) {

  }

  ngOnInit() {
    this.subscriptions.push(
      this.dwSecurityUserService.securityContextChanged().subscribe(securityContext => {
        if (securityContext) {
          this.aviatorUser = new AviatorOnlineUser(securityContext?.ApplicationUser);

          this.keyValue = this.aviatorUser?.FlightSchoolId;
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}