import { Component, Inject, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwExpressionService, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService } from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CourseLessonEntity, StudentCourseEntity, StudentCourseProgressQueryEntity } from '../../../../../meta-data/app-meta-data.service';
import { DateTimeUtilService } from '../../../../shared/util/date-time-util.service';
import { CourseNavigationService } from '../../../services/course-navigation.service';
import { CourseRepositoryService } from '../../../services/course-repoistory.service';


@DwComponent({
  key: 'courseStudentProgressFormCard',
  name: 'Course Student Progress Card - Form',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.StudentCourse,
  isGlobal: false,
})
@Component({
  selector: 'app-course-student-progress-form-card',
  template: `
    <div class="progress-list course-progress-list">
      <app-course-student-progress-card [data]="studentCourseProgress"></app-course-student-progress-card>
    </div>
  `
})
export class CourseStudentProgressFormCardComponent  extends DwSectionBaseComponent {
  formApi: DwMetaDataFormApi;
  studentCourseProgress: StudentCourseProgressQueryEntity;
  studentCourse: StudentCourseEntity;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private courseRepositoryService: CourseRepositoryService,
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  }
  ngOnInit(): void {

    this.setupSection();
  }

  setupSection() {

    this.studentCourse = this.formApi.getFormData<StudentCourseEntity>();

    this.courseRepositoryService.getStudentCourseProgressQuery(this.studentCourse?.StudentCourseId)
      .subscribe(studentCourseProgress => {
        this.studentCourseProgress = studentCourseProgress;
      });
  }

}
