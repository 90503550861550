<app-dw-card-designer [data]="data" [cardDesignerConfig]="cardDesignerConfig" [queryMetaData]="queryMetaData"
    (cardClick)="click()" (cardItemResultChange)="setCardItemResult($event)">
    <div cardDesc>
        <div class="hide-mobile">
            <div *ngIf="data?.LessonComplete">
                {{cardItemResult?.additionalFields?.completed}}

                <span
                    *ngIf="data?.CourseLessonLessonTypeId == quizLessonTypeId && data?.SessionCount && data.BestQuizResultId">Attempts:
                    {{data?.SessionCount}}</span>
            </div>
            <div *ngIf="data?.LastActivityDateTime && !data?.LessonComplete && data?.TotalTimeSeconds>0">
                {{cardItemResult?.additionalFields?.lastActivity}}

                <span
                    *ngIf="data?.CourseLessonLessonTypeId == quizLessonTypeId && data?.SessionCount && data?.BestQuizResultId">Attempts:
                    {{data?.SessionCount}}</span>
            </div>
        </div>
        <div class="show-mobile">
            <span *ngIf="data?.LessonComplete">
                {{cardItemResult?.additionalFields?.completed}}
            </span>
            <span *ngIf="data?.LastActivityDateTime && !data?.LessonComplete && data?.TotalTimeSeconds>0">
                {{cardItemResult?.additionalFields?.lastActivity}}
            </span>
            <ng-container *ngIf="data?.CourseLessonLessonTypeId == quizLessonTypeId">
                <ng-container *ngIf="data?.BestQuizResultId">
                    <span *ngIf="data?.LessonComplete" class="pull-right" title="Quiz Passed">
                        Passed - {{cardItemResult?.additionalFields?.quizScore}}
                        <i class="fa fa-check-circle dw-green"></i>
                    </span>
                    <span *ngIf="!data?.LessonComplete" class="pull-right">
                        Failed - {{cardItemResult?.additionalFields?.quizScore}}
                        <i class="fa fa-times-circle dw-red" title="Quiz Failed"></i>
                    </span>
                </ng-container>
                <ng-container *ngIf="!data?.BestQuizResultId && !data?.LessonComplete">
                    <span clas="pull-right">
                        Not Started
                    </span>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="data?.CourseLessonLessonTypeId != quizLessonTypeId">
                <span *ngIf="data?.LessonComplete" class="pull-right">
                    <i class="fa fa-check-circle dw-green" title="Complete"></i>
                </span>

                <span *ngIf="data?.TotalTimeSeconds > 0 && data?.PercentComplete < 100 && !data?.LessonComplete"
                    class="pull-right">
                    {{data?.PercentComplete}}% Complete
                </span>

                <div dwRow *ngIf="data?.TotalTimeSeconds==0 && !data?.LessonComplete">
                    <div dwCol="12">
                        Not Started
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div cardRight class="card-additional-data hide-mobile">

        <div dwRow *ngIf="data?.TotalTimeSeconds > 0">
            <div dwCol="12">
                {{cardItemResult?.additionalFields?.timeSpent}}
            </div>
        </div>
        <ng-container *ngIf="data?.CourseLessonLessonTypeId == quizLessonTypeId">
            <div dwRow *ngIf="data?.BestQuizResultId">
                <div dwCol="12">
                    <span *ngIf="data?.LessonComplete">Passed</span>
                    <span *ngIf="!data?.LessonComplete">Failed</span> -
                    {{cardItemResult?.additionalFields?.quizScore}}

                    <i *ngIf="!data?.LessonComplete" class="fa fa-times-circle dw-red" title="Quiz Failed"></i>
                    <i *ngIf="data?.LessonComplete" class="fa fa-check-circle dw-green" title="Complete"></i>
                </div>
            </div>
            <div dwRow *ngIf="!data?.BestQuizResultId && !data?.LessonComplete">
                <div dwCol="12">
                    Not Started
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="data?.CourseLessonLessonTypeId != quizLessonTypeId">
            <div dwRow *ngIf="data?.LessonComplete">
                <div dwCol="12">
                    <i class="fa fa-check-circle dw-green" title="Complete"></i>
                </div>
            </div>
            <div dwRow *ngIf="data?.TotalTimeSeconds > 0 && data?.PercentComplete < 100 && !data?.LessonComplete">
                <div dwCol="12">
                    {{data?.PercentComplete}}% Complete
                </div>
            </div>

            <div dwRow *ngIf="data?.TotalTimeSeconds==0 && !data?.LessonComplete">
                <div dwCol="12">
                    Not Started
                </div>
            </div>
        </ng-container>

    </div>
</app-dw-card-designer>
<!-- 

<ng-container *ngIf="data?.CourseLessonLessonTypeId == quizLessonTypeId">
    <pre>{{data | json}}</pre>
</ng-container> -->