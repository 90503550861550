<div class="row">
    <div class="login-image">

    </div>
    <div class="login-input">
        <!-- <ng-container *ngFor="let userTypeItem of userTypes">
            <label class="radio-container">
                <input type="radio" name="userType" [value]="userTypeItem.UserTypeId" [(ngModel)]="userType"
                    (ngModelChange)="updateUserType()" />
                <span class="radio-item"> <strong>{{userTypeItem.UserTypeDisplay}}</strong></span>

                <div class="radio-desc">
                    {{userTypeItem.UserTypeDescription}}
                </div>
            </label>
            <div dwRow *ngIf="userType == schoolUserType && userTypeItem.UserTypeId == schoolUserType">
                <div dwCol="12">
                    <label class="label">Flight School Name</label>
                        <input type="text" class="form-control" [(ngModel)]="flightSchoolSignupInfo.FlightSchoolName"
                            (ngModelChange)="updateFlightSchoolInfo()" />
                </div>
                <div dwCol="12">
                    <label class="label">School Location(s) <i class="fa fa-info-circle"
                            title="Location(s) of the Flight School"></i></label>
                    <input type="text" class="form-control" [(ngModel)]="flightSchoolSignupInfo.Location"
                        (ngModelChange)="updateFlightSchoolInfo()" maxlength="100" />
                </div>
                <div dwCol="12">
                    <label class="label"># of Aircraft <i class="fa fa-info-circle"
                            title="Estimate of number of aircrafts"></i></label>
                    <input type="text" class="form-control" [(ngModel)]="flightSchoolSignupInfo.NumberOfAircraft"
                        (ngModelChange)="updateFlightSchoolInfo()" />
                </div>
                <div dwCol="12">
                    <label class="label"># of Students <i class="fa fa-info-circle"
                            title="Estimate of number of students"></i></label>
                    <input type="text" class="form-control" [(ngModel)]="flightSchoolSignupInfo.NumberOfStudents"
                        (ngModelChange)="updateFlightSchoolInfo()" />
                </div>
            </div>

        </ng-container> -->

    </div>
</div>