import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwMediaRepositoryService, DwMenuActionType, DwMetaDataListCardConfigData, DwMetaDataRoutingService, DwModalConfig, DwRoutingService, isAfterDate } from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CourseEntity, CourseListForStudentQueryEntity } from '../../../../../meta-data/app-meta-data.service';
import { CourseNavigationService } from '../../../services/course-navigation.service';

@DwComponent<DwMetaDataListCardConfigData>({
  name: 'Course Card',
  key: 'courseCard',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: false,
  parentItemName: AppMetaDataItemNames.Course,
  configData: {
    contentItemDefinitions: [{
      display: 'Main',
      contentItemKey: 'main'
    }]
  }
})
@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements DwCardBaseComponent<any, CourseEntity> {
  cardConfig?: any;
  data?: CourseListForStudentQueryEntity;
  resolvedContext?: any;
  cardClick: EventEmitter<any> = new EventEmitter<any>();

  courseImageUrl: string = '/assets/aviator-online/img/course-image-default.png';
  progressPercent = 25;
  ignoreClick: boolean;
  endorsed: boolean;
  expired: boolean;
  constructor(private dwMediaRepository: DwMediaRepositoryService,
    private courseNavigationService: CourseNavigationService,
    private dwMetaDataRoutingService: DwMetaDataRoutingService

  ) { }

  setupCard() {

    this.progressPercent = this.data?.StudentCoursePercentComplete || 0;

    this.courseImageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.data.MediaMediaPath}`;

    if (this.data?.StudentCourseIsEndorsed) {
      this.endorsed = true;
      this.progressPercent = 100;

      if (isAfterDate(this.data?.CourseEndorsementStudentExpirationDateTime)) {
        this.expired = true;
      }
    }


  }

  click() {
    if (this.ignoreClick) {
      setTimeout(() => {
        this.ignoreClick = false;
      }, 500)
      return;
    }
    if (this.expired) {
      this.viewEndorsement();
      return;
    }

    this.cardClick.emit(this.data);
  }

  resume() {
    this.ignoreClick = true;
    this.courseNavigationService.resumeCourse(this.data.CourseId);
  }

  viewEndorsement() { 
    this.ignoreClick = true;
   // this.click();
    const endorsementStudentId = this.data.CourseEndorsementStudentCourseEndorsementStudentId;

    const modalOptions: Partial<DwModalConfig> = {
      title: 'View Endorsement',
    }

    return this.dwMetaDataRoutingService.openAddEditViewModal(DwMenuActionType.view, AppMetaDataItemNames.CourseEndorsementStudent, endorsementStudentId, null, null, 'student-view', modalOptions, true)
      .subscribe(modalResult => {
      });
  }

  ngOnInit(): void {
  }

}
