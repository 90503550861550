import { AfterContentInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VideoComponentBase } from '../../../../../models/video-component.base.interface';
import { VideoEntity, VideoLibraryEntity, VideoStreamingProviderEntity } from '../../../../../../../meta-data/app-meta-data.service';
import { VideoEvent } from '../../../../../models/video-event.model';
import { VideoEventType } from '../../../../../models/video-event-type.enum';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PlayerSdk } from '@api.video/player-sdk'
import { VideoPlayerBaseComponent } from '../../../base/video-player-base.component';
import { VideoUploadService } from '../../../../../services/video-upload.service';
import { DwSecurityUserService } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-video-player-apivideo',
  template: `
  <div style="width:100%">
    <div #avplayer id="avplayer" name="avplayer" [ngStyle]="videoPlayerStyle" style="margin-top:-3px">
    </div>
  </div>
`
})
export class VideoPlayerApiVideoComponent extends VideoPlayerBaseComponent implements VideoComponentBase, AfterContentInit {
  player: PlayerSdk;

  userSeeked: boolean = false;
  lastTimeData: any;
  seekFurthestTime: boolean;
  videoStartComplete: boolean;
  aspectRatio: number = 16 / 9;

  width:number;
  videoPlayerStyle:any =  {
    width:'100%',
    // 'min-width': '400px',
    // 'min-height': '225px',
    'min-width': '350px',
    'min-height': '196px',
    'max-width': '1200px'
  }
  height: number;
  maxHeight: number;
  finalHeight: number;
  finalWidth: string;


  videoSetupComplete: boolean = false;
  videoToken: string;
  contentInitComplete: boolean;
  tokenLoaded: boolean;
  previousWidth: number;

  
  constructor(dwSecurityUserService: DwSecurityUserService, private elementRef: ElementRef, private videoUploadService: VideoUploadService) {
    super(dwSecurityUserService);
   }

  setupVideo(): void {
    super.setupVideo();
    this.setVideoPlayerStyle();

    if (this.video.IsPrivateVideo) {
      this.videoUploadService.getVideoToken(this.video.VideoStreamingProviderId, this.video.VideoKey)
        .subscribe(videoToken => {
          this.videoToken = videoToken;
          this.tokenLoaded = true;
  
          this.setupPlayer();
        });
    } else {
      this.tokenLoaded = true;
  
      this.setupPlayer();
    }
  }
  
  @HostListener('window:resize', ['$event'])
  public reset(event: any): void {
      // re-configure the grid style upon browser resize
      this.setVideoPlayerStyle();
  }

  setVideoPlayerStyle() {
    if (!this.elementRef?.nativeElement) {
      return;
    }
    this.width = this.elementRef.nativeElement?.firstChild?.clientWidth;
    const offsetTop: number = this.elementRef.nativeElement?.getBoundingClientRect()?.top || 0;

    if (!this.width) {
      
      setTimeout(() => {
        this.setVideoPlayerStyle();
        
      },50);
      return;
    }
    
    if (this.previousWidth === this.width) {
      return;
    }

    this.previousWidth = this.width;

    this.height = Math.round(this.width / this.aspectRatio)+2;

    this.maxHeight = window.innerHeight - offsetTop - 100;

    this.finalHeight = this.height;
    this.finalWidth = '100%';

    if (this.finalHeight > this.maxHeight) {
      this.finalHeight = this.maxHeight;
      const adjustedWidth = Math.round(this.finalHeight * this.aspectRatio) - 2;

      this.finalWidth = `${adjustedWidth}px`;
    }

    this.videoPlayerStyle = {
      'width' : this.finalWidth,
      'min-width': '350px',
      'min-height': '196px',
      height: `${this.finalHeight}px`
    };

    this.videoWidthChanged.emit(this.finalWidth);
  }


  ngAfterContentInit(): void {
    this.setVideoPlayerStyle();

    this.contentInitComplete = true;

    this.setupPlayer();
  }

  setupPlayer() {
    if (!this.contentInitComplete || !this.tokenLoaded) {
      return;
    }
    if (this.player) {
      return;
    }
    const videoKey = this.video?.VideoKey;
    
    if (this.videoToken) {
      this.player = new PlayerSdk("#avplayer", {
        id: videoKey,
        hideTitle: true,
        token: this.videoToken
      });
    } else {
      this.player = new PlayerSdk("#avplayer", {
        id: videoKey,
        hideTitle: true,
      });
    }

    this.videoSetupComplete = true;

    this.initVideoPlayer();
  }

  initComplete = false;

  initVideoPlayer() {

    if (this.initComplete) {

      return;
    }

    this.player.hideTitle();
    

    this.player.setTheme({
      // link: 'red',
      // linkHover: 'aqua',
      // backgroundBottom: 'red',
      // trackBackground: 'green',
      backgroundText: 'black',
      
      // text: 'yellow',
      // backgroundTop: 'blue',
      // linkActive: 'purple'
    });

    this.player.hideControls(['seekForward', 'playbackRate', 'download', 'more', 'chapters']);

    this.player.addEventListener("play", () => {
      this.onVideoEvent(VideoEventType.videoStarted);
    });

    this.player.addEventListener("pause", () => {
      this.onVideoEvent(VideoEventType.videoPaused);
    });

    this.player.addEventListener("ended", () => {
      this.onVideoEvent(VideoEventType.videoCompleted);
    });

    this.player.addEventListener("timeupdate", (eventData) => {
      const timeUpdateResult = this.updateCurrentTime(eventData.currentTime);

      if (timeUpdateResult.userSeeked) {
        this.player.setCurrentTime(this.furthestWatchedTime);
      }

      this.onVideoEvent(VideoEventType.videoProgress);
    });


    this.player.getDuration((duration) => {
      this.videoDuration = duration;
      this.initComplete = true;

      this.setupVideoStartOptions();
    });
  }


  setupVideoStartOptions() {

    if (this.videoStreamingProvider.ContinueFromLastPosition) {

      if (this.furthestWatchedTime < this.videoDuration - 3) {
        this.player.setCurrentTime(this.furthestWatchedTime);
      }
    }

    if (this.videoStreamingProvider.AutoPlay) {
      this.player.setAutoplay(true);

      //this.player.play();
    }
  }

  // onVideoEvent(eventType: VideoEventType) {


  //   const videoEvent: VideoEvent = {
  //     videoEventType: eventType,
  //     videoTime: this.lastTime,
  //     videoDuration: this.videoDuration,
  //     furtherestVideoTime: this.furthestWatchedTime,
  //     userSeeked: this.userSeeked
  //   };

  //   this.videoEvent.emit(videoEvent);
  // }
}
