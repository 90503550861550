import { Injectable, Inject } from "@angular/core";
import { DwCustomContextDefinition, DwCustomContextBase, DwSecurityUserService, DwUIMetaDataConfigToken, DwUIMetaDataConfig, DwContextDefinition, DwSecurityTopics, deepCopy, DwQueryMdAttribute, DwAttributeType, DwControlType, DevwareMetaDataService, DwExpressionAttribute, DwExpressionCompareType, DevwareMetaDataLookups, DwMenuService, DwMenuParams } from "@devwareapps/devware-cap";

@DwCustomContextDefinition({
    keyName: 'url-parameter-context',
    display: 'Current Url Parameters',
    isGlobal: true,
})
@Injectable()
export class UrlParameterContextService extends DwCustomContextBase {


    constructor(private dwMenuService: DwMenuService,
    ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {

        contextDef.noCache = true;
        contextDef.data = {};

        contextDef.getCustomContextFunction = (context: any, contextData: any) => {

            if (!this.dwMenuService.menuState?.currentParams) {
                return {};
            }

            const paramsObject = new DwMenuParams();
            Object.assign(paramsObject, this.dwMenuService.menuState.currentParams);

            const params = paramsObject.getAllParams();

            const result: any = {};

            result.Path = this.dwMenuService.menuState?.currentMenu?.path;

            if (params) {
                let index = 1;
                for (const param in params) {
                    result['Param' + index] = params[param].paramValue;

                    index++;
                }
            }

            return result;
        }

        contextDef.attributes = this.getContextAttribute();
    }

    private getContextAttribute(): DwQueryMdAttribute[] {
        const attributes: DwQueryMdAttribute[] = [];

        this.addProperty(attributes, 'Path', 'Path', DwAttributeType.Text, DwControlType.Textbox);
        this.addProperty(attributes, 'Param1', 'Param 1', DwAttributeType.Integer, DwControlType.NumericTextBox);
        this.addProperty(attributes, 'Param2', 'Param 2', DwAttributeType.Integer, DwControlType.NumericTextBox);
        this.addProperty(attributes, 'Param3', 'Param 3', DwAttributeType.Integer, DwControlType.NumericTextBox);

        return attributes;
    }


}