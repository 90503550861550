import { Inject, Injectable } from "@angular/core";
import { DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";

@DwDatasource({
    keyName: 'LessonListDataSource',
    display: 'Lesson List Data Source',
    itemName: AppMetaDataItemNames.CourseLesson,
}) 
@Injectable()
export class LessonListDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.CourseModule);
    }

    initDataSource() {

    }

    prepareRequestQuery(request: DwDataRequest): DwQuery {
        const baseQuery = super.prepareRequestQuery(request);

        const courseListQuery = AppMetaData.Queries.CourseListForStudent.CreateQueryBuilderFromQuery(baseQuery);

        const userId = this.securityUserService.securityState.securityContext?.ApplicationUser?.UserId;

        courseListQuery.addFilterAnd(f => f.GroupOr(
            g => g.Equal(a => a.StudentUserId, userId),
            g => g.IsBlank(a => a.StudentUserId, null)
        ));

        return courseListQuery.query;
    }

}