import { Inject, Injectable } from "@angular/core";
import { DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";

@DwDatasource({
    keyName: 'ModuleListDataSource',
    display: 'Module List Data Source',
    itemName: AppMetaDataItemNames.CourseModule,
})
@Injectable()
export class ModuleListDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.CourseModule);
    }

    initDataSource() {

    }

    prepareRequestQuery(request: DwDataRequest): DwQuery {
        const baseQuery = super.prepareRequestQuery(request);

        const moduleListQuery = AppMetaData.Queries.ModuleListForStudent.CreateQueryBuilderFromQuery(baseQuery);

        const userId = this.securityUserService.securityState.securityContext?.ApplicationUser?.UserId;

        moduleListQuery.addFilterAnd(f => f.GroupOr(
            g => g.Equal(a => a.StudentUserId, userId),
            g => g.IsBlank(a => a.StudentUserId, null)
        ));
        
        //moduleListQuery.addFilterAnd(f => f.Equal(a => a.StudentUserId, userId));

        return moduleListQuery.query;
    }

}