
<div class="quiz-results-inner-container">

    <div class="quiz-results-header">
        All Attempts
    </div>

    <div class="table-container quiz-table">
        <div dwRow class="row row-header">
            <div class="quiz-column-action">

            </div>
            <div class="quiz-column-date">
                Completed
            </div>
            <div class="quiz-column-result">
                Result 
            </div>
            <div class="quiz-column-score">
                Score
            </div>
            <div class="quiz-column-time">
                Time
            </div>
        </div>

        <ng-container *ngFor="let quizResult of quizResults">
            <div dwRow class="row">
                <div class="quiz-column-action">
                    <button class="btn-sm btn-primary" (click)="viewQuizAnswers(quizResult)" title="View Answers">Answers</button>
                </div>
                <div class="quiz-column-date">
                    {{quizResult.QuizStopDateTime | dwDate:{ showTime:true, showAmPm: true, isGmt:true} }}
                </div>
                <div class="quiz-column-result">
                    {{quizResult.QuizResultStatusIdDisplay}}
                </div>
                <div class="quiz-column-score">
                    {{quizResult.PercentageScore}}%

                    ({{quizResult.CorrectQuestions}} / {{quizResult.TotalQuestions}})
                </div>
                <div class="quiz-column-time">
                    {{quizResult.TotalTimeSeconds | timeDuration}}
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="quiz-results-inner-container" *ngIf="false">

    <div class="quiz-results-header">
        All Attempts
    </div>

    <div class="table-container">
        <div dwRow class="row row-header">
            <div dwCol="2" dwSizeType="xs">

            </div>
            <div dwCol="3"  dwSizeType="xs">
                Completed
            </div>
            <div dwCol="2"  dwSizeType="xs">
                Result 
            </div>
            <div dwCol="2"  dwSizeType="xs">
                Score
            </div>
            <div dwCol="1"  dwSizeType="xs">
                Time
            </div>
        </div>

        <ng-container *ngFor="let quizResult of quizResults">
            <div dwRow class="row">
                <div dwCol="2"  dwSizeType="xs">
                    <button class="btn-sm btn-primary" (click)="viewQuizAnswers(quizResult)" title="View Answers">Answers</button>
                </div>
                <div dwCol="3"  dwSizeType="xs">
                    {{quizResult.QuizStopDateTime | dwDate:{ showTime:true, showAmPm: true, isGmt:true} }}
                </div>
                <div dwCol="2"  dwSizeType="xs">
                    {{quizResult.QuizResultStatusIdDisplay}}
                </div>
                <div dwCol="2"  dwSizeType="xs">
                    {{quizResult.PercentageScore}}%

                    ({{quizResult.CorrectQuestions}} / {{quizResult.TotalQuestions}})
                </div>
                <div dwCol="1"  dwSizeType="xs">
                    {{quizResult.TotalTimeSeconds | timeDuration}}
                </div>
            </div>
        </ng-container>
    </div>
</div>

