<dw-content-manager configKey="anonymous-home">
    <h1>
        <dw-content name="title" display="Page Title" [controlType]="textboxControlType">
            <!-- Welcome to Aviator Online -->
        </dw-content>
    </h1>

    <dw-content name="detail" display="Header Details">
        
    </dw-content>

    
    <!-- <dw-login-page></dw-login-page> -->

    <dw-login></dw-login>

    
</dw-content-manager>