import { Injectable, Inject } from "@angular/core";
import { DwCustomContextDefinition, DwCustomContextBase, DwSecurityUserService, DwUIMetaDataConfigToken, DwUIMetaDataConfig, DwContextDefinition, DwSecurityTopics, deepCopy, DwQueryMdAttribute, DwAttributeType, DwControlType, DevwareMetaDataService, DwExpressionAttribute, DwExpressionCompareType, DevwareMetaDataLookups, DwMenuService, DwMenuParams, DwOrmDataService, DwQuery } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";

@DwCustomContextDefinition({
    keyName: 'manage-student-context',
    display: 'Manage Student',
    isGlobal: true,
    parentItem: AppMetaData.StudentCourse.ItemDetail.itemName,
    
})
@Injectable()
export class ManageStudentContextService extends DwCustomContextBase {


    constructor(private dwMenuService: DwMenuService,
    ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {
        contextDef.buildQueryFunction = (context) => this.buildQuery(context);

        contextDef.item = AppMetaDataItemNames.Student;
        contextDef.getCustomContextKey = (context) => {
            return this.getFlightSchoolStudentId();
        };

        contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];
    }

    buildQuery(resolvedContext: any): DwQuery {
        const query = AppMetaData.Student.CreateQueryBuilder();

        const userId = this.getUserId();
   
        if (!userId) {
            return null;
        }

        query.addFilterAnd(f => f.Equal(u => u.UserId, userId));

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        
        return query.query;
    }

    buildQueryManageStudents(resolvedContext: any): DwQuery {
        const query = AppMetaData.Student.CreateQueryBuilder();

        const flightSchoolStudentId = this.getFlightSchoolStudentId();
   
        if (!flightSchoolStudentId) {
            return null;
        }

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        
        //query.addFilterAnd(filters => filters.Equal(f => AppMetaData.FlightSchoolStudent.Attributes.FlightSchoolStudentId, flightSchoolStudentId));

        return query.query;
    }

    private getFlightSchoolStudentId(): number {
        if (!this.dwMenuService.menuState?.currentParams) {
            return null;
        }

        const paramsObject = new DwMenuParams();
        Object.assign(paramsObject, this.dwMenuService.menuState.currentParams);

        const params = paramsObject.getAllParams();
        let index = 1;
        for (const param in params) {

            return params[param].paramValue;
        }

        return null;
    }
    private getUserId(): number {
        if (!this.dwMenuService.menuState?.currentParams) {
            return null;
        }

        const paramsObject = new DwMenuParams();
        Object.assign(paramsObject, this.dwMenuService.menuState.currentParams);

        const params = paramsObject.getAllParams();
        let index = 1;
        for (const param in params) {

            return params[param].paramValue;
        }

        return null;
    }

}