import { Inject, Injectable } from "@angular/core";
import { DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { AppDevwareBootstrapService } from "src/app/app-devware-registrations.service";

@DwDatasource({
    keyName: 'AssignmentStudentDataSource',
    display: 'Assignment Student List Data Source',
    itemName: AppMetaDataItemNames.Assignment,
})
@Injectable()
export class AssignmentStudentDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.Course);
    }

    initDataSource() {

    }
 

    prepareRequestQuery(request: DwDataRequest): DwQuery {
        const baseQuery = super.prepareRequestQuery(request);

        
        const assignmentQuery = AppMetaData.Queries.AssignmentByStudent.CreateQueryBuilderFromQuery(baseQuery);
       
        //const courseListQuery = AppMetaData.Queries.AssignmentLessonWithProgressForStudent.CreateQueryBuilderFromQuery(baseQuery);

        const userId = this.securityUserService.securityState.securityContext?.ApplicationUser?.UserId;

        assignmentQuery.addPrefetch(r => r.AssignmentCourseLesson);

        assignmentQuery.addFilterAnd(f => f.Equal(a => a.StudentUserId, userId));

        assignmentQuery.addFilterAnd(f => f.LessThan(a => a.AssignmentStudentPercentComplete, 100));

        return assignmentQuery.query;
    }

}