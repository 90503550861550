import { Injectable } from "@angular/core";

import * as luxon from 'luxon';

const DateTime = luxon.DateTime;

@Injectable({ providedIn: 'root' })
export class DateTimeUtilService {

    calcDurationMinutes(startUtcDateTime: string, endUtcDateTime: string, interval?: number, minValue?: number, maxValue?: number, isUtc?: boolean) {

        var opts: luxon.DateTimeOptions = {};

        if (isUtc) {
            opts = {
                zone: 'utc'
            }
        }

    const startDateTime = DateTime.fromISO(startUtcDateTime, opts);
    const endDateTime = DateTime.fromISO(endUtcDateTime, opts);

    const duration = endDateTime.diff(startDateTime, 'minutes');

    if(interval) {
        return duration.minutes - (duration.minutes % interval);
    }

    if(minValue && interval < minValue) {
    return minValue;
}

if (maxValue && interval > maxValue) {
    return maxValue;
}

return duration.minutes;
    }

calcDurationSeconds(startUtcDateTime: string, endUtcDateTime: string, interval ?: number, minValue ?: number, maxValue ?: number, roundToNearestInterval: boolean = true) : number {
    const startDateTime = DateTime.fromISO(startUtcDateTime);
    const endDateTime = DateTime.fromISO(endUtcDateTime);

    const duration = endDateTime.diff(startDateTime, 'seconds');

    if (interval) {
        return duration.seconds - (duration.seconds % interval);
    }

    if (minValue && duration.seconds < minValue) {
        return minValue;
    }

    if (maxValue && duration.seconds > maxValue) {
        return maxValue;
    }
    if (roundToNearestInterval) {
        return Math.round(duration.seconds);
    }

    return duration.seconds;
}

formatDurationMinutesSeconds(durationSeconds: number, options?: { showSeconds?: boolean, showText?: boolean}) {
    if (!durationSeconds) {
        return '';
    }

    let seconds = durationSeconds % 60;
    const totalMinutes = ((durationSeconds - seconds) / 60);
    const minutes = totalMinutes % 60;
    const hours = (totalMinutes - minutes) / 60;

    seconds = Math.round(seconds);

    if (options?.showText) {
        const parts: string[] = [];

        if (hours>0) {
            parts.push(`${hours} hours`);
        }

        if (minutes>0 || (options?.showSeconds && seconds>0)) {
            parts.push(`${minutes} minutes`);
        }
            
        if (options?.showSeconds) {
            parts.push(`${seconds} seconds`);
        }

        if (parts.length>0) {
            return parts.join(' ');
        }

        return '';
    }

    let minutesSeconds = `${minutes}:${this.pad(seconds,2)}`;

    if (minutes < 10 && hours > 0) {
        minutesSeconds = `${this.pad(minutes,2)}:${this.pad(seconds,2)}`;
    }

    if (hours>0) {
        return `${hours}:${minutesSeconds}`;
    }

    return `${minutesSeconds}`;
}

// private pad(num: number, size = 0): string {
//     const numberString = num.toFixed(0).toString();

//     if (numberString.length < size) {
//         return '0'.repeat(size - numberString.length) + numberString;
//     }

//     return numberString;
// }

DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';

    readonly minutesConst = 60;
    readonly hoursConst = 60 * 60;
    readonly daysConst = this.hoursConst * 24;

formatDateTimeToTimezone(dateTime: string, timezone: string, format ?: string): string {
    format = format || this.DATE_TIME_FORMAT;

    const opt: luxon.DateTimeOptions = {};

    opt.setZone = true;
    opt.zone = 'UTC';

    return DateTime.fromISO(dateTime, opt).setZone(timezone).toFormat(format);
}

isToday(lastCalendarErrorDateTime: string): boolean {
    return DateTime.fromISO(lastCalendarErrorDateTime).hasSame(DateTime.local(), 'day');
}

getCurrentMonth() {
    return luxon.DateTime.local().month;
}

getCurrentYear() {
    return luxon.DateTime.local().year;
}

getCurrentDateTime(): string {
    return luxon.DateTime.utc().toISO();
}
getCurrentDateTimeLocal(): string {
    return luxon.DateTime.local().toISO();
}

convertDateTimeToJson(dateTime: string): string {
    //const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // If already in ISO format, return
    if (dateTime?.indexOf('T') > -1) {
        return dateTime;
    }

    const opt: luxon.DateTimeOptions = {};

    opt.setZone = true;
    opt.zone = 'GMT';

    const returnDateTime = DateTime.fromFormat(dateTime, this.DATE_TIME_FORMAT, opt).toJSON();

    return returnDateTime;
    //return DateTime.fromISO(dateTime, opt).toJSON();
}


formatDateTimeToLocal(dateTime: string, format ?: string): string {

    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.formatDateTimeToTimezone(dateTime, browserTimeZone, format);
}

convertDateTimeToUtc(dateTime: string): string {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const opt: luxon.DateTimeOptions = {};

    opt.setZone = true;
    opt.zone = browserTimeZone;

    return DateTime.fromISO(dateTime, opt).setZone('UTC').toISO();
}

formatDuration(timeInSeconds: number) {
    const isNegative = (timeInSeconds < 0);
    if (isNegative) {
        timeInSeconds = -timeInSeconds;
    }

    let totalSeconds = timeInSeconds;


    const seconds = (totalSeconds % 60);

    let totalMinutes = (timeInSeconds - seconds) / 60;

    const minutes = totalMinutes % this.minutesConst;

    let totalHours = (totalMinutes - minutes) / 60;

    const hours = totalHours % 24;

    let days = (totalHours - hours) / 24;

    let prefix = '';

    if (days == 1) {
        prefix = '1 day ';
    }
    if (days > 1) {
        prefix = `${days.toFixed(0)} days `
    }

    if (isNegative) {
        prefix = '-' + prefix;
    }

    if (hours > 0) {
        return `${prefix}${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;
    }

    return `${prefix}${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;
}



    private pad(num: number, size = 0, decimals = 0) {
    var numString = num.toFixed(0).toString();
    while (numString.length < size) numString = "0" + numString;
    return numString;
}


formatDuration2(timeInSeconds: number) {
    const isNegative = (timeInSeconds < 0);
    if (isNegative) {
        timeInSeconds = -timeInSeconds;
    }

    let totalSeconds = timeInSeconds;


    const seconds = (totalSeconds % 60);

    let totalMinutes = (timeInSeconds - seconds) / 60;

    const minutes = totalMinutes % this.minutesConst;

    let totalHours = (totalMinutes - minutes) / 60;

    const hours = totalHours % 24;

    let days = (totalHours - hours) / 24;

    let prefix = '';

    if (days == 1) {
        prefix = '1 day ';
    }
    if (days > 1) {
        prefix = `${days.toFixed(0)} days `
    }

    if (isNegative) {
        prefix = '-' + prefix;
    }

    let result = prefix;

    if (hours == 1) {
        result += `${this.pad(hours, 0)} hour `;
    }
    if (hours > 1) {
        result += `${this.pad(hours, 0)} hours `;
    }

    if (minutes > 0) {
        result += `${this.pad(minutes, 0)} minutes`;
    }

    return result;
}




}