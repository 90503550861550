import { Inject, Injectable } from "@angular/core";
import { DwDataPageResults, DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmData, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { AppDevwareBootstrapService } from "src/app/app-devware-registrations.service";
import { Observable, of } from "rxjs";
import { AviatorOnlineUser } from "../../shared/models/aviator-user.model";

@DwDatasource({
    keyName: 'SchoolStudentDataSource',
    display: 'School Student Data Source',
    itemName: AppMetaDataItemNames.Student,
})
@Injectable()
export class SchoolStudentDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.Course);
    }

    initDataSource() {

    }

    requestData(request: DwDataRequest): Observable<DwDataPageResults> {
        if (request.pageInfo.pageNo>1) {
            return of({ data: [], pageInfo:{ currentPageNo:2, totalPageCount: 2, totalRecordCount: 4, currentPageSize:4 }});
        }

        return super.requestData(request);
    }
 
    prepareRequestQuery(request: DwDataRequest): DwQuery {
        const baseQuery = super.prepareRequestQuery(request);
        
        const studentQuery = AppMetaData.Queries.StudentBySchool.CreateQueryBuilderFromQuery(baseQuery);

        studentQuery.query.FieldSettings.LoadAllLookupDisplayFields = true;
        const aviatorUser = new AviatorOnlineUser(this.securityUserService.securityState.securityContext?.ApplicationUser);

        studentQuery.addFilterAnd(f => f.Equal(a => a.UserDetailFlightSchoolId, aviatorUser.FlightSchoolId));

        //console.log('School student - data source', request.pageInfo);
        //studentQuery.addFilterAnd(f => f.Equal(a => a.FlightSchoolStudentFlightSchoolId, userId));
        // studentQuery.addFilterAnd(f => f.Equal(a => a.FlightSchoolStudentFlightSchoolId, userId));

        return studentQuery.query;
    }

}