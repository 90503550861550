<div class="card card-container card-item" (click)="click()" *ngIf="data">
    <div class="card-image">
        <dw-image [src]="imageUrl" [defaultSrc]="defaultImageUrl" maxWidth2="250px" maxHeight2="141px"></dw-image>
    </div>
    <div class="card-body">
        <h5 class="card-title">{{data.ModuleName}}</h5>

        <div class="card-description">
            <dw-html-view [ngModel]="data.ModuleDescription"></dw-html-view>
        </div>
        <div class="bottom-row">
            <div class="pull-left">
                {{data?.CourseModuleProgressLessonsCompleted}} of {{data?.CourseModuleProgressLessonsCount}}
                Completed
            </div>
            <!-- <div dwCol="4"></div> -->
            <div class="pull-right">
                <ng-container *ngIf="data?.CourseModuleProgressPercentComplete>=100">
                    <i class="fa fa-check-circle dw-green" title="Complete"></i>
                </ng-container>
                <ng-container
                    *ngIf="data?.CourseModuleProgressTotalTimeSeconds > 0 && data?.CourseModuleProgressPercentComplete < 100">
                    {{data?.CourseModuleProgressPercentComplete}}% Complete
                </ng-container>
                <ng-container *ngIf="data?.CourseModuleProgressTotalTimeSeconds ==0">
                    <span class="hide-mobile">Not Started</span>
                    <span class="show-mobile">0%</span>
                </ng-container>
            </div>
        </div>

        <div cardRight class="card-additional-data" class="pull-right" *ngIf="false">

            <div dwRow *ngIf="data?.CourseModuleProgressPercentComplete>=100">
                <div dwCol="12">
                    <i class="fa fa-check-circle dw-green" title="Complete"></i>
                </div>
            </div>
            <div dwRow
                *ngIf="data?.CourseModuleProgressTotalTimeSeconds > 0 && data?.CourseModuleProgressPercentComplete < 100">
                <div dwCol="12">
                    {{data?.CourseModuleProgressPercentComplete}}% Complete
                </div>
            </div>
            <div dwRow *ngIf="data?.CourseModuleProgressTotalTimeSeconds ==0">
                <div dwCol="12">
                    <span class="hide-mobile">Not Started</span>
                    <span class="show-mobile">0%</span>
                </div>
            </div>
        </div>
    </div>
</div>