import { Component, OnInit } from '@angular/core';
import { DwRadioItem } from '../quiz-answer-true-false/quiz-answer-true-false.component';
import { QuizAnswerBaseComponent } from '../base/quiz-answer-base.component';
import { flattenArray, unflattenArray } from '@devwareapps/devware-cap';
import { QuestionAnswerEntity } from '../../../../../meta-data/app-meta-data.service';
import { ArrayUtilService } from '../../../../shared/util/array-util.service';
import { QuestionAnswerReview, QuestionAnswerReviews } from '../../../models/question-answer-review.model';
import { QuizUtilService } from '../../../services/quiz-util.service';

@Component({
  selector: 'app-quiz-answer-multiple-answer',
  template: `
  
    <div *ngFor="let answer of answersToShow; let i = index" id="check-item" class="check-item">
      <div class="check-label">
        <label>
          <input type="checkbox" class="check-input" [disabled]="reviewAnswers" [ngModel]="checkValues[answer.QuestionAnswerId]" (ngModelChange)="setAnswer($event, answer)" />
          <div class="check-item-text"> {{answer.AnswerText}}</div>
        </label>
      </div> 
      
      <div *ngIf="answerReviews[i] && reviewAnswers" class="answer-review">
        <i class="answer-icon" [ngClass]="answerReviews[i].iconClass"></i>
        <span class="answer-note">{{answerReviews[i].textNote}}</span>
        <span class="answer-explanation" [ngClass]="answerReviews[i].explanationTextClass">{{answerReviews[i].explanationText}}</span>
      </div>
    </div>
    
    
   <!-- Answer Reviews: {{answerReviews | json }}
    {{checkValues | json }}<br>
    Answer Value: {{questionAnswerValue | json }} 
    Answers to Show: {{answersToShow | json }} -->
  `
})
export class QuizAnswerMultipleAnswerComponent extends QuizAnswerBaseComponent {
  options: DwRadioItem[] = [];
  checkValues: { [prop: string]: number } = {};

  answersToShow: QuestionAnswerEntity[] = [];
 
  answerReviews: QuestionAnswerReviews = {};

  selectedOption: any;

  constructor(private arrayUtilService: ArrayUtilService, private quizUtilService: QuizUtilService) {
    super();
  }

  ngOnInit(): void {

  }

  setAnswer(value, answer: QuestionAnswerEntity) {
    if (value) {
      this.checkValues[answer.QuestionAnswerId] = answer.QuestionAnswerId;
    } else {
      delete this.checkValues[answer.QuestionAnswerId];
    }

    this.questionAnswerValue.quizQuestionAnswerIds = unflattenArray(this.checkValues);

    this.setAnswerValue(this.questionAnswerValue, this.questionAnswerValue.quizQuestionAnswerIds.length>0);
  }

  setupAnswer() {
    this.questionAnswerValue.quizQuestionAnswerIds = this.questionAnswerValue.quizQuestionAnswerIds || [];

    this.checkValues = flattenArray(this.questionAnswerValue.quizQuestionAnswerIds);

    // Set answers to use default order
    this.answersToShow = [...this.question.QuestionAnswer];

    if (this.questionAnswerValue.answerOrder) {
      this.answersToShow = this.answersToShow.sort((a, b) => {
        return this.questionAnswerValue.answerOrder.indexOf(a.QuestionAnswerId) - this.questionAnswerValue.answerOrder.indexOf(b.QuestionAnswerId);
      });

      if (this.reviewAnswers) {
        this.setupAnswerReviews();
      }
      return;
    }

    // Setup new order
    if (this.question.UseRandomOrder) {
      this.answersToShow = this.arrayUtilService.shuffle(this.answersToShow);
    }

    // Set the answer order so when we view it later, it will be in the same order
    this.questionAnswerValue.answerOrder = this.answersToShow.map((answer) => answer.QuestionAnswerId);
  }

  setupAnswerReviews() {
    this.answerReviews = this.quizUtilService.getAnswerReviews(this.questionLink, this.questionResult, this.questionAnswerValue, this.answersToShow);

  }
}