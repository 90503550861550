import { Inject, Injectable } from "@angular/core";
import { DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";

@DwDatasource({
    keyName: 'ModuleProgressListDataSource',
    display: 'Module Progress List Data Source',
    itemName: AppMetaDataItemNames.CourseModuleProgress,
})
@Injectable() 
export class ModuleProgressListDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.CourseModuleProgress);
    }

    initDataSource() {

    }

    prepareRequestQuery(request: DwDataRequest): DwQuery {
        const baseQuery = super.prepareRequestQuery(request);

        const moduleListQuery = AppMetaData.Queries.StudentModuleProgress.CreateQueryBuilderFromQuery(baseQuery);

        moduleListQuery.addOrderBy(a => a.CourseModuleModuleOrder);

        return moduleListQuery.query;
    }

}