import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DwSecurityUserService, DwSecurityRepositoryServiceToken, DwSecurityRepositoryService, DwUser } from '@devwareapps/devware-cap';
import { AviatorOnlineUserTypeIds } from '../../../shared/models/aviator-user-type.enum';
import { UserTypeAllItems } from '../../../../meta-data/app-meta-data.service';
import { AviatorOnlineUser } from '../../../shared/models/aviator-user.model';
import { AppPermissions } from '../../../../meta-data/app-permissions.enum';

@Component({
  selector: 'app-authenticated-home-view',
  templateUrl: './authenticated-home-view.component.html',
  styleUrls: ['./authenticated-home-view.component.scss']
})
export class AuthenticatedHomeViewComponent implements OnInit {
  aviatorUser: AviatorOnlineUser;
  dashboardUserTypeId?: number = UserTypeAllItems.Student; // Default to student dashboard
  dashboardName?: string;
  readonly UserTypeAllItems = UserTypeAllItems;
  
  showDashboardSelect: boolean = false;
  canChangeDashbaord: boolean = false;

  constructor(private router:Router,
    private dwSecurityUserService: DwSecurityUserService,
    @Inject(DwSecurityRepositoryServiceToken) private dwSecurityRepositoryService: DwSecurityRepositoryService
  
    ) { }

  ngOnInit(): void {
    this.dwSecurityUserService.securityContextChanged().subscribe(change => {
      this.setupSecurity();
    });
      
    this.setupSecurity();
  }


  changeDashboardType(userTypeId: string) {
    if (!this.canChangeDashbaord) {
      return;
    }

    this.dashboardUserTypeId = parseInt(userTypeId);
    this.setDashbaordName();

    this.showDashboardSelect = false;
  }

  toggleChangeDashboard() {
    if (!this.canChangeDashbaord) {
      return;
    }

    this.showDashboardSelect = !this.showDashboardSelect;
  }
  
  private setupSecurity() {
    if (!this.dwSecurityUserService.securityState.isAuthenticated || this.dwSecurityUserService.securityState.isAuthenticating) {
      return;
    }

    const user = this.dwSecurityUserService.securityContext.ApplicationUser;
    this.aviatorUser = new AviatorOnlineUser(user);

    this.dashboardUserTypeId = AviatorOnlineUserTypeIds.student;
    
    if (this.dwSecurityUserService.hasPermission(AppPermissions.flightSchoolAccess)) {
      this.dashboardUserTypeId = AviatorOnlineUserTypeIds.flightSchool;
      this.canChangeDashbaord = true;
    }

    if (this.dwSecurityUserService.hasPermission(AppPermissions.instructorAccess)) {
      this.dashboardUserTypeId = AviatorOnlineUserTypeIds.flightInstructor;
    }

    this.setDashbaordName();
  }

  private setDashbaordName() {
    switch(this.dashboardUserTypeId) {
      case AviatorOnlineUserTypeIds.student:
        this.dashboardName = 'Student Dashboard';
        break;
      case AviatorOnlineUserTypeIds.flightInstructor:
        this.dashboardName = 'Instructor Dashboard';
        break;
      case AviatorOnlineUserTypeIds.flightSchool:
        this.dashboardName = 'School Dashboard';
        break;
    }
  }
}
 