<b>Linking {{modalConfig?.data?.length || 0}} Questions</b>

<dw-message [message]="message"></dw-message>


<div dwRow>
    <div dwCol="12">
        <label>Create new Quiz</label>

        <dw-yesno [(ngModel)]="isNewQuiz">
        </dw-yesno>
    </div>
</div>

<div dwRow *ngIf="!isNewQuiz">
    <div dwCol="12">
        <label>Quiz</label>

        <dw-select-lookup [lookupId]="quizLookupId" [(ngModel)]="quizId" showBlankOption="false"></dw-select-lookup>
    </div>
</div>

<div dwRow *ngIf="isNewQuiz">
    <div dwCol="12">
        <label>Quiz Name</label>

        <input type="text" [(ngModel)]="quizName" class="form-control" />
    </div>
</div>

<div dwRow>
    <div dwCol="12">
        <label>Open Quiz After update</label>

        <dw-yesno [(ngModel)]="openQuizAfter">
        </dw-yesno>
    </div>
</div>