<ng-container *ngIf="quizResult && quiz">
    <ng-container *ngIf="view == 'quizAnswers'">
        <div class="quiz-answers-container">
            <app-quiz [quiz]="quiz" [quizResult]="quizResult" [reviewAnswers]="true" [startingQuizResultQuestionId]="startingQuizResultQuestionId" (quizComplete)="quizComplete($event)"></app-quiz>
        </div>
    </ng-container>

    <div *ngIf="view == 'lastResult'" class="quiz-home-result-container">
        <div class="quiz-result-container">
            <div>
                <app-quiz-result [quiz]="quiz" [quizResult]="quizResult" [showRetake]="false"  (quizResultAction)="quizResultAction($event)"></app-quiz-result>
            </div>
        </div>
        <div class="dw-button-bar">
           
            <div class="pull-right">
                <button class="btn btn-danger" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</ng-container>