import { Component, OnInit } from '@angular/core';
import { CourseRepositoryService } from '../../../courses/services/course-repoistory.service';
import { StudentCourseEntity } from '../../../../meta-data/app-meta-data.service';
import { CourseProgressItem } from '../../../courses/models/course-progress-hierarchy.model';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss']
})
export class StudentDashboardComponent implements OnInit {
  studentCourses: StudentCourseEntity[];
  currentStudentCourse: StudentCourseEntity;
  currentCourseProgress: CourseProgressItem; 

  courseProgressFilters: any = {};
  
  constructor(protected courseRepositoryService: CourseRepositoryService) { }

  ngOnInit(): void {
    this.setupDashboard();
  }

  setupDashboard() {
    
    this.courseRepositoryService.getCurrentStudentCourses()
      .subscribe((studentCourses) => {
        this.studentCourses = studentCourses; 

        this.setupCurrentCourse();
         
      })
  }  

  setupCurrentCourse() {
    if (!this.studentCourses || this.studentCourses.length === 0) {
      return;
    }

    this.currentStudentCourse = this.studentCourses.find((studentCourse) => !studentCourse.CourseEndorsementStudent?.CourseEndorsementId);

    if (!this.currentStudentCourse) {
      return;
    }

    this.courseRepositoryService.getCourseProgress(this.currentStudentCourse.CourseId)
      .subscribe((courseProgress) => {
        this.currentCourseProgress = courseProgress;

        //this.
        //this.currentCourseProgress.course.CourseName

        this.courseProgressFilters = {
          CourseLessonId : this.currentCourseProgress.furtherestLesson?.CourseLessonId || this.currentCourseProgress.modules[0].lessons[0].lesson.CourseLessonId
        }
      }
    );
  }
}
