import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { QuestionEntity, QuizQuestionLinkEntity, QuizResultQuestionEntity } from "../../../../../meta-data/app-meta-data.service";
import { QuestionAnswerValue } from "../../../models/question-answer-value.model";



@Directive({ selector: 'app-quiz-answer-base' })
export class QuizAnswerBaseComponent {
    @Input() questionLink: QuizQuestionLinkEntity;
    @Input() question: QuestionEntity;
    @Input() questionResult: QuizResultQuestionEntity;
    @Input() reviewAnswers: boolean = false;
    @Output() questionResultChange = new EventEmitter<QuizResultQuestionEntity>();

    questionAnswerValue: QuestionAnswerValue;

    setAnswerValue(answerValue: QuestionAnswerValue, isAnswered: boolean) {
        // Ignore answer updates when reviewing answers
        if (this.reviewAnswers) {
            return;
        }
        if (!answerValue || !this.questionResult) {
            return;
        }

        this.questionResult.IsAnswered = isAnswered;
        this.questionResult.AnswerValue = JSON.stringify(answerValue);
        this.questionResultChange.emit(this.questionResult);
    }

    initAnswer() {
        this.question = this.questionLink?.Question;

        let questionAnswerValue = {};

        if (this.questionResult?.AnswerValue) {
            try {
                questionAnswerValue = JSON.parse(this.questionResult.AnswerValue);
            } catch (e) {
                console.error(`Couldn't parse Answer value for question ${this.questionLink.Question.QuestionId}`);
            }
        } 

        this.questionAnswerValue = questionAnswerValue;

        this.setupAnswer();
    }

    setupAnswer() {
        // Setup method to be overridden by derived classes
    }
}