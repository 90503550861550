
<dw-loading [show]="isLoading" text="Building Quiz"></dw-loading>

<ng-container *ngIf="message">
    <dw-message [(message)]="message"></dw-message>
    <br>
    <button class="btn btn-danger" (click)="cancel()">Close</button>
</ng-container>

<div class="quiz-container unselectable" *ngIf="showQuiz">
    <div class="quiz-header">
        <div class="left-side">
            <div class="pull-left">Question {{currentQuestionIndex + 1}} of {{totalQuestionCount}}</div>

            <div class="answer-review" *ngIf="reviewAnswers">
                <i class="answer-icon" [ngClass]="questionResultIconClass"></i>
                <div class="answer-note">{{questionResultTextNote}}</div>
            </div>
        </div>
        <div class="center">
        </div>
        <div class="right-side">
            <i class="fa fa-times close-icon" (click)="cancel()" title="Close Quiz"></i>

            <div class="right-text" *ngIf="reviewAnswers">Quiz Review</div>
        </div>
    </div>
    <div class="question-container">
        <div class="right-side time-section">
            <ng-container *ngIf="!reviewAnswers">
                Time Remaining {{timeString}}
            </ng-container>
            <ng-container *ngIf="reviewAnswers">
                Time Taken {{timeTakingString}}
            </ng-container>
        </div>
        <app-quiz-question [questionLink]="currentQuestionLink" [reviewAnswers]="reviewAnswers" [(questionResult)]="currentQuestionResult"></app-quiz-question>
    </div>

    <div class="quiz-navigation">
        <div class="left-side">
            <button class="btn btn-primary" (click)="previousQuestion()" *ngIf="hasPreviousQuestion">Previous</button>
        </div>
        <div class="center">
            <div flagLesson class="pull-left" *ngIf="lesson?.LessonTypeId == videoLessonType && flagEnabled">
                <i class="fa fa-flag fa-2x hand" title="Flag Question" (click)="flagLesson()"></i>

                <span *ngIf="flagSubmitted"> Submitted</span>
            </div>
        </div>
        <div class="right-side">
            <button class="btn btn-primary" (click)="nextQuestion()" *ngIf="hasNextQuestion" [disabled]="!currentQuestionResult.IsAnswered">Next</button>
            <button class="btn btn-primary" (click)="submitQuiz()" *ngIf="!hasNextQuestion && !reviewAnswers" [disabled]="!currentQuestionResult.IsAnswered">Submit Quiz</button>
            <button class="btn btn-danger" (click)="cancel()" *ngIf="!hasNextQuestion && reviewAnswers">Close</button>
        </div>
    </div>
</div> 