import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwDataFormatService, DwFormatTypes, DwMetaDataListCardConfigData, DwQueryMetaData, DwRoutingService } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, AssignmentByStudentQueryEntity, AssignmentEntity, AssignmentLessonWithProgressForStudentQueryEntity, LessonTypeAllItems, QuizTypeAllItems } from '../../../../../meta-data/app-meta-data.service';
import { CourseNavigationService } from '../../../../courses/services/course-navigation.service';
import { LessonReferenceInfo } from '../../../../courses/models/lesson-reference-info.model';
import { DateTimeUtilService } from '../../../../shared/util/date-time-util.service';


@DwComponent<DwMetaDataListCardConfigData>({
  name: 'Assignment Card',
  key: 'assignmentCard',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: true,
  parentItemName: AppMetaDataItemNames.AssignmentCourseLesson,
  configData: {
    contentItemDefinitions: [{
      display: 'Main',
      contentItemKey: 'main'
    }]
  }
}) 
@Component({
  selector: 'app-assignment-card',
  templateUrl: './assignment-card.component.html',
  styleUrls: ['./assignment-card.component.scss']
})
export class AssignmentCardComponent implements DwCardBaseComponent<any, AssignmentLessonWithProgressForStudentQueryEntity> {
  cardConfig?: any; 
  data?: AssignmentByStudentQueryEntity;
  resolvedContext?: any;
  cardClick: EventEmitter<any> = new EventEmitter<any>();
  dueDateTime: string;
  percentCompleteText: string;
  lessonCount: number;
  dueClass:string;
  dueInMinutes: number;
  currentDate: string;
  currentDateFormatted: string;
  
  constructor(private dwDataFormatService: DwDataFormatService,
    private dwRoutingService: DwRoutingService,
    private courseNavigationService: CourseNavigationService,
    private dateTimeUtilService: DateTimeUtilService
  ) { }
  queryMetaData?: DwQueryMetaData;

  setupCard() {
    this.dueDateTime = this.dwDataFormatService.formatValue(this.data?.DueDateTime, { formatType: DwFormatTypes.datetime, useGmtDateTime: true});
   
    this.lessonCount = this.data?.AssignmentCourseLesson?.length || 1;

    let currentDate = this.dateTimeUtilService.getCurrentDateTime();
    this.currentDateFormatted = this.dwDataFormatService.formatValue(currentDate, { formatType: DwFormatTypes.datetime, useGmtDateTime: true});

    this.dueInMinutes = this.dateTimeUtilService.calcDurationMinutes(currentDate, this.data?.DueDateTime, null, null,null,true);

    if (this.dueInMinutes<0) {
      this.dueClass = 'dw-red';
    }
    if (this.dueInMinutes>=0 && this.dueInMinutes < 60 * 24) {
      this.dueClass = 'dw-orange';
    }

    // this.data?.CourseLessonProgressLessonComplete
    // this.data?.CourseLessonProgressPercentComplete
    // this.data?.CourseLessonLessonTypeId == LessonTypeAllItems.Quiz
  }

  quizLessonTypeId = LessonTypeAllItems.Quiz
  click() {
    const url = `/my-assignments/view/${this.data?.AssignmentStudentAssignmentStudentId}`;

    this.dwRoutingService.navigateToUrl(url, null);

    // const lessonReference : LessonReferenceInfo = {
    //   courseId: this.data.AssignmentCourseId,
    //   moduleId: this.data.CourseModuleCourseModuleId,
    //   lessonId: this.data.CourseLessonId
    // }
    // this.courseNavigationService.navigateToLesson(lessonReference);

  }

}
