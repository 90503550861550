<div class="card card-container card-item hand" (click)="click()">
    <div class="card-image">
        <!-- <img [src]="courseImageUrl" class="card-img-top" style="width:100%"> -->
        <div class="card-icon">
            <i class="fa fa-chalkboard-teacher fa-2x"></i>
        </div>
    </div>
    <div class="card-body">
        <h5 class="card-title">{{data?.CourseIdDisplay}}</h5>
        <div>{{lessonCount}} Lessons</div>
        <div [ngClass]="dueClass">Due: {{dueDateTime}}</div>
    </div>
    <div class="card-right">
        <!-- <div>{{lessonCount}} Lessons</div> -->
        <div>{{ data?.AssignmentStudentPercentComplete || 0 }}%</div>
    </div>
</div>
