import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwMediaRepositoryService, DwMetaDataListCardConfigData, DwRoutingService } from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CourseEntity, CourseModuleEntity, ModuleListForStudentQueryEntity } from '../../../../../meta-data/app-meta-data.service';

@DwComponent<DwMetaDataListCardConfigData>({
  name: 'Module Card',
  key: 'moduleCard',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: false,
  parentItemName: AppMetaDataItemNames.CourseModule,
  configData: {
    contentItemDefinitions: [{
      display: 'Main',
      contentItemKey: 'main'
    }]
  }
})
@Component({
  selector: 'app-module-card',
  templateUrl: './module-card.component.html',
  styleUrls: ['./module-card.component.scss']
})
export class ModuleCardComponent implements DwCardBaseComponent<any, CourseEntity> {

  cardConfig?: any;
  @Input() data?: ModuleListForStudentQueryEntity;
  resolvedContext?: any;
  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>();

  imageUrl: string  = ''; //= '/assets/aviator-online/img/module-image-default.png';

  defaultImageUrl: string = '/assets/aviator-online/img/module-image-default.png';
  progressPercent = 0;
  constructor(private dwMediaRepository: DwMediaRepositoryService,
    private dwRoutingService: DwRoutingService,

  ) { }

  

  setupCard() {
    this.progressPercent = this.data?.CourseModuleProgressPercentComplete || 0;
  
    if (this.data?.MediaMediaPath) {
      this.imageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.data.MediaMediaPath}`;
    } else {
      this.imageUrl = `${this.dwMediaRepository.mediaApi}/download/${this.data.CourseImageMediaPath}`;
    }
  }
 
  click() {
    const url = `/my-courses/view/${this.data.CourseId}/module/${this.data.CourseModuleId}`;

    this.dwRoutingService.navigateToUrl(url, null);
    //this.cardClick.emit(this.data);
  }
  
  ngOnInit(): void {
  }
}
