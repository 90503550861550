import { Inject, Injectable } from "@angular/core";
import { DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { AppDevwareBootstrapService } from "src/app/app-devware-registrations.service";

@DwDatasource({
    keyName: 'InstructorStudentDataSource',
    display: 'Instructor Student List Data Source',
    itemName: AppMetaDataItemNames.Student,
})
@Injectable()
export class InstructorStudentDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.Course);
    }

    initDataSource() {

    }
 
    prepareRequestQuery(request: DwDataRequest): DwQuery {
        const baseQuery = super.prepareRequestQuery(request);
        
        const studentQuery = AppMetaData.Queries.StudentByInstructor.CreateQueryBuilderFromQuery(baseQuery);

        studentQuery.query.FieldSettings.LoadAllLookupDisplayFields = true;
        const userId = this.securityUserService.securityState.securityContext?.ApplicationUser?.UserId;

        studentQuery.addFilterAnd(f => f.Equal(a => a.InstructorUserId, userId));

        return studentQuery.query;
    }

}