import { Component, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwSecurityUserRegistration, DwUserRegistrationExtensionBase, DwUserTypeEntity, getParameterByName } from '@devwareapps/devware-cap';
import { AviatorOnlineHomePageRepository } from '../../services/home-page-repository.service';
import { DwUserTypeExtensionEntity } from '../../../../meta-data/extensions/dw-user-type-entity.model';
import { AviatorOnlineUserTypeIds, convertUserType } from '../../../shared/models/aviator-user-type.enum';
import { Router } from '@angular/router';
import { FlightSchoolSignup } from '../../models/flight-school-signup.model';
import { AppMetaData, FlightSchoolEntity } from '../../../../meta-data/app-meta-data.service';

@DwComponent({
  key: 'aviator-online-user-registration-extension',
  name: 'Aviator Online User Registration Component',
  isGlobal: false,
  componentType: DwComponentType.pageComponent
})
@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
}) 
export class UserRegistrationComponent extends DwUserRegistrationExtensionBase {
   
  userTypes: DwUserTypeEntity[];
  userType: AviatorOnlineUserTypeIds;

  schoolUserType: AviatorOnlineUserTypeIds = AviatorOnlineUserTypeIds.flightSchool;
  flightSchoolSignupInfo: FlightSchoolEntity;

  constructor(private router: Router,
    private homePageRepository: AviatorOnlineHomePageRepository) {
    super();
   }

  setupComponent() {
    this.flightSchoolSignupInfo = {
      _itemName: AppMetaData.FlightSchool.ItemDetail.itemName,

    };

    const userTypeMeaning = getParameterByName(this.router, 'type');
    
    if (!isNaN(parseInt(userTypeMeaning))) {
      this.userType = parseInt(userTypeMeaning);
    } else {
      this.userType = convertUserType(userTypeMeaning);
    }

    if (!this.userType) {
      this.userType = AviatorOnlineUserTypeIds.student;
    }

    this.updateUserType();

    this.homePageRepository.getUserTypes().subscribe(userTypes => {
      this.userTypes = userTypes;

      if (!this.userTypes.find(ut => ut.UserTypeId === this.userType)) {
        this.userType = this.userTypes[0].UserTypeId;
      }
    });
  }

  customValalidation(userRegistration: DwSecurityUserRegistration, error: string[]) {
    if (!this.userType) {
      error.push('User type is required');
    }

    switch(this.userType) {
      case AviatorOnlineUserTypeIds.flightSchool:
        if (!this.flightSchoolSignupInfo?.FlightSchoolName) {
          error.push('School name is required');
        }
        if (!this.flightSchoolSignupInfo?.Location) {
          error.push('Location is required');
        }
    }

  }

  updateUserType() {
    this.data.additionalUserFields[0] = {
      FieldName: 'UserTypeId',
      FieldValue: this.userType.toString()
    };

    this.dataChange.emit(this.data);
  }

  updateFlightSchoolInfo() {
    this.data.additionalUserFields[1] = {
      FieldName: 'FlightSchoolSignupInfo',
      FieldValue: JSON.stringify(this.flightSchoolSignupInfo)
    };
  }

}
