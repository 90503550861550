import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LessonNavigationInfo } from '../../models/lesson-navigation-info.model';
import { CourseNavigationService } from '../../services/course-navigation.service';
import { DwInputConverter, booleanConverter } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-lesson-navigation',
  template: `
  
    <div class="lesson-nav-container" [ngStyle]="navigationStyle" *ngIf="false">
      <div class="lesson-details-center">
        <button class="btn btn-light" (click)="previous()" *ngIf="lessonNavigationInfo?.previousLesson && !lessonNavigationInfo.isFirstLesson">Previous</button>
        <button class="btn btn-light" (click)="previous()" *ngIf="lessonNavigationInfo?.previousLesson && lessonNavigationInfo.isFirstLesson" >Previous</button>
        <ng-content select="[lessonDetails]"></ng-content>
        <button class="btn btn-light" (click)="next()" *ngIf="lessonNavigationInfo?.nextLesson && !disableNext && !lessonNavigationInfo.isLastLesson">Next</button>
        <button class="btn btn-light" (click)="next()" *ngIf="lessonNavigationInfo?.nextLesson && !disableNext && lessonNavigationInfo.isLastLesson">Next</button>
      </div>
    </div>
    <div class="lesson-nav-container" [ngStyle]="navigationStyle" *ngIf="true">
      <div class="lesson-previous">
        <button class="btn btn-light" (click)="previous()" *ngIf="lessonNavigationInfo?.previousLesson && !lessonNavigationInfo.isFirstLesson">Previous</button>
        <button class="btn btn-light" (click)="previous()" *ngIf="lessonNavigationInfo?.previousLesson && lessonNavigationInfo.isFirstLesson" >Previous</button>
      </div>
      <div class="lesson-details">
          <ng-content select="[lessonDetails]"></ng-content>
      </div>
      <div class="lesson-next">
        <button class="btn btn-light" (click)="next()" *ngIf="lessonNavigationInfo?.nextLesson && !disableNext && !lessonNavigationInfo.isLastLesson">Next</button>
        <button class="btn btn-light" (click)="next()" *ngIf="lessonNavigationInfo?.nextLesson && !disableNext && lessonNavigationInfo.isLastLesson">Next</button>
      </div>
    </div>

  <!--
    <button class="btn btn-primary">Primary</button><button class="btn btn-success">Success</button><button class="btn btn-warning">Warning</button><button class="btn btn-danger">Danger</button><button class="btn btn-info">Info</button>

    <button class="btn btn-light">Light</button><button class="btn btn-dark">Dark</button>-->
  `
})
export class LessonNavigationComponent implements OnInit, OnChanges {
  @Input() lessonNavigationInfo: LessonNavigationInfo;

  @DwInputConverter(booleanConverter)
  @Input() disableNext: boolean = false;
  width: any;
  height: number;
  finalWidth: string;
  navigationStyle: any;

  @Input() videoWidth: string;

  constructor(private courseNavigationService: CourseNavigationService,
    private elementRef: ElementRef
  ) { }


  ngOnInit(): void {
    this.setNavigationStyle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setNavigationStyle();
  }

  previous() {
    this.courseNavigationService.navigateToLesson(this.lessonNavigationInfo.previousLesson);
  }

  next() {
    if (this.lessonNavigationInfo.isLastLesson) {
      this.courseNavigationService.navigateToModule(this.lessonNavigationInfo.course?.CourseId, this.lessonNavigationInfo.nextLesson?.moduleId)
      return;
    }

    this.courseNavigationService.navigateToLesson(this.lessonNavigationInfo.nextLesson);
  }


  @HostListener('window:resize', ['$event'])
  public reset(event: any): void {
    // re-configure the grid style upon browser resize
    this.setNavigationStyle();
  }

  aspectRatio: number = 16 / 9;

  setNavigationStyle() {

    if (this.videoWidth) {
      this.navigationStyle = {
        'width': this.videoWidth,
      };
    } else {
      this.navigationStyle = {
        'max-width': '1200px'
      };
    }

    // this.width = this.elementRef.nativeElement?.firstChild?.clientWidth;

    // if (this.width === 0) {
    //   setTimeout(() => {
    //     this.setNavigationStyle();
    //   }, 100);
    //   return;
    // }

    // const offsetTop: number = this.elementRef.nativeElement?.getBoundingClientRect()?.top || 0;

    // this.height = Math.round(this.width / this.aspectRatio)+2;

    // let maxHeight = window.innerHeight - offsetTop - 100;

    // let finalHeight = this.height;
    // this.finalWidth = '100%';

    // if (finalHeight > maxHeight) {
    //   finalHeight = maxHeight;
    //   const adjustedWidth = Math.round(finalHeight * this.aspectRatio) - 2;

    //   this.finalWidth = `${adjustedWidth}px`;
    // }

    // this.navigationStyle = {
    //   'width' : this.finalWidth,
    //   // 'min-width': '400px',
    //   // 'min-height': '227px',
    //   // height: `${this.finalHeight}px`
    // };
  }

}
