import { Injectable } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentLessonSessonGridQueryEntity, StudentModuleLessonActivityQueryEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { QuizResultsModalComponent } from "../components/quiz-results-modal/quiz-results-modal.component";
import { QuizResultModalConfig } from "../models/quiz-result-modal-config.model";


@DwAction({
    keyName: 'show-quiz-modal-action',
    display: 'Show Quiz Result Modal',
    actionType: DwActionType.gridRowAction,
    parentItemName: [AppMetaDataItemNames.CourseModuleProgress, AppMetaDataItemNames.AssignmentStudent],
    isGlobal: true,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Quiz Details',
        iconClass: 'fa fa-question-circle',
        buttonClass: 'btn-sm btn-warning',
        buttonTitle: 'View Quiz Result'
    },
    defaultSecurityConfig: {
        requireAuthentication: true, 
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    }
})
@Injectable()
export class ShowQuizResultModalActionService implements DwActionHandler {
    constructor(
        /*  @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
         private route: ActivatedRoute,
         private metaDataRoutingService: DwMetaDataRoutingService, */
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService
    ) { }


    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {
        return this.showModal(actionState);
    }

    private showModal(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {
        let modalConfig = new DwModalConfig<QuizResultModalConfig>();

        modalConfig.buttons = []; // [DwModalButtonStandardConfigs.instance.closeButton]
        modalConfig.allowFullScreen = true;

        modalConfig.component = QuizResultsModalComponent;

        modalConfig.title = 'Quiz Results';
        modalConfig.data = this.getModalConfig(actionState.data);

        return this.dwModalService.showModal(modalConfig)
            .pipe(map(modalResult => {
                const result = new DwGridActionResult();

                if (result.cancelled) {
                    result.refreshData = false;
                    result.cancelled = true;

                    return result;
                }

                result.refreshData = true;

                return result;
            }));

            AppMetaData.Queries.AssignmentStudentLessonSessonGrid
    }

    private getModalConfig(data?: StudentModuleLessonActivityQueryEntity | AssignmentStudentLessonSessonGridQueryEntity | any): QuizResultModalConfig {
        return {
            quizId: data.QuizResultQuizId || data.QuizId,
            quizResultId: data.QuizResultQuizResultId || data.QuizResultId,
            quizResultQuestionId: data.QuizResultQuestionId
        };
    }

}