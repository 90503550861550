<app-dw-card-designer [data]="data" [cardDesignerConfig]="cardDesignerConfig" [queryMetaData]="queryMetaData"
    (cardClick)="click()" (cardItemResultChange)="setCardItemResult($event)">

    <span cardTitle>
        {{title}}
       <span class="dw-red" *ngIf="expired">- Expired</span>
       <span *ngIf="endorsed">- Endorsed</span>
    </span>
    <div cardDesc style2="width:400px;">
        {{progressPercent}}% Complete
        <div class="hide-mobile">
            <p-progressBar [value]="progressPercent" [showValue]="false"></p-progressBar>
        </div>
    </div>

    <div cardRight class="card-additional-data">
        <ng-container *ngIf="data?.StudentCourseTotalTimeSeconds>0">
            <div dwRow>
                <div dwCol="12">
                    {{cardItemResult?.additionalFields?.started }}
                </div>
            </div>
            <div dwRow>
                <div dwCol="12">
                    {{cardItemResult?.additionalFields?.lastActivity}}
                </div>
            </div>
            <div dwRow>
                <div dwCol="12">
                    {{cardItemResult?.additionalFields?.timeSpent}}
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="data?.StudentCourseTotalTimeSeconds==0">
            <div dwRow>
                <div dwCol="12">
                    Not Started
                </div>
            </div>
        </ng-container>
    </div>
</app-dw-card-designer>
