import { Injectable } from '@angular/core';
import { DwUiConfigRegistryService, DwUiConfigInitDecorator, DwUiGridStyleReference,
    DwUiRegistryType, DwUiContainerStyleReference } from '@devwareapps/devware-cap';

@Injectable()
  export class AppDevwareBootstrapService {
     constructor(private uiConfigRegistyService: DwUiConfigRegistryService) {}

    @DwUiConfigInitDecorator({
        name: 'DwUiMetadataRegistrationService',
        useDI: true,
      })
      setupRegistrations() {
        this.setupContainerStyles();
        this.setupGridStyles();
      }

      private setupContainerStyles() {
        this.uiConfigRegistyService.registerItem(DwUiRegistryType.containerStyles, <DwUiContainerStyleReference>{
            keyName: '',
            display: 'App Blue',
            containerClass: 'dw-form-section-container2',
            containerInnerClass: 'dw-form-section-container-inner',
            containerTitleClass: 'dw-form-section-container-header dw-blue-bg'
          });
      } 

      private setupGridStyles() {

        
      }
  }
