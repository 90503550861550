<app-dw-card-designer [data]="data" [cardDesignerConfig]="cardDesignerConfig" [queryMetaData]="queryMetaData" (cardClick)="click()" (cardItemResultChange)="setCardItemResult($event)" [additionalClasses]="cardClass">
    <div cardRight class="card-additional-data">
        <div dwRow *ngIf="data?.PercentComplete>=100">
            <div dwCol="12">
               <i class="fa fa-check-circle dw-green" title="Complete"></i>
            </div>
        </div>
        <div dwRow *ngIf="data?.TotalTimeSeconds > 0 && data?.PercentComplete < 100">
            <div dwCol="12">
                {{data?.PercentComplete}}% <span class="hide-mobile"></span>
            </div>
        </div>
        <div dwRow *ngIf="data?.TotalTimeSeconds ==0">
            <div dwCol="12">
                <!-- <span class="hide-mobile">Not Started</span> -->
                <span class="hide-mobile">0%</span>
                <span class="show-mobile">0%</span>
            </div>
        </div>
    </div> 
</app-dw-card-designer>
