import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataListCardConfigData, DwMetaDataService, DwMetaDataServiceToken } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, CourseModuleProgressEntity, StudentCourseProgressQueryEntity, StudentModuleProgressQueryEntity } from '../../../../../meta-data/app-meta-data.service';
import { DwCardBaseConfig } from '../../../../z-devware/cards/models/dw-card-base-config.model';
import { DwCardItemResult } from '../../../../z-devware/cards/models/dw-card-item-result.model';
import { CourseProgressVariableContext } from '../../../models/course-progress-variable-context.model';

@DwComponent<DwMetaDataListCardConfigData>({
  name: 'Module Card - Student Progress',
  key: 'moduleCard-StudentProgress',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: false,
  parentItemName: AppMetaDataItemNames.CourseModuleProgress,
})
@Component({
  selector: 'app-module-student-progress-card',
  templateUrl: './module-student-progress-card.component.html',
  styleUrls: ['./module-student-progress-card.component.scss']
})
export class ModuleStudentProgressCardComponent implements DwCardBaseComponent<any, StudentModuleProgressQueryEntity> {
  cardConfig?: any;
  data?: StudentModuleProgressQueryEntity;
  resolvedContext?: any;

  cardClass:string;
  cardDesignerConfig?: DwCardBaseConfig;

  cardItemResult: DwCardItemResult<CourseModuleProgressEntity>;

  
  queryMetaData: any;
  variableContext: CourseProgressVariableContext;
  formApi: DwMetaDataFormApi;
  constructor(
    @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
    private formStateService: DwMetaDataFormStateService
  ) { 
    this.formApi = this.formStateService.state.formApi;

  }

  cardClick = new EventEmitter<any>();

  click() {
    this.cardClick.emit(this.data);

    // // Need to set selected module
    // this.formApi.patchVariableContext<CourseProgressVariableContext>({
    //   Selected_Module_Id: this.data.CourseModuleProgressId
    // });

  }

  setCardItemResult(cardItemResult: any): void {
    this.cardItemResult = cardItemResult;
  }
 
  setupCard() {
    this.formApi.getVariableContext<CourseProgressVariableContext>().subscribe(context => {
      this.variableContext = context;

      this.cardClass = '';

      if (this.data?.CourseModuleProgressId == context?.Selected_Module_Id) {
        this.cardClass = 'selected-module';
      } 
    });

    const attrs = AppMetaData.Queries.StudentModuleProgress.Attributes;

    this.cardDesignerConfig = {
      titleField: attrs.CourseModuleModuleName.fieldName,
      descriptionField: '',
    }
    if (!this.queryMetaData) {
      this.dwMetaDataService.getSavedQueryMetaData(AppMetaDataItemNames.Course, null, AppMetaData.Queries.StudentModuleProgress.QueryMeaning, false)
        .subscribe(queryMetaData => {
          this.queryMetaData = queryMetaData;
        });
    }
  }
}
