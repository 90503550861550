import { Injectable, Optional } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray, DwMetaDataFormStateService, DwMetaDataFormApi, DwEventService, DwGridEvents, DwActionItemRequirement, DwMdFormActionHandler, DwMdFormActionState } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentEntity, StudentCourseEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { CourseProgressVariableContext } from "../../courses/models/course-progress-variable-context.model";
import { StudentAssignmentProgressEvent, studentAssignmentProgress } from "../models/student-assignment-progress-event.model";
import { SchoolRepositoryService } from "../services/school-repository.service";
import { ResetStudentAssignmentRequest } from "../models/reset-student-assignment-request.model";
import { StudentCourseProgressRequest } from "../models/student-course-progress-request.model";
import { AppPermissions } from "../../../meta-data/app-permissions.enum";

@DwAction({
    keyName: 'student-reset-course-progress',
    display: 'Student - Reset Course Progress',
    actionType: DwActionType.formAction,
    parentItemName: AppMetaDataItemNames.StudentCourse,
    isGlobal: false,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Reset Course Progress',
        iconClass: 'fa fa-eraser',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'Clear out all progress for this course'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        permissionMeaning: AppPermissions.studentCourseCanResetProgress,
        requireCanUpdate: true
    },
    defaultConfirmConfig :{
        showDialogConfig: true,
        showConfirmDialog: true,
        message: 'Are you sure?  This cannot be undone!',
        title: 'Reset Course Progress for Student'
    }
})
@Injectable()
export class StudentResetCourseProgressActionService implements DwMdFormActionHandler {
    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService,
        @Optional() private dwFormStateService: DwMetaDataFormStateService,
        private schoolRespositoryService: SchoolRepositoryService,
        private dwEventService: DwEventService
    ) {
        
    }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {

        const studentCourse = actionState.formApi.getFormData<StudentCourseEntity>();

        const resetRequest: StudentCourseProgressRequest = {
            studentCourseId: studentCourse.StudentCourseId
        };

        return this.schoolRespositoryService.resetStudentCourseProgress(resetRequest)
            .pipe(map((resetResult) => {
                const result: DwActionResult = {
                    success: resetResult.IsSuccess
                };

                if (resetResult.ErrorMessage) {
                    result.message = `Error resetting student progress  Error: ${resetResult.ErrorMessage} `
                } else {
                    result.message = 'Student progress reset successfully';

                    actionState.formApi.cancel();
                }
        
                return result;
            }));
    }
}