<div class="course-nav">
    <div class="course-name"> {{ courseProgress?.course?.CourseName }}</div>

    <div class="course-details">
        <div class="course-details-inner">
            <ng-container *ngFor="let moduleNav of courseProgress?.modules">
                <div class="module-nav"
                    [ngClass]="{ 'module-nav-active' : currentLessonReference?.moduleId == moduleNav.module.CourseModuleId,  'module-only-nav-active' : (currentLessonReference?.moduleId == moduleNav.module.CourseModuleId && !currentLessonReference?.lessonId) }">
                    <div class="lesson-nav-text">
                        <span class="mr-1 expand-icon" (click)="toggleModuleCollapse(moduleNav)">
                            <i class="fa fa-chevron-right" title="Expand"
                                [hidden]="!moduleCollapsed[moduleNav.module?.CourseModuleId]"></i>
                            <i class="fa fa-chevron-down" title="Collapse"
                                [hidden]="moduleCollapsed[moduleNav.module?.CourseModuleId]"></i>
                        </span>
                        <span (click)="navigateToModule(moduleNav.module)">{{moduleNav.module.ModuleName}} </span>
                    </div>
                </div>
                <div [dwExpandable]="true" [collapsed]="moduleCollapsed[moduleNav.module?.CourseModuleId]">
                    <ng-container *ngFor="let lessonNav of moduleNav.lessons">
                        <div class="lesson-nav"
                            [ngClass]="{ 'lesson-nav-active' : currentLessonReference?.lessonId == lessonNav.lesson.CourseLessonId }"
                            (click)="navigateToLesson(moduleNav.module, lessonNav.lesson)">
                            <div class="lesson-nav-text">
                                <i [ngClass]="lessonNav.lessonIconClass"></i>

                                {{lessonNav.lesson.LessonName}}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>



<!-- 
<i class="fa fa-play-circle progress-icon progress-icon-1"></i>
<i class="fa fa-play-circle progress-icon progress-icon-2"></i><br>
<i class="progress-icon progress-icon-5"></i>
<i class="fa fa-play-circle progress-icon progress-icon-10"></i> -->