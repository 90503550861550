import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DwOrmDataServiceToken, DwOrmDataService, DwMetaDataServiceToken, DwMetaDataService, DwSecurityUserService, DwCacheService, DwEventService, DwFileDetail, DwMediaSize, DwMediaUploadResult, DwConfigService, DwConfigServiceToken, DwMediaEntity, SuppressErrorMessagesHeader, getErrorDetails, DwActionResult, DwModalConfig, DwModalButtonStandardConfigs, DwModalService, DwModalResult } from "@devwareapps/devware-cap";
import { Observable, Subject, of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import {VideoUploadResult, VideoUploadResultItem} from "../models/video-upload-result.model";
import { VideoUploadRequest } from "../models/video-upload-request.model";
import { HttpHeaders, HttpEventType, HttpClient } from "@angular/common/http";
import { VideoSetThumbnailResult } from "../models/video-set-thumbnail-result.model";


@Injectable({ providedIn: 'root' })
export class VideoUploadService {

    videoApi: string;
    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) private configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        private dwEventService: DwEventService,
        private router: Router,
        private http: HttpClient,
        //private dwModalService: DwModalService
    ) {
        this.videoApi = `${configService.coreConfig.apiRoot}/aviator-online/videos`;
    }

    // public showVideoUploadModal(video?: VideoEntity): Observable<DwModalResult> {
    //     let modalConfig = new DwModalConfig();

    //     modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;
    //     modalConfig.allowFullScreen = true;
    //     modalConfig.preventClose = true;

    //     modalConfig.buttons[0].buttonText = 'Upload';
    //     modalConfig.buttons[0].buttonClass = 'btn btn-warning';
    //     modalConfig.buttons[0].iconClass = 'fa fa-video';

    //     modalConfig.component = VideoUploadModalComponent;

    //     modalConfig.data = video;

    //     modalConfig.title = 'Replace Video';

    //     return this.dwModalService.showModal(modalConfig);
    // }

    updateVideoStatuses(videoStreamingProviderId : number) : Observable<boolean> {
        const url = `${this.videoApi}/statusupdate?videoStreamingProviderId=${videoStreamingProviderId}`;
        
        return this.http.get<boolean>(url);
    }
    
    updateVideoDetalis(videoIds: number[]) : Observable<boolean> {
        const videoDetailsRequest = {
            videoIds: videoIds
        }
        const url = `${this.videoApi}/updateVideoDetails`;
        
        return this.http.post<boolean>(url, videoDetailsRequest);
    }

    
    updateVideoStatus(videoId : number) : Observable<boolean> {
        const url = `${this.videoApi}/updateVideoStatus?videoId=${videoId}`;
        
        return this.http.get<boolean>(url);
    }

    revertToVideoHistory(videoHistoryId: number) : Observable<any> {
        const url = `${this.videoApi}/revertToVideoHistory?videoHistoryId=${videoHistoryId}`;
        
        return this.http.get(url);
    }

    uploadVideos(fileDetails: DwFileDetail[], videoUploadRequest: VideoUploadRequest, concurrentUploads: number = 1): Observable<VideoUploadResult[]> {
        const VideoUploadResults: VideoUploadResult[] = [];
        const resultSubject = new Subject<VideoUploadResult[]>();
        
        const uploadSubjects = new Subject<DwFileDetail>();

        for(const fileDetail of fileDetails) {
            const uploadResult: VideoUploadResult = {
                isPending: true,
                isUploading: false,
                isComplete: false,
                percentComplete: 0,
                fileName: fileDetail.resizedFile ? fileDetail.resizedFile.name : fileDetail.file.name
            };

            if (fileDetail.cancelled) {
                uploadResult.isComplete = true;
            }

            VideoUploadResults.push(uploadResult);
        }

        uploadSubjects.asObservable().pipe(
            mergeMap(fileDetail => {
                return this.uploadVideo(fileDetail, videoUploadRequest, fileDetail.cancellationSubject.asObservable());
            }, concurrentUploads)
        ).subscribe(result => {
            // Ignore events that we're not listening to
            if(!result) {
                return;
            }

            const existingResult = VideoUploadResults.find(r => r.fileName == result.fileName);
            
            const index = VideoUploadResults.indexOf(existingResult);

            // replace result
            VideoUploadResults[index] = result;

            resultSubject.next([...VideoUploadResults]);
        });

        for(const fileDetail of fileDetails) {
            if (!fileDetail.cancelled) {
                uploadSubjects.next(fileDetail);
            }
        }

        return resultSubject.asObservable();
    }

    uploadVideo(fileDetail: DwFileDetail, videoUploadRequest: VideoUploadRequest, cancellationTokenOb: Observable<any>) : Observable<VideoUploadResult> {
        const url = `${this.videoApi}/upload`;
        const file = fileDetail.file;

        const formData = new FormData();

        formData.append('file', file, file.name);
        formData.append('videoUploadRequest', JSON.stringify(videoUploadRequest));

        let headers = new HttpHeaders();

        headers = headers.set('contentTypeOverride', 'multipart/form-data');
        headers = headers.append(SuppressErrorMessagesHeader, 'true');

        // If no cancellation token, just create a noop version
        if (!cancellationTokenOb) {
            cancellationTokenOb = new Subject<any>().asObservable();
        }

        return this.http.post(url, formData, { reportProgress: true, observe: 'events', headers: headers })
            .pipe(map(event => {
                const uploadResult: VideoUploadResult = {
                    isUploading: false,
                    isComplete: false,
                    isPending: false,
                    fileName: file.name
                };

                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        uploadResult.isUploading = true;
                        uploadResult.percentComplete = Math.round(100 * event.loaded / event.total);

                        if (uploadResult.percentComplete == 100) {
                            uploadResult.isUploading = false;
                            uploadResult.isProcessing = true;
                            uploadResult.processingIconClass = 'fa fa-cloud-upload dw-orange';
                            uploadResult.progressingStatusMessage = 'Uploading';
                        }
                        break;
                    case HttpEventType.Response:
                        //uploadResult.isComplete = true;
                        uploadResult.isUploading = false;
                        uploadResult.isProcessing = true;
                        uploadResult.processingIconClass = 'fa fa-video dw-blue';
                        uploadResult.progressingStatusMessage = 'Encoding';
                        uploadResult.uploadResult = event.body as VideoUploadResultItem;
                        break;
                    
                    default:
                        return null;
                    // Other events
                }

                return uploadResult;
            }),
            catchError(errResponse=> {

                const errorDetails = getErrorDetails(errResponse);

                const uploadResult: VideoUploadResult = {
                    isUploading: true,
                    isComplete: false,
                    isPending: false,
                    isError: true,
                    errorMessage: 'Error uploading file: ' + errorDetails?.message || errResponse?.message,
                    fileName: file.name,
                    isProcessing:false
                };

                return of(uploadResult);
            }),
            takeUntil(cancellationTokenOb));
    }


    setThumnbailTime(videoId: number, timeSeconds: number): Observable<VideoSetThumbnailResult> {
        const url = `${this.videoApi}/setThumbnailTime?videoId=${videoId}&timeSeconds=${timeSeconds}`;

        return this.http.get<VideoSetThumbnailResult>(url);
    }


    getVideoToken(videoStreamingProviderId: number, videoKey: string):Observable<string> {
        const url = `${this.videoApi}/getVideoToken?videoStreamingProviderId=${videoStreamingProviderId}&videoKey=${videoKey}`;

        return this.http.get<string>(url);
    }
}
