import { Inject, Injectable, Optional } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray, DwMetaDataFormStateService, DwMetaDataFormApi, DwEventService, DwGridEvents, DwMdFormActionState, DwOrmDataService, DwOrmDataServiceToken, DwButtonType, DwMdFormActionResult } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentEntity, DwUserEntity, InstructorEntity, JoinRequestDetailsGridQueryEntity, JoinRequestEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { CourseProgressVariableContext } from "../../courses/models/course-progress-variable-context.model";
import { StudentAssignmentProgressEvent, studentAssignmentProgress } from "../models/student-assignment-progress-event.model";
import { SchoolRepositoryService } from "../services/school-repository.service";

@DwAction({
    keyName: 'resend-join-request-action',
    display: 'Resend Join Request',
    actionType: DwActionType.formAction,
    parentItemName: AppMetaDataItemNames.JoinRequest,
    isGlobal: false,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Send Reminder',
        iconClass: 'fa fa-envelope dw-orange',
        buttonClass: 'btn btn-success',
        buttonTitle: `Resends join request to student who haven''t accepted`
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: false
    }
})
@DwAction({
    keyName: 'resend-join-request-action-grid',
    display: 'Resend Join Request',
    actionType: DwActionType.gridRowAction,
    parentItemName: AppMetaDataItemNames.JoinRequest,
    isGlobal: false,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Send Reminder',
        iconClass: 'fa fa-envelope dw-icon-grid dw-orange',
        buttonClass: 'btn btn-success',
        buttonType: DwButtonType.iconOnlyNoText,
        buttonTitle: `Resends join request to student who haven''t accepted`
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: false
    }
})
@Injectable()
export class ResendJoinRequestActionService implements DwActionHandler {
    
    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService,
        @Optional() private dwFormStateService: DwMetaDataFormStateService,
        private dwEventService: DwEventService,
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        private schoolRepositoryService : SchoolRepositoryService
    ) {
       
    }

    handleAction(actionState: DwMdFormActionState | DwMetaDataGridActionState): Observable<DwActionResult> { 
        
        const result: DwActionResult = {
            success: true,
        };

        if (this.isForm(actionState)) {
            const joinRequest = actionState.formApi.getFormData<JoinRequestEntity>();
    
            return this.schoolRepositoryService.resendJoinRequest(joinRequest.JoinRequestId)
                .pipe(map(result => {


                    const formResult: DwMdFormActionResult = {
                        success: true,
                        message: 'E-mail reminder has been sent!'
                    }

                    return formResult;
                }))
        }

        // Otherwise we're in a grid
        const joinRequests: JoinRequestDetailsGridQueryEntity[] = ensureArray(actionState.data);

        const emailsEntities = joinRequests.filter(jr => jr.JoinRequestEmailJoinRequestEmailId).map(jr => this.createJoinRequestEmail(jr));

        
        return this.schoolRepositoryService.resendJoinRequestEmails(emailsEntities)
            .pipe(map(result => {
                const gridResult : DwGridActionResult = {
                    success: true,
                    refreshData: true,
                    message: 'E-mail reminder has been sent!'
                }

                return gridResult;
            }));

    }
    createJoinRequestEmail(jr: JoinRequestDetailsGridQueryEntity): any {
       const jrEmail = AppMetaData.JoinRequestEmail.CreateEntity();

       jrEmail.JoinRequestEmailId = jr.JoinRequestEmailJoinRequestEmailId;
       jrEmail.Accepted = jr.JoinRequestEmailAccepted;

       return jrEmail;
    }

    private isForm(actionState: DwMdFormActionState | DwMetaDataGridActionState): actionState is DwMdFormActionState {
       return !!(actionState as DwMdFormActionState).formApi;
    }
}