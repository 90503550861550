import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CourseLessonEntity, CourseLessonProgressEntity, QuizEntity, QuizResultEntity, QuizResultStatusAllItems } from '../../../../meta-data/app-meta-data.service';
import { QuizProgressEvent } from '../../models/quiz-progress-event.model';
import { QuizRepositoryService } from '../../services/quiz-repository.service';
import { Observable, combineLatest } from 'rxjs';
import { LessonProgressHierarchy } from '../../../courses/models/lesson-progress-hierarchy.model';
import { QuizCompleteResult } from '../../models/quiz-complete-result.model';
import { QuizViewAction, QuizViewActionType } from '../../models/quiz-view-action.enum';
import { QuizHomeView } from '../../models/quiz-home-view.enum';
import { DwSecurityStateService, sortArrayByField } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-quiz-home',
  templateUrl: './quiz-home.component.html',
  styleUrls: ['./quiz-home.component.scss']
})
export class QuizHomeComponent implements OnInit, OnChanges {
  @Input() lesson: CourseLessonEntity;
  @Input() progress: LessonProgressHierarchy;
  @Output() quizEvent = new EventEmitter<QuizProgressEvent>();

  

  quiz: QuizEntity;
  quizResults: QuizResultEntity[] = [];

  currentQuizResult: QuizResultEntity;

  @Input() view: QuizHomeView = QuizHomeView.notStarted;
  @Output() viewChanged = new EventEmitter<QuizHomeView>();
  constructor(private quizRepositoryService: QuizRepositoryService, private securityStateService: DwSecurityStateService) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.lesson?.firstChange == false) {
      if (this.quiz?.QuizId != this.lesson?.QuizId) {
        this.initQuiz();
      }
    }
  }

  ngOnInit(): void {
    this.initQuiz();
  }

  startQuiz() {
    this.currentQuizResult = null;

    //this.view = QuizHomeView.quiz;
    this.setView(QuizHomeView.quiz);
  }

  quizComplete(event: QuizCompleteResult) {
    // Nothing to do if the quiz is cancelled
    if (event.isCancelled) {

      this.quizResultAction({ isCancelled: true })
      return;
    }

    this.currentQuizResult = event.quizResult;
    this.quizResults = [this.currentQuizResult, ...this.quizResults];

    //this.view = QuizHomeView.lastResult;

    this.setView(QuizHomeView.lastResult);

    let bestResult = sortArrayByField(this.quizResults, 'PercentageScore', 'QuizStopDateTime', true)[0];

    let isComplete = (bestResult.QuizResultStatusId == QuizResultStatusAllItems.Passed);

    this.quizEvent.emit({
      isComplete: isComplete,
      bestQuizResultId: bestResult.QuizResultId,
      quizResult: this.currentQuizResult
    });
  }

  quizResultAction(action: QuizViewAction) {

    if (action.isCancelled) {
      if (this.currentQuizResult) {
        this.setView(QuizHomeView.lastResult);
        //this.view = QuizHomeView.lastResult;
      } else {
        if (this.quizResults.length > 0) {
          this.currentQuizResult = this.quizResults[this.quizResults.length - 1]
          this.setView(QuizHomeView.lastResult);
          //this.view = QuizHomeView.lastResult;
        } else {
          this.setView(QuizHomeView.notStarted);
          //this.view = QuizHomeView.notStarted;
        }
      }
    }

    switch (action.actionType) {
      case QuizViewActionType.retake:
        this.startQuiz();
        break;
      case QuizViewActionType.viewAnswers:
        //this.view = QuizHomeView.quizAnswers;
        this.setView(QuizHomeView.quizAnswers);
        this.currentQuizResult = action.quizResult;

        break;
      case QuizViewActionType.viewAllResults:
        this.setView(QuizHomeView.allResults);
        //this.view = QuizHomeView.allResults;

        break;
    }

  }

  initQuiz() {
    if (!this.lesson?.QuizId) {
      return;
    }

    const obs: Observable<any>[] = [];

    obs.push(this.quizRepositoryService.getQuiz(this.lesson.QuizId));
    obs.push(this.quizRepositoryService.getQuizResults(this.lesson.QuizId, this.progress.course.studentCourse.StudentCourseId, this.progress?.lesson?.lessonProgress?.CourseLessonProgressId));

    combineLatest(obs).subscribe(([quiz, quizResults]) => {
      this.quiz = quiz;
      this.quizResults = quizResults;

      if (this.quizResults.length > 0) {
        this.currentQuizResult = this.quizResults[this.quizResults.length - 1]

        this.setView(QuizHomeView.lastResult);
      }

    });

  }

  private setView(view: QuizHomeView) {
    this.view = view;

    this.securityStateService.setLastUserInteraction();

    this.viewChanged.emit(view);
  }

}
