import { Inject, Injectable, Optional } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray, DwMetaDataFormStateService, DwMetaDataFormApi, DwEventService, DwGridEvents, DwMdFormActionState, DwOrmDataService, DwOrmDataServiceToken } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentEntity, DwUserEntity, InstructorEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { CourseProgressVariableContext } from "../../courses/models/course-progress-variable-context.model";
import { StudentAssignmentProgressEvent, studentAssignmentProgress } from "../models/student-assignment-progress-event.model";

@DwAction({
    keyName: 'instructor-assign-students-save-action',
    display: 'Instructor Assign Students Save',
    actionType: DwActionType.formAction,
    parentItemName: AppMetaDataItemNames.Instructor,
    isGlobal: false,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Save Assignments',
        iconClass: 'fa fa-save',
        buttonClass: 'btn btn-success',
        buttonTitle: 'Assign Students'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    }
})
@Injectable()
export class InstructorAssignStudentsSaveActionService implements DwActionHandler {
    
    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService,
        @Optional() private dwFormStateService: DwMetaDataFormStateService,
        private dwEventService: DwEventService,
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
    ) {
       
    }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> { 
        
        const result: DwActionResult = {
            success: true,
        };

        const user = actionState.formApi.getFormData<DwUserEntity>();

        const instrutor : InstructorEntity = user.Instructor;

        if (!instrutor) {
            result.message = 'Could not save instructor';
            result.success = false;
            return of (result);
        }

        return this.dwOrmDataService.saveEntity(instrutor, true)
            .pipe(map(result => {
                if (result.ErrorMessage) {
                    result.message = `Error saving instructor students: ${result.ErrorMessage}`;
                }
                
                // Since we saved the instrutor, we need to publish an event to update the UI
                this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.InstructorStudent), {});

                // Hide the assign students form
                actionState.formApi.patchVariableContext({Show_Assign_Students: false});

                return result;
            }));
    }

}