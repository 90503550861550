import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { QuizEntity, QuizResultEntity } from '../../../../meta-data/app-meta-data.service';
import { QuizUtilService } from '../../services/quiz-util.service';
import { isFirstChange } from '@devwareapps/devware-cap';
import { QuizDisplayResult } from '../../models/quiz-display-result.model';
import { QuizViewAction, QuizViewActionType } from '../../models/quiz-view-action.enum';

@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
})
export class QuizResultComponent implements OnInit, OnChanges {
  @Input() quiz: QuizEntity;
  @Input() quizResult: QuizResultEntity;

  @Input() moreThanOneResult: boolean = false;

  @Output() quizResultAction = new EventEmitter<QuizViewAction>();

  @Input() showRetake: boolean = true;
  quizDisplayResult: QuizDisplayResult;


  constructor(private quizUtilService: QuizUtilService) { }
  
  ngOnInit(): void {
    this.setupQuizResult();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isFirstChange(changes)) {
      this.setupQuizResult();
    }
  }

  private setupQuizResult() {
    if (!this.quiz) {
      return;
    }
    this.quizDisplayResult = this.quizUtilService.getQuizDisplayResult(this.quiz, this.quizResult);

  }

  takeAction(actionType: QuizViewActionType) {

    const action: QuizViewAction = {
      actionType: actionType,
      quizResult: this.quizResult,
      isCancelled: false
    };

    this.quizResultAction.emit(action);
  }

}
