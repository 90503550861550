import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CourseRepositoryService } from "./course-repoistory.service";
import { Observable } from "rxjs";
import { LessonNavigationInfo } from "../models/lesson-navigation-info.model";
import { map } from "rxjs/operators";
import { AppMetaDataItemNames, CourseEntity } from "../../../meta-data/app-meta-data.service";
import { deepCopy, DwActionType, DwMenuActionType, DwMetaDataRoutingService, DwModalConfig, DwModalService } from "@devwareapps/devware-cap";
import { LessonReferenceInfo } from "../models/lesson-reference-info.model";



@Injectable({ providedIn: 'root' })
export class CourseNavigationService {


    constructor(private router: Router, private courseRepositoryService: CourseRepositoryService,
       // private dwMetaDataRoutingService: DwMetaDataRoutingService
    ) {

    }


    // showEndorsementModal(courseEndorsementStudentId: number) {

    //     const modalOptions: Partial<DwModalConfig> = {
    //         title : 'View Endorsement',
    //     }

    //     // return this. dwMetaDataRoutingService.openAddEditViewModal(DwMenuActionType.view, AppMetaDataItemNames.CourseEndorsementStudent, courseEndorsementStudentId, null, null, 'student-view', modalOptions, true)
    //     //     .subscribe(modalResult => {
    //     //     });
    // }

    resumeCourse(courseId: number) {
        this.courseRepositoryService.getCourseProgress(courseId)
            .subscribe(progress => {
                if (progress.furtherestLesson) {
                    this.navigateToLesson({
                        courseId: courseId,
                        moduleId: progress.furtherestLesson.CourseModuleId,
                        lessonId: progress.furtherestLesson.CourseLessonId
                    });

                    return true;
                }
            });
    }

    navigateToModule(courseId: number, moduleId: number) {
        const url = `/my-courses/view/${courseId}/module/${moduleId}`;

        this.router.navigateByUrl(url);
    }

    navigateToLesson(lessonReference: LessonReferenceInfo) {
        const url = `/my-courses/view/${lessonReference?.courseId}/module/${lessonReference?.moduleId}/lesson/${lessonReference?.lessonId}`;

        this.router.navigateByUrl(url);
    }

    getLessonNavigationInfo(lessonReference: LessonReferenceInfo): Observable<LessonNavigationInfo> {
        return this.courseRepositoryService.getCourseHierarchy(lessonReference?.courseId)
            .pipe(map(course => {
                return this.getLessonNavigationInfoFromCourse(course, lessonReference);
            }));
    }

    private getLessonNavigationInfoFromCourse(course: CourseEntity, lessonReference: LessonReferenceInfo): LessonNavigationInfo {

        course = deepCopy(course);
        const lessonNavigationInfo: LessonNavigationInfo = {
            course: course,
            currentLesson: lessonReference,
            isFirstLesson: false,
            isLastLesson: false,
            isFirstModule: false,
            isLastModule: false
        };

        if (course) {
            const currentModule = course.CourseModule.find(m => m.CourseModuleId == lessonReference.moduleId);
            const currentLesson = currentModule?.CourseLesson.find(l => l.CourseLessonId == lessonReference.lessonId);

            const nextLesson = currentModule?.CourseLesson.find(m => m.LessonOrder > currentLesson?.LessonOrder);

            if (nextLesson) {
                lessonNavigationInfo.nextLesson = {
                    courseId: course.CourseId,
                    moduleId: currentModule.CourseModuleId,
                    lessonId: nextLesson.CourseLessonId
                };
            } else {
                const nextModule = course.CourseModule.find(m => m.ModuleOrder > currentModule?.ModuleOrder);
                const firstLesson = nextModule?.CourseLesson[0];

                if (firstLesson) {
                    lessonNavigationInfo.isLastLesson = true;

                    lessonNavigationInfo.nextLesson = {
                        courseId: course.CourseId,
                        moduleId: nextModule.CourseModuleId,
                        lessonId: firstLesson.CourseLessonId
                    };
                } else {
                    lessonNavigationInfo.isLastModule = true;
                }
            }

            const previousLesson = currentModule?.CourseLesson.reverse().find(m => m.LessonOrder < currentLesson?.LessonOrder);

            if (previousLesson) {
                lessonNavigationInfo.previousLesson = {
                    courseId: course.CourseId,
                    moduleId: currentModule.CourseModuleId,
                    lessonId: previousLesson.CourseLessonId
                };
            } else {
                const previousModule = course.CourseModule.reverse().find(m => m.ModuleOrder < currentModule?.ModuleOrder);
                const previousModuleLesson = previousModule?.CourseLesson.reverse()[0];

                if (previousModuleLesson) {
                    lessonNavigationInfo.isFirstLesson = true;

                    lessonNavigationInfo.previousLesson = {
                        courseId: course.CourseId,
                        moduleId: previousModule.CourseModuleId,
                        lessonId: previousModuleLesson.CourseLessonId
                    };
                } else {
                    lessonNavigationInfo.isFirstLesson = true;
                    lessonNavigationInfo.isFirstModule = true;
                }
            }
        }


        return lessonNavigationInfo;
    }

    getCurrentCourseParameters(): LessonReferenceInfo {
        return this.getCourseUrlParameters(this.router.url);
    }

    getCourseUrlParameters(url: string): LessonReferenceInfo {
        url = url.replace('//', '/');

        if (url?.indexOf('/my-courses/view/') != 0) {
            return null;
        }

        const result: LessonReferenceInfo = {};

        const parts = url.replace('//', '/').split('/');

        result.courseId = parseInt(parts[3]);

        if (parts.length >= 6) {
            result.moduleId = parseInt(parts[5]);
        }

        if (parts.length >= 8) {
            result.lessonId = parseInt(parts[7]);
        }

        return result;
    }

}