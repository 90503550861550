import { Injectable } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwMdFormActionHandler, DwMdFormActionState } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, VideoEntity } from "../../../meta-data/app-meta-data.service";
import { VideoUploadModalComponent } from "../components/video-upload-modal/video-upload-modal.component";
import { map } from "rxjs/operators";


@DwAction({
    keyName: 'video-replace-action',
    display: 'Replace Video',
    actionType: DwActionType.formAction,
    parentItemName: AppMetaDataItemNames.Video,
    isGlobal: true,
    defaultButtonConfig: {
        useParentDisplay: true,
        iconClass: 'fa fa-video',
        buttonClass: 'btn btn-warning',
        buttonTitle: 'Replace existing video'
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: true,
        // permissionMeaning: DwSecurityPermissions.AdminCanAccess,
        requireCanUpdate: true
    }
})
@Injectable()
export class VideoReplaceFormActionService implements DwMdFormActionHandler {
    constructor(
        /*  @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
         private route: ActivatedRoute,
         private metaDataRoutingService: DwMetaDataRoutingService, */
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService
    ) { }


    handleAction(actionState: DwMdFormActionState<any>): Observable<DwActionResult> {

        const video = actionState.formApi.getFormData<VideoEntity>();
        return this.showModal(video);
    }

    public showModal(video: VideoEntity): Observable<DwActionResult> {
        let modalConfig = new DwModalConfig();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;
        modalConfig.allowFullScreen = true;
        modalConfig.preventClose = true;

        modalConfig.buttons[0].buttonText = 'Upload';
        modalConfig.buttons[0].buttonClass = 'btn btn-warning';
        modalConfig.buttons[0].iconClass = 'fa fa-video';

        modalConfig.component = VideoUploadModalComponent;

        modalConfig.data = video;

        modalConfig.title = 'Replace Video';

        return this.dwModalService.showModal(modalConfig)
            .pipe(map(modalResult => {
                const result = new DwGridActionResult();

                if (result.cancelled) {
                    result.refreshData = false;
                    result.cancelled = true;

                    return result;
                }

                result.refreshData = true;

                return result;
            }));
    }

}