import { Component, OnChanges, OnInit, SimpleChanges, Type } from '@angular/core';
import { QuizAnswerBaseComponent } from '../base/quiz-answer-base.component';
import { QuestionTypeAllItems } from '../../../../../meta-data/app-meta-data.service';
import { QuizAnswerTrueFalseComponent } from '../quiz-answer-true-false/quiz-answer-true-false.component';
import { QuizAnswerMultipleAnswerComponent } from '../quiz-answer-multiple-answer/quiz-answer-multiple-answer.component';
import { QuizAnswerMultipleChoiceComponent } from '../quiz-answer-multiple-choice/quiz-answer-multiple-choice.component';

@Component({
  selector: 'app-quiz-answer-host',
  template: `
  <div *ngIf="answerComponentType" class="quiz-answer-host">
    <dw-dynamic-loader [component]="answerComponentType" (configure)="configureAnswerComponent($event)"></dw-dynamic-loader>
  </div>
  `
})
export class QuizAnswerHostComponent extends QuizAnswerBaseComponent implements OnInit, OnChanges {
  
  answerComponentInstance: QuizAnswerBaseComponent;

  answerComponentType: Type<any>;
  
  ngOnInit(): void {
    this.question = this.questionLink?.Question;
    this.determinAnswerComponent(); 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.questionLink?.firstChange) {
      this.question = this.questionLink?.Question;

      const isComponentChange = this.determinAnswerComponent();

      if (!isComponentChange) {
        this.updateAnswerComponentInstance();
      }
    }
  }

  determinAnswerComponent() {
    if (!this.questionLink) {
      return;
    }

    let answerComponentType :Type<any> = null;
    switch (this.questionLink.Question.QuestionTypeId) {
      case QuestionTypeAllItems.TrueOrFalse:
        answerComponentType = QuizAnswerTrueFalseComponent;
        break;
      case QuestionTypeAllItems.MultipleAnswer:
        answerComponentType = QuizAnswerMultipleAnswerComponent;
        break;
      case QuestionTypeAllItems.MultipleChoice:
        answerComponentType = QuizAnswerMultipleChoiceComponent;
        break;
    }

    if (answerComponentType != this.answerComponentType) {
      this.answerComponentType = answerComponentType;
      return true;
    }

    return false;
  }


  configureAnswerComponent(answerComponentInstance: QuizAnswerBaseComponent) {
    this.answerComponentInstance = answerComponentInstance;

    this.answerComponentInstance.questionResultChange.subscribe((result) => {
   
    });

    this.updateAnswerComponentInstance();
  }

  
  updateAnswerComponentInstance() {
    this.answerComponentInstance.questionLink = this.questionLink;
    this.answerComponentInstance.questionResult = this.questionResult;
    this.answerComponentInstance.reviewAnswers = this.reviewAnswers;

    this.answerComponentInstance.initAnswer();
  }
}
