import { QuizResultEntity } from "../../../meta-data/app-meta-data.service";

export interface QuizViewAction {
   actionType? : QuizViewActionType;
   isCancelled?: boolean;

   quizResult?: QuizResultEntity;
}


export enum QuizViewActionType {
    retake= 'retake',
    viewAnswers = 'viewAnswers',
    viewAllResults = 'viewAllResults',
}