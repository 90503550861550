import { Inject, Injectable } from '@angular/core';
import { DevwareMetaData, DwAppPermissionEntity, DwAppPermissionMetaData, DwAttributeType, DwCacheService, DwContextDefinition, DwControlType, DwCustomContextBase, DwCustomContextDefinition, DwMenuService, DwOrmDataService, DwOrmDataServiceToken, DwQuery, DwQueryHelper, DwQueryMdAttribute, DwRoutingService, DwSecurityTopics, DwSecurityUserService } from '@devwareapps/devware-cap';
import { AppMetaData, RoModuleLessonMaxOrderEntity } from '../../../meta-data/app-meta-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


export const CourseHelperContext = 'courseHelperContext';

@DwCustomContextDefinition({
    keyName: CourseHelperContext,
    display: 'Course Helper Context',
    isGlobal: true,
})
@Injectable()
export class CourseHelperContextContextService extends DwCustomContextBase {

    constructor(private dwSecurityUserService: DwSecurityUserService,
        private dwRoutingService: DwRoutingService,
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        private dwCacheService: DwCacheService

    ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {
        //contextDef.buildQueryFunction = (context) => this.buildQuery(context);

        contextDef.getCustomContextFunction = (context) => this.getContext(context);

        contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];
        contextDef.noCache = true;

        contextDef.attributes = this.getContextAttribute();


    }

    private getContextAttribute(): DwQueryMdAttribute[] {
        const attributes: DwQueryMdAttribute[] = [];

        this.addProperty(attributes, 'CourseId', 'Current Course', DwAttributeType.Integer, DwControlType.NumericTextBox);
        this.addProperty(attributes, 'NextModuleOrder', 'Next Module Order', DwAttributeType.Integer, DwControlType.NumericTextBox);
        this.addProperty(attributes, 'CurrentModuleId', 'Curren Module', DwAttributeType.Integer, DwControlType.NumericTextBox);
        this.addProperty(attributes, 'NextLessonOrder', 'Next Lesson Order', DwAttributeType.Integer, DwControlType.NumericTextBox);
        // for(const extAttr of this.metadataConfig.userContextExtendedAttributes || []) {
        //     this.addProperty(attributes, extAttr.name, extAttr.display, extAttr.attrType, extAttr.controlType);
        // }

        return attributes;
    }

    getContext(context: any): any {

        // return this.dwRoutingService.getCurrentRouteParams()
        //     .pipe(map(routeParams => {
        //         console.log('routeParams', routeParams);
        //         return {
        //             CourseId:1,
        //             NextModuleOrder: 2,
        //             CurrentModuleId: 2,
        //             NextLessonOrder: 5,
        //         }


        //     }));

        const routeParams = this.dwRoutingService.getCurrentRouteParams();
        //console.log('routeParams', routeParams);

        return this.getModuleLessonMaxes()
            .pipe(map(moduleLessonMaxes => {

                const moduleMax = moduleLessonMaxes.find(m => m.CourseId === parseInt(routeParams?.CourseId))?.ModuleMaxOrder || 0;
                const lessonMax = moduleLessonMaxes.find(m => m.CourseModuleId === parseInt(routeParams?.CourseModuleId))?.LessonMaxOrder || 0;

               // console.log('moduleLessonMaxes', moduleLessonMaxes);
                const result = {
                    CourseId: routeParams?.CourseId,
                    NextModuleOrder: moduleMax + 1,
                    CurrentModuleId: routeParams?.CourseModuleId,
                    NextLessonOrder: lessonMax + 1
                };

               // console.log('result', result);

                return result;
            }));
    }

    private getModuleLessonMaxes(): Observable<RoModuleLessonMaxOrderEntity[]> {
        var cacheInvalidationTopics: string[] = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.CourseModule.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.CourseLesson.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwSecurityTopics.SECURITY_CHANGED);

        return this.dwCacheService.get("ModuleLessonMaxes", this.loadModuleLessonMaxes(), null, cacheInvalidationTopics);
    }

    private loadModuleLessonMaxes(): Observable<RoModuleLessonMaxOrderEntity[]> {
        const query = AppMetaData.RoModuleLessonMaxOrder.CreateQueryBuilder();

        return this.dwOrmDataService.executeQuery(query.query);
    }

    // buildQuery(resolvedContext: any): DwQuery {
    //     const query = AppMetaData.CourseModule.CreateQueryBuilder();

    //     if (!this.dwSecurityUserService.securityContext?.ApplicationUser) {
    //         return null;
    //     }

    //     query.query.FieldSettings.LoadAllLookupDisplayFields = true;

    //     const user = this.dwSecurityUserService.securityContext.ApplicationUser;

    //     query.addFilterAnd(filters => filters.Equal(f => f.UserId, user?.UserId || -1));

    //     return query.query;

    // }


}
