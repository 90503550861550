import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DwControlType, DwSecurityRepositoryService, DwSecurityRepositoryServiceToken, DwSecurityUserService } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-aviator-online-home-page',
  templateUrl: './aviator-online-home-page.component.html',
  styleUrls: ['./aviator-online-home-page.component.scss']
})
export class AviatorOnlineHomePageComponent implements OnInit {
 
  isAuthenticated: boolean;

  constructor(private router:Router,
    private dwSecurityUserService: DwSecurityUserService,
    @Inject(DwSecurityRepositoryServiceToken) private dwSecurityRepositoryService: DwSecurityRepositoryService
  
    ) { }

  ngOnInit(): void {
   

    this.dwSecurityUserService.securityContextChanged().subscribe(change => {
      this.isAuthenticated = this.dwSecurityUserService.securityState.isAuthenticated;
    });
      
    this.isAuthenticated = this.dwSecurityUserService.securityState.isAuthenticated;
  }
}
