<dw-message [message]="message"></dw-message>

<ng-container *ngIf="!uploadStarted">
    <ng-container *ngIf="!modalConfig?.data?.VideoId">
        <div dwRow>
            <div dwCol="12">
                <label>Video Streaming Provider</label>
                <dw-select-lookup [lookupId]="videoStreamingProviderLookupId" [(ngModel)]="videoStreamingProviderId"
                    showBlankOption="false"></dw-select-lookup>
            </div>
        </div>
        <div dwRow>
            <div dwCol="12">
                <label>Update Matching filenames</label>
                <dw-yesno [(ngModel)]="matchFileName"></dw-yesno>
            </div>
        </div>
    </ng-container>

    <dw-file-upload [allowMultiple]="allowMultiple" (filesSelected)="onFilesSelected($event)"
        [allowedFileTypes]="allowedFileTypes" [allowMultiple]="allowMultiple"></dw-file-upload>
</ng-container>

<div class="dw-file-list">
    <div class="dw-file" *ngFor="let fileDetail of fileDetails; index as i" [hidden]="fileDetail.cancelled">
        <div class="dw-file-icon">
            <i [ngClass]="fileDetail.fileIconCss"></i>
        </div>
        <div class="dw-file-name" title="{{fileDetail.file.name}}">
            {{fileDetail.file.name}}
        </div>
        <div class="dw-file-size">
            {{ fileDetail.file.size | filesize}}
        </div>
        <div class="dw-file-status">
            <i class="fa fa-times hand" (click)="cancelFile(fileDetail)"
                [hidden]="uploadResults[i]?.isComplete || uploadResults[i]?.isProcessing" title="Cancal upload"></i>
            <i [ngClass]="uploadResults[i]?.processingIconClass" [hidden]="!uploadResults[i]?.isProcessing"
                [title]="uploadResults[i]?.progressingStatusMessage"></i>
            <i class="fa fa-check dw-green" [hidden]="!uploadResults[i]?.isComplete"></i>
        </div>
        <div *ngIf="uploadResults[i]?.isProcessing" class="dw-video-upload-state">
            {{ uploadResults[i]?.progressingStatusMessage}}
        </div>
        <div *ngIf="fileDetail.errorMessage" class="dw-file-error">
            {{ fileDetail.errorMessage}}
        </div>
        <div *ngIf="uploadResults[i]" class="dw-file-progress">
            <p-progressBar [value]="uploadResults[i]?.percentComplete" [style]="{'height': '4px'}" mode="indeterminate"
                *ngIf="uploadResults[i]?.isProcessing"></p-progressBar>
            <p-progressBar [value]="uploadResults[i]?.percentComplete" [style]="{'height': '4px'}"
                *ngIf="!uploadResults[i]?.isProcessing || uploadResults[i]?.isComplete"></p-progressBar>
        </div>
    </div>
</div>