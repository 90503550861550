import { Component, Inject, OnInit } from '@angular/core';

import { AppMetaDataItemNames, AssignmentEntity, InstructorEntity } from '../../../../meta-data/app-meta-data.service';
import {
  DwComponent, DwComponentType, DwExpressionService, DwFormContextMode, DwMdFormContextInfo, DwMetaDataFormApi, DwMetaDataFormStateService,
  DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken,
  DwLookupSearchType, DwOrmDataService, DwOrmDataServiceToken, DwQueryHelper, DwQueryFilter, DwOperator, DwFilterType,
  deepCopy,
  DwEventService,
  DwGridEvents
}
  from '@devwareapps/devware-cap';
import { StudentAssignmentProgressEvent, studentAssignmentProgress } from '../../models/student-assignment-progress-event.model';
import { CourseProgressVariableContext } from '../../../courses/models/course-progress-variable-context.model';
import { InstructorRepositoryService } from '../../../instructors/services/instructor-repository.service';

@DwComponent({
  key: 'assignmentUtil',
  name: 'Assignment Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.Assignment,
})
@Component({
  selector: 'app-assignment-util',
  templateUrl: './assignment-util.component.html',
  styleUrls: ['./assignment-util.component.scss']
})
export class AssignmentUtilComponent extends DwSectionBaseComponent {
  assignment: AssignmentEntity;

  originalAssignment: AssignmentEntity;
  formApi: DwMetaDataFormApi;
  instructor: InstructorEntity;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Inject(DwOrmDataServiceToken) private dataService: DwOrmDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private dwEventService: DwEventService,
    private instructorRepositoryService: InstructorRepositoryService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupEventHandlers();

    // this.runAfterFormDataLoaded(() => {
    //   this.setupStudents();
    // });

    // this.formApi.getFormDataChanges(100).subscribe(() => {
    //   this.setupStudents();
    // });

    this.setupData();
  }


  setupData() {
    this.checkFields();

    this.formApi.getFormDataChanges<AssignmentEntity>().subscribe(assignment => {
      this.checkFields();
    });
  }

  instructorInfoLoaded = false;

  checkFields() {
    this.assignment = this.formApi.getFormData<AssignmentEntity>();
    if (!this.assignment) {
      return;
    }

    if (!this.assignment.FlightSchoolId) {
      
      // if (this.instructor) {
      //   const partialAssignment : Partial<AssignmentEntity> = {
      //     FlightSchoolId: this.instructor.FlightSchoolId
      //   };
        
      //   this.formApi.patchFormData(partialAssignment);
      //   return;
      // }

      if (this.instructorInfoLoaded) {
        return;
      }

      this.instructorInfoLoaded = true;

      this.instructorRepositoryService.getCurrentInstructor()
        .subscribe((instructor) => {
          this.instructor = instructor;

          this.checkFields();

          // if (instructor?.FlightSchoolId) {
          //   this.assignment.FlightSchoolId = instructor.FlightSchoolId;

          //   this.formApi.patchFormData(this.assignment);
          // }
        });
    }
  }

  setupEventHandlers(): void {

    this.subscriptions.push(
      this.dwEventService.getEventData<StudentAssignmentProgressEvent>(studentAssignmentProgress).subscribe((event) => {
        this.viewProgressDetail(event);
      }));

    // Clear the quick search text when the tab is changed
    this.formApi.getVariableContext<CourseProgressVariableContext>()?.subscribe(context => {
      if (context.Tab_Index === 1) {
        this.dwEventService.publishEvent(DwGridEvents.setQuickSearchText,
          {
            itemName: AppMetaDataItemNames.AssignmentStudent,
            quickSearchText: ''
          });
      }
    });

    // If the assignment is updated, we need to refresh any assignment student grids
    this.subscriptions.push(
      this.dwEventService.getEventData(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.Assignment)).subscribe((event) => {
        this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.AssignmentStudent), {});
      })
    );

  }

  viewProgressDetail(event: StudentAssignmentProgressEvent) {
    if (!event.data?.StudentIdDisplay) {
      return;
    }

    // Need to set selected selected lesson and the tab
    this.formApi?.patchVariableContext<CourseProgressVariableContext>({
      Tab_Index: 2
    });

    this.dwEventService.publishEvent(DwGridEvents.setQuickSearchText,
      {
        itemName: AppMetaDataItemNames.AssignmentStudent,
        quickSearchText: event.data?.StudentIdDisplay
      }
    );
  }



}