import { Component, EventEmitter, OnInit } from '@angular/core';
import { AppMetaDataItemNames, AppMetaDataLookups, QuestionEntity, QuizEntity, QuizTypeAllItems, StudentModuleLessonActivityQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { DwModalHandler, DwModalConfig, DwModalResult, DwButtonConfig, DwButtonCommand, DwMessage, DwMessageSeverity } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { QuizRepositoryService } from '../../services/quiz-repository.service';
import { map, mergeMap } from 'rxjs/operators';
import { LinkQuestionsModalResult } from '../../models/link-questions-modal-result.model';

@Component({
  selector: 'app-link-questions-modal',
  templateUrl: './link-questions-modal.component.html',
})
export class LinkQuestionsModalComponent implements OnInit, DwModalHandler {

  modalConfig: DwModalConfig<QuestionEntity[]>;
  closeModal = new EventEmitter<DwModalResult<any>>();

  isNewQuiz: boolean = false;
  quizName: string;
  quizId: number;
  openQuizAfter: boolean = true;

  quizLookupId = AppMetaDataLookups.QuizAllItems;

  message: DwMessage;
  constructor(private quizRepositoryService: QuizRepositoryService) { }


  ngOnInit(): void {
  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult<any>> {
    let result = new DwModalResult();

    result.cancelled = false;

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:
        if (!this.validateForm) {
          return of(result);
        }

        return this.createOrUpdateQuiz();


      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }

  createOrUpdateQuiz(): Observable<DwModalResult<any>> {
    let quizEntity: QuizEntity = {
      _itemName: AppMetaDataItemNames.Quiz,
    };

    let ob: Observable<QuizEntity> = null;;

    if (this.isNewQuiz) {
      quizEntity.QuizName = this.quizName;
      quizEntity.QuizTypeId = QuizTypeAllItems.Quiz;
      quizEntity.UseRandomOrder = true;
      quizEntity.QuizQuestionLink = [];

      ob = of(quizEntity);
    } else {
      ob = this.quizRepositoryService.getQuiz(this.quizId)
    }

    return ob.pipe(mergeMap(quizEntity => {
      return this.updateQuizQuestions(quizEntity);

    }));

  }
  updateQuizQuestions(quizEntity: QuizEntity): Observable<DwModalResult<any>> {

    // Add questions
    this.modalConfig.data.forEach(question => {
      // Check if question is already linked
      let existingLink = quizEntity.QuizQuestionLink.find(q => q.QuestionId === question.QuestionId);

      if (!existingLink) {
        quizEntity.QuizQuestionLink.push({
          _itemName: AppMetaDataItemNames.QuizQuestionLink,
          QuestionId: question.QuestionId
        });
      }
    });

    let order = 1;
    quizEntity.QuizQuestionLink.forEach(link => {
      link.QuestionOrder = order;

      order++;
    });

    return this.quizRepositoryService.saveQuiz(quizEntity)
      .pipe(map(savedQuiz => {
        let result = new DwModalResult<LinkQuestionsModalResult>();

        result.closeModal = true;

        result.data = {
          quiz: savedQuiz,
          isNewQuiz: this.isNewQuiz,
          openQuizAfter: this.openQuizAfter
        };

        return result;
      }));
  }

  validateForm(): boolean {
    if (this.isNewQuiz && !this.quizName) {
      this.setMessage('Please enter a quiz name');
      return false;
    }
    if (!this.isNewQuiz && !this.quizId) {
      this.setMessage('Please selet a quiz');
      return false;
    }

    return true;
  }

  setMessage(message: string, severity: DwMessageSeverity = DwMessageSeverity.error) {
    this.message = {
      messageBody: message,
      severity: severity
    };
  }

  clearMessage() {
    this.message = null;
  }
}

