import { DwMenuActionType, DwMetaDataRoutingService, DwModalButtonStandardConfigs, DwModalConfig, DwModalResult, DwModalService, DwModalSize } from "@devwareapps/devware-cap";
import { FlagLessonModalComponent } from "../components/flag-lesson-modal/flag-lesson-modal.component";
import { Observable } from "rxjs/internal/Observable";
import { AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class LessonFlagUtilService {

    constructor(private dwModalService: DwModalService, private dwMetaDataRoutingService: DwMetaDataRoutingService) {

    }

    public showLessonFlagModal(courseId: number, lessonId: number): Observable<DwModalResult> {
        return this.dwMetaDataRoutingService.openAddEditViewModal(DwMenuActionType.add, AppMetaDataItemNames.CourseLessonFlag, null, {
            CourseId: courseId,
            CourseLessonFlagTypeId: 1,
            CourseLessonId: lessonId,
        },
            null, 'user-lesson-flag', { title: 'Flag Lesson' }, true);
    }

    public showQuestionFlagModal(courseId: number, lessonId: number, quizId: number, questionId: number): Observable<DwModalResult> {

        const modalOptions: Partial<DwModalConfig> = {
            title: 'Flag Question',
            modalSize: DwModalSize.custom,
            customModalWidth: '400px',
            customModalHeight: '400px',
        };
        
        return this.dwMetaDataRoutingService.openAddEditViewModal(DwMenuActionType.add, AppMetaDataItemNames.CourseLessonFlag, null, {
            CourseId: courseId,
            CourseLessonFlagTypeId: 1,
            CourseLessonId: lessonId,
            QuizId: quizId,
            QuestionId: questionId,
        },
            null, 'user-lesson-flag', modalOptions, true);
    }
}