import { Injectable } from "@angular/core";
import { ValidatorFn, AbstractControl, FormGroup } from "@angular/forms";
import { DwValidationError, DwValidationErrorResult, DwValidationItemType, DwValidationRule, DwValidationRuleBase } from "@devwareapps/devware-cap";
import * as moment from "moment";
import { AssignmentEntity } from "../../../meta-data/app-meta-data.service";


const errorProperty = 'lessonCountError';
@DwValidationRule({
    keyName: 'assignment_lesson_count_validator',
    display: 'Assignment Lesson Count Validator',
    errorType: errorProperty,
    validationItemType: DwValidationItemType.formGroup,
    messageTemplate: 'At least one lesson must be selected',
})
@Injectable()
export class DwAssignmentLessonCountValidationRule extends DwValidationRuleBase {
    createValidator(config?: any) {
        return ValidateLessonCount(config);
    }

    getMessageTemplate(error: DwValidationError<any>, displayName: string, isSubmitted: boolean, messageTemplate?: string): string {
        return messageTemplate;
    }
}

export function ValidateLessonCount(config: any): ValidatorFn {
    return (control: AbstractControl) => {
        const formGroup = control as FormGroup;
        const assignment : AssignmentEntity = formGroup?.getRawValue();
        
        let lessonCount = 0;

        if (assignment?.AssignmentCourseLesson) {
            lessonCount = assignment.AssignmentCourseLesson.filter(lesson => !lesson._isDeleted).length;
        }

        if (lessonCount == 0) {
            const result: DwValidationErrorResult<any> = {};
            result[errorProperty] = {
                value: true
            };

            return result;
        } 

        return null;
    }
}