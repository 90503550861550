<div *ngIf="!lessonLocked && lesson">
    <ng-container *ngIf="progress">
        <app-video-host *ngIf="lesson?.LessonTypeId == videoLessonType" [video]="lesson?.Video"
            (videoEvent)="videoEvent($event)" (videoWidthChanged)="videoWidthChanged($event)"
            [furthestWatchedTime]="progress?.lesson?.lessonProgress?.VideoFurthestTimeSeconds"></app-video-host>

        <app-quiz-home *ngIf="lesson?.LessonTypeId == quizLessonType" [lesson]="lesson" [progress]="progress"
            (quizEvent)="quizEvent($event)" (viewChanged)="quizViewChanged($event)"></app-quiz-home>
    </ng-container>
    <div>
        <app-lesson-navigation [lessonNavigationInfo]="lessonNavigationInfo" [videoWidth]="videoWidth" *ngIf="!hideNavigation"
            [disableNext]="!progress?.lesson?.lessonProgress?.LessonComplete">

            <!-- <div flagLesson class="pull-left" *ngIf="lesson?.LessonTypeId == videoLessonType && flagEnabled">
                <i class="fa fa-flag fa-2x hand" title="Flag lesson" (click)="flagLesson()"></i>

                <span *ngIf="flagSubmitted"> Submitted</span>
            </div> -->
            <div lessonDetails>
                <div *ngIf="progress?.lesson?.lessonProgress?.LessonComplete" class="lesson-complete">
                    <i class="fa fa-check-circle dw-green fa-2x"></i>

                    <span class="hide-mobile-inline">Lesson Completed!</span>
                    <span class="show-mobile-inline">Complete</span>
                </div>

                <div *ngIf="!progress?.lesson?.lessonProgress?.LessonComplete && false">
                    <button class="btn btn-primary" (click)="markLessonComplete()" title="For testing only">Complete Lesson</button>
                </div>
            </div>
        </app-lesson-navigation>
    </div>
</div>
<div *ngIf="lessonLocked">
    <div class="lesson-locked">
        <div class="lesson-locked-inner">
            <h2> <i class="fa fa-lock fa-3x"></i> Lesson Locked </h2>
        </div>
    </div>
</div>